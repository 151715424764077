<template>
  <Card
    :class="`!border-action-default-hi-disabled relative`"
    :is-loading
    :is-opened
    :status
    :style="{ backgroundColor: BG_WITH_OPACITY }"
    :tracking
    @click="emit('click')"
  >
    <template #header>
      <CardHeader :label="heading" :status />
    </template>

    <h2 class="heading-1 px-24 md:text-center">
      {{ title }}

      <IconCheckInCircleFilled
        class="text-static-success-hi inline-block"
        size="32"
      />
    </h2>

    <p
      v-if="description"
      class="text-static-default-low body-2 px-24 md:text-center"
    >
      {{ description }}
    </p>

    <div class="mx-24 mt-24 hidden md:block">
      <RevButton
        full-width="always"
        size="medium"
        variant="primary"
        @click.stop="onContinue"
      >
        {{ i18n(translations.buttonLabel) }}
      </RevButton>
    </div>

    <div v-if="image" aria-hidden="true" class="md:hidden">
      <ExampleImage class="text-static-brand-mid pl-24" :with-icon="false" />

      <div class="relative">
        <RevIllustration
          alt=""
          class="rounded-b-lg"
          :height="498"
          sizes="498px"
          :src="image"
          :width="498"
        />
        <div
          class="rounded-br-lg rounded-bl-lg absolute bottom-0 left-0 right-0 px-16 pb-24 pt-48"
          :class="$style.gradient"
        >
          <RevButton
            full-width="always"
            size="medium"
            variant="primary"
            @click.stop="onContinue"
          >
            {{ i18n(translations.buttonLabel) }}
          </RevButton>
        </div>
      </div>
    </div>
  </Card>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'
import { IconCheckInCircleFilled } from '@ds/icons/IconCheckInCircleFilled'

import ExampleImage from '../../../ExampleImage/ExampleImage.vue'
import { BG_WITH_OPACITY } from '../../NoGrade.constants'
import Card from '../Card/Card.vue'
import CardHeader from '../CardHeader/CardHeader.vue'

import translations from './StepUnavailable.translations'

const props = withDefaults(
  defineProps<{
    heading: string
    title: string
    description?: string
    image?: string
    isOpened?: boolean
    isLoading?: boolean
    tracking: Record<string, string>
    status: 'accepted' | 'declined' | 'unknown'
  }>(),
  {
    description: '',
    image: '',
    isOpened: false,
    isLoading: false,
    status: 'unknown',
  },
)

const emit = defineEmits<{
  (e: 'click'): void
  (e: 'continue'): void
}>()

const i18n = useI18n()
const { trackClick } = useTracking()

const status = ref<'accepted' | 'declined' | 'unknown'>(props.status)

function onContinue() {
  trackClick({
    zone: props.tracking.block_name ?? '',
    value: 'continue',
  })
  status.value = 'accepted'
  emit('continue')
}
</script>

<style lang="css" module>
.gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 6.8%,
    rgba(255, 255, 255, 0.02) 12.43%,
    rgba(255, 255, 255, 0.04) 17.16%,
    rgba(255, 255, 255, 0.08) 21.25%,
    rgba(255, 255, 255, 0.12) 24.96%,
    rgba(255, 255, 255, 0.17) 28.57%,
    rgba(255, 255, 255, 0.23) 32.33%,
    rgba(255, 255, 255, 0.3) 36.52%,
    rgba(255, 255, 255, 0.38) 41.4%,
    rgba(255, 255, 255, 0.46) 47.23%,
    rgba(255, 255, 255, 0.56) 54.28%,
    rgba(255, 255, 255, 0.66) 62.82%,
    rgba(255, 255, 255, 0.76) 73.11%,
    rgba(255, 255, 255, 0.88) 85.41%,
    #fff 100%
  );
}
</style>
