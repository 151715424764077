<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0908 3.87354C9.94087 3.87354 9.7922 3.90049 9.65185 3.95312L5.85714 5.37614C5.6597 5.45018 5.4842 5.57296 5.34697 5.73306L2.71646 8.802C2.30774 9.27883 2.31628 9.98489 2.73641 10.4517L11.071 19.7123C11.5675 20.264 12.4327 20.264 12.9292 19.7123L21.2638 10.4517C21.6839 9.98489 21.6924 9.27883 21.2837 8.802L18.6597 5.74059C18.5122 5.56858 18.3208 5.43987 18.1059 5.36823L13.8142 3.93768C13.6868 3.8952 13.5533 3.87354 13.419 3.87354H10.0908Z"
      fill="#C795FF"
    />
    <g clip-path="url(#clip0_75_8478)">
      <path
        clip-rule="evenodd"
        d="M10.0908 3.87354C9.94087 3.87354 9.7922 3.90049 9.65185 3.95312L5.85714 5.37614C5.6597 5.45018 5.4842 5.57296 5.34697 5.73306L2.71646 8.802C2.30774 9.27883 2.31628 9.98489 2.73641 10.4517L11.071 19.7123C11.5675 20.264 12.4327 20.264 12.9292 19.7123L21.2638 10.4517C21.6839 9.98489 21.6924 9.27883 21.2837 8.802L18.6597 5.74059C18.5122 5.56858 18.3208 5.43987 18.1059 5.36823L13.8142 3.93768C13.6868 3.8952 13.5533 3.87354 13.419 3.87354H10.0908ZM7.71857 6.28011L10.1361 5.37354H13.3784L16.1766 6.30628L13.9076 6.87353H10.0923L7.71857 6.28011ZM5.91491 7.37536L4.50657 9.01842L8.5858 10.6501L9.54676 8.28332L5.91491 7.37536ZM5.15022 10.8914L10.0986 16.3897L8.42297 12.2005L5.15022 10.8914ZM13.9011 16.3902L18.8501 10.8913L15.5769 12.2005L13.9011 16.3902ZM19.4935 9.01834L18.0852 7.37529L14.4533 8.28327L15.4143 10.6501L19.4935 9.01834ZM11.1282 8.37353H12.8719C12.8757 8.38428 12.8798 8.39499 12.8841 8.40566L13.8861 10.8736H10.114L11.116 8.40566C11.1203 8.39499 11.1244 8.38428 11.1282 8.37353ZM10.1077 12.3736L12 17.1041L13.8922 12.3736H10.1077Z"
        fill="#0F1117"
        fill-rule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_75_8478">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
</template>
