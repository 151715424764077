import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

export const logger = {
  info(
    err: Error,
    {
      component: { name: componentName, props },
      routeName,
    }: {
      component: { name: string; props: Record<string, unknown> }
      routeName: string
    },
  ) {
    const globalLogger = useLogger()
    const owner = 'S&R'
    const level = 'Error'
    const message = `[${owner}][${level}][${componentName}]`
    const attributes = {
      componentName,
      props,
      routeName,
      details: {
        message: err?.message,
        stack: err?.stack,
      },
      err,
    }

    globalLogger.info(message, {
      ...attributes,
      owners: ['bot-squad-search-and-recommendation-front'],
    })
  },
}
