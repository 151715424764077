<template>
  <div class="flex justify-center gap-24">
    <Price
      v-bind="decline"
      class="w-1/2"
      :theme
      variant="decline"
      @decline="onDecline"
    />
    <Price
      v-bind="accept"
      class="w-1/2"
      :is-good-deal
      :theme
      variant="accept"
      @accept="onAccept"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import Price, { type PriceProps } from '../Price/Price.vue'

const { trackClick } = useTracking()

export type PartialPriceProps = Omit<
  PriceProps,
  'variant' | 'theme' | 'isGoodDeal'
>

const props = defineProps<{
  accept: PartialPriceProps
  decline: PartialPriceProps
  theme?: PriceProps['theme']
  tracking: { zone: string }
}>()

const emit = defineEmits<{
  (e: 'accept'): void
  (e: 'decline'): void
}>()

const isGoodDeal = computed(() => {
  const declinePrice = parseFloat(props.decline.price.amount)
  const acceptPrice = parseFloat(props.accept.price.amount)

  return acceptPrice < declinePrice
})

const trackClickEvent = (action: 'accept' | 'decline') => {
  trackClick({
    name: `no_grade_${action}`,
    value: props[action].price.amount,
    ...props.tracking,
  })
}
const onAccept = () => {
  trackClickEvent('accept')
  emit('accept')
}
const onDecline = () => {
  trackClickEvent('decline')
  emit('decline')
}
</script>
