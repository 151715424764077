export default {
  title: {
    id: 'product_checkpoints_carousel_title',
    defaultMessage:
      'All devices are restored professionally based on a 25-point inspection',
  },
  buttons: {
    id: 'product_checkpoints_item_buttons',
    defaultMessage: 'Buttons',
  },
  sim: {
    id: 'product_checkpoints_item_sim_reader',
    defaultMessage: 'SIM & Memory card reader',
  },
  factoryReset: {
    id: 'product_checkpoints_item_factory_reset',
    defaultMessage: 'Factory reset',
  },
  configuration: {
    id: 'product_checkpoints_item_software_configuration',
    defaultMessage: 'Configuration software',
  },
  charger: {
    id: 'product_checkpoints_item_charger_connectors_cables',
    defaultMessage: 'Matching charger, checked connectors and cables',
  },
  unlocked: {
    id: 'product_checkpoints_item_unlocked_product',
    defaultMessage: 'Unlocked product',
  },
  gps: {
    id: 'product_checkpoints_item_digital_gps',
    defaultMessage: 'GPS & Positioning system',
  },
  cameras: {
    id: 'product_checkpoints_item_cameras',
    defaultMessage: 'Cameras',
  },
  externalSensor: {
    id: 'product_checkpoints_item_external_sensor',
    defaultMessage: 'External sensor',
  },
  internalSensors: {
    id: 'product_checkpoints_item_internal_sensors',
    defaultMessage: 'Internal sensors',
  },
  biometricSensors: {
    id: 'product_checkpoints_item_biometric_sensors',
    defaultMessage: 'Biometric sensors',
  },
  wifi: {
    id: 'product_checkpoints_item_wifi_bluetooth',
    defaultMessage: 'Wi-Fi & Bluetooth',
  },
  imei: {
    id: 'product_checkpoints_item_imei',
    defaultMessage: 'IMEI & Serial number',
  },
  oxidation: {
    id: 'product_checkpoints_item_oxidation_indicator',
    defaultMessage: 'Oxidation indicator',
  },
  flash: {
    id: 'product_checkpoints_item_flash_indicator_lights',
    defaultMessage: 'Flash & indicator lights',
  },
  microphone: {
    id: 'product_checkpoints_item_microphone_speakers',
    defaultMessage: 'Microphone & speakers',
  },
  ports: {
    id: 'product_checkpoints_item_input_output_port',
    defaultMessage: 'Input/output port',
  },
  carrier: {
    id: 'product_checkpoints_item_phone_carrier',
    defaultMessage: 'Phone carrier',
  },
  battery: {
    id: 'product_checkpoints_item_battery',
    defaultMessage: 'Battery',
  },
  mechanical: {
    id: 'product_checkpoints_item_original_mechanical_parts',
    defaultMessage: 'Original mechanical parts',
  },
  consumableItems: {
    id: 'product_checkpoints_item_consumable_items',
    defaultMessage: 'Consumable items',
  },
  cooling: {
    id: 'product_checkpoints_item_cooling_elements',
    defaultMessage: 'Cooling elements',
  },
  screen: {
    id: 'product_checkpoints_identical_screen_quality',
    defaultMessage: 'Identical screen quality',
  },
  subassembly: {
    id: 'product_checkpoints_item_sub_assembly_impact_configuration',
    defaultMessage: 'Sub-assembly impacting the configuration',
  },
  trackpad: {
    id: 'product_checkpoints_item_trackpad_keyboard_touch_bar_backlighting',
    defaultMessage: 'Trackpad, keyboard, touch bar, and backlighting',
  },
  features: {
    id: 'product_checkpoints_item_other_features',
    defaultMessage: 'Other features',
  },
  compatiblePart: {
    id: 'product_checkpoints_item_compatible_part',
    defaultMessage:
      'Compatible part must have the same performance as the original ones',
  },
}
