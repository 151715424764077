export default {
  accept: {
    id: 'no_grade_button_accept',
    defaultMessage: 'Accept',
  },
  decline: {
    id: 'no_grade_button_decline',
    defaultMessage: 'Decline',
  },
  goodDeal: {
    id: 'no_grade_good_deal',
    defaultMessage: 'Good deal',
  },
}
