import { IconBattery } from '@ds/icons/IconBattery'
import { IconCamera } from '@ds/icons/IconCamera'
import { IconChargingWire } from '@ds/icons/IconChargingWire'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'
import { IconCrosshair } from '@ds/icons/IconCrosshair'
import { IconExport } from '@ds/icons/IconExport'
import { IconFactory } from '@ds/icons/IconFactory'
import { IconHeadset } from '@ds/icons/IconHeadset'
import { IconLightbulb } from '@ds/icons/IconLightbulb'
import { IconListView2 } from '@ds/icons/IconListView2'
import { IconLocationPinOutlined } from '@ds/icons/IconLocationPinOutlined'
import { IconLockUnlocked } from '@ds/icons/IconLockUnlocked'
import { IconPowerAdapterApple } from '@ds/icons/IconPowerAdapterApple'
import { IconRepairHome } from '@ds/icons/IconRepairHome'
import { IconSim } from '@ds/icons/IconSim'
import { IconSmartphone } from '@ds/icons/IconSmartphone'
import { IconTouch } from '@ds/icons/IconTouch'
import { IconWaterdrop } from '@ds/icons/IconWaterdrop'
import { IconWifi } from '@ds/icons/IconWifi'

import translations from './CheckpointsCarousel.translations'

export const CHECKPOINTS = [
  {
    icon: IconTouch,
    text: translations.buttons,
  },
  {
    icon: IconSim,
    text: translations.sim,
  },
  {
    icon: IconFactory,
    text: translations.factoryReset,
  },
  {
    icon: IconPowerAdapterApple,
    text: translations.charger,
  },
  {
    icon: IconLockUnlocked,
    text: translations.unlocked,
  },
  {
    icon: IconLocationPinOutlined,
    text: translations.gps,
  },
  {
    icon: IconCamera,
    text: translations.cameras,
  },
  {
    icon: IconTouch,
    text: translations.externalSensor,
  },
  {
    icon: IconCheckInCircle,
    text: translations.biometricSensors,
  },
  {
    icon: IconWifi,
    text: translations.wifi,
  },
  {
    icon: IconCrosshair,
    text: translations.imei,
  },
  {
    icon: IconWaterdrop,
    text: translations.oxidation,
  },
  {
    icon: IconLightbulb,
    text: translations.flash,
  },
  {
    icon: IconHeadset,
    text: translations.microphone,
  },
  {
    icon: IconChargingWire,
    text: translations.ports,
  },
  {
    icon: IconWifi,
    text: translations.carrier,
  },
  {
    icon: IconBattery,
    text: translations.battery,
  },
  {
    icon: IconRepairHome,
    text: translations.mechanical,
  },
  {
    icon: IconExport,
    text: translations.compatiblePart,
  },
  {
    icon: IconSmartphone,
    text: translations.screen,
  },
  {
    icon: IconListView2,
    text: translations.features,
  },
]
