<template>
  <div data-test="out-of-stock-product">
    <div class="heading-1 mb-24 mt-16 text-center text-[1.75rem] md:hidden">
      <!-- eslint-disable vue/no-v-html -->
      <!-- nosemgrep: javascript.vue.security.audit.xss.templates.avoid-v-html.avoid-v-html -->
      <span v-html="i18n(translations.overlayLabelMobile)" />
    </div>

    <div class="flex flex-row md:flex-col">
      <div class="relative flex justify-center">
        <div
          class="absolute inset-0 z-[1] hidden w-full items-center justify-center md:flex"
        >
          <div
            class="text-static-default-low punchline -rotate-12 px-16 text-center md:min-w-[130%]"
          >
            {{ i18n(translations.overlayLabelDesktop) }}
          </div>
        </div>

        <div
          v-if="!hasImage"
          class="size-[5.5rem] max-h-full min-w-[5.5rem] md:block md:size-[29.125rem] md:p-56"
        >
          <div class="bg-static-default-hi size-full" />
        </div>

        <RevIllustration
          v-if="hasImage"
          :alt="product.images[0].description"
          class="max-h-full w-[5.5rem] min-w-[5.5rem] opacity-30 md:block md:h-auto md:w-[29.125rem]"
          :height="976"
          loading="eager"
          sizes="(min-width: 768px) 466px, 88px"
          :src="product.images[0].url"
          :width="976"
        />
      </div>

      <div
        v-if="hasAlternative"
        class="bg-static-default-mid rounded-lg mb-16 mt-32 hidden p-16 md:flex"
      >
        <IconWarning />
        <p class="body-1 ml-8">{{ i18n(translations.warningMessage) }}</p>
      </div>

      <div
        class="ml-16 flex flex-col items-center justify-center md:mb-16 md:ml-0 md:justify-between"
      >
        <div class="flex w-full flex-col items-start">
          <h1
            class="body-1-bold line-clamp-2 overflow-hidden text-ellipsis md:heading-1"
          >
            {{ product.titles.raw }}
          </h1>
          <div class="body-2 md:hidden">
            {{ i18n(translations.unavailableLabel) }}
          </div>
        </div>

        <RatingInfo class="mt-16 hidden w-full md:flex" :rating />
      </div>
    </div>

    <div v-if="!hasAlternative && !isLoading">
      <RevDivider class="my-32 flex w-full md:hidden" />

      <RevButton
        v-if="landingLink"
        full-width="always"
        :to="landingLink"
        variant="primary"
      >
        {{ i18n(translations.cta) }}
      </RevButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type GetBreadcrumbResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/breadcrumb'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevDivider } from '@ds/components/Divider'
import { RevIllustration } from '@ds/components/Illustration'
import { IconWarning } from '@ds/icons/IconWarning'

import RatingInfo from '~/scopes/reviews/reviews-display/components/Rating/Rating.vue'

import translations from './ExpectedProduct.translations'

const i18n = useI18n()

const props = defineProps<{
  product: GetProductResponse
  rating: Rating
  recommendation: Product | null
  breadcrumb: GetBreadcrumbResponse['breadcrumb'] | null
  isLoading: boolean
}>()

const hasImage = computed(() => {
  return !isEmpty(props.product.images?.[0])
})

const hasAlternative = computed(() => {
  return !isEmpty(props.recommendation)
})

const landingLink = computed(() => {
  return (props.breadcrumb ?? []).slice(-1)?.[0]?.url ?? null
})
</script>
