<template>
  <div v-if="scores.length > 0">
    <RevDivider class="my-12" />

    <ul ref="root" class="grid gap-x-24 gap-y-16 md:grid-cols-4">
      <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events, vuejs-accessibility/no-static-element-interactions -->
      <li
        v-for="score in scores"
        :key="score.id"
        @click="handleScoreClick(score)"
      >
        <h3 class="body-2">
          {{ score.label }}
        </h3>
        <div class="bg-static-default-low rounded-full mt-4 h-12">
          <div
            :aria-label="score.label"
            aria-valuemax="100"
            aria-valuemin="0"
            :aria-valuenow="score.value"
            class="rounded-full h-full bg-gradient-to-r from-[#3f55bf] to-[#59de99]"
            role="progressbar"
            :style="{
              width: `${score.value}%`,
              '--tw-gradient-to-position': `${(100 / parseInt(score.value, 10)) * 100}%`,
            }"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { onUnmounted, ref } from 'vue'

import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevDivider } from '@ds/components/Divider'
import { useIntersectionObserver } from '@vueuse/core'

const props = defineProps<{
  scores: GetProductResponse['scores']
  productModel?: string
}>()

const { trackProductBlockImpression, trackClick } = useTracking()
const root = ref<HTMLElement | null>()

function handleScoreClick(score: GetProductResponse['scores'][0]) {
  trackClick({
    zone: 'scorecards',
    name: score.id,
    value: `${score.value}%`,
  })
}

const { stop } = useIntersectionObserver(root, ([{ isIntersecting }]) => {
  if (isIntersecting) {
    trackProductBlockImpression({
      block_name: 'scorecards',
      product_model: props.productModel || '',
    })
  }
})

onUnmounted(() => {
  stop()
})
</script>
