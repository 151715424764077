<template>
  <RevTag
    v-if="shouldDisplayInStock"
    :label="i18n(translations.inStockDisplay)"
    variant="success"
  />
</template>

<script lang="ts" setup>
/**
 * Displays "In stock" revtag as it is done for YouSave.
 * As it is done as a quick A/B test, it is handled in a dedicated component so it's easy to ditch if not positive.
 * This A/B test id performed on JP PP where there is no YouSave
 * so we display both components sequentially for the sake of simplicity during the lifespan of the A/B test.
 */
import { computed } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevTag } from '@ds/components/Tag'

import { COUNTRIES_WITH_IN_STOCK } from '../../Price.constants'

import translations from './InStock.translations'

const i18n = useI18n()
const {
  market: { countryCode },
} = useMarketplace()
const experiments = useExperiments()

const shouldDisplayInStock = computed(() => {
  return (
    COUNTRIES_WITH_IN_STOCK.includes(countryCode) &&
    experiments['experiment.ppInStock'] === 'displayInStock'
  )
})
</script>
