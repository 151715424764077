<template>
  <a
    class="bg-static-success-low text-static-success-hi rounded-sm body-2-bold inline-flex items-center self-center p-4 pr-8"
    href="#ecoBlocks"
    @click="handleClick"
  >
    <img
      alt=""
      class="stroke-success"
      :height="24"
      :src="toPublicPath('/img/product/eco-block/butterfly.svg')"
      :width="24"
    />
    <span class="ml-1 underline">
      {{ i18n(translations.ecoBlockAnchor) }}
    </span>
  </a>
</template>

<script lang="ts" setup>
import { toPublicPath } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import translations from './EcoBlockAnchor.translations'

const i18n = useI18n()
const { trackClick } = useTracking()

function handleClick(e: Event) {
  // Let's prevent the default anchor behavior (keeping the anchor it for a11y)
  e.preventDefault()

  trackClick({
    name: 'go_to_ecoBlock',
    zone: 'detail',
  })

  // Using getElementById instead of ref as the target is in another component
  document.getElementById('ecoBlocks')?.scrollIntoView({
    behavior: 'smooth',
    // Centering the block in the page to avoid sticky elements hidden the first lines.
    block: 'center',
  })
}
</script>
