export default {
  shippingInformations: {
    id: 'bundle_add_to_cart_shipping_informations',
    defaultMessage:
      'These items might be sold and delivered by different sellers 🚛',
  },
  genericError: {
    id: 'bundle_add_to_cart_generic_error',
    defaultMessage: 'Something went wrong, please try again.',
  },
  cta: {
    id: 'bundle_add_to_cart_cta',
    defaultMessage: 'Add both to cart',
  },
  ctaWithPrice: {
    id: 'bundle_add_to_cart_cta_with_price',
    defaultMessage: 'Add to cart for {totalPrice}',
  },
  totalPrice: {
    id: 'bundle_add_to_cart_total_price',
    defaultMessage: 'Total price: {totalPrice}',
  },
} as const
