<template>
  <Card :is-loading :is-opened :status :tracking @click="emit('click')">
    <template #header>
      <CardHeader :label="i18n(translations[`cardHeader_${status}`])" :status />
    </template>

    <h2 class="heading-1 px-24 md:text-center">
      {{ i18n(translations.title) }}
    </h2>

    <div aria-hidden="true" class="md:hidden">
      <ExampleImage class="text-static-default-low pl-24" :with-icon="false" />

      <RevIllustration
        alt=""
        :height="500"
        sizes="500px"
        src="/img/product/no-grade/battery.png"
        :width="500"
      />
    </div>

    <div class="px-24">
      <p class="text-static-default-low body-2 text-center">
        {{ i18n(translations.text) }}
      </p>
      <div class="flex justify-center">
        <RevLink class="body-2" @click="openBatteryModal">
          {{ i18n(translations.link) }}
        </RevLink>
      </div>

      <PriceGroup
        :accept="{ price: prices[0], label: i18n(translations.accept) }"
        class="pb-24"
        :decline="{ price: prices[1], label: i18n(translations.decline) }"
        :tracking="{ zone: 'no_grade_battery' }"
        @accept="emit('accept')"
        @decline="emit('decline')"
      />
    </div>
  </Card>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { Price } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '~/scopes/product/constants'

import ExampleImage from '../../../ExampleImage/ExampleImage.vue'
import Card from '../Card/Card.vue'
import CardHeader from '../CardHeader/CardHeader.vue'
import PriceGroup from '../PriceGroup/PriceGroup.vue'

import translations from './StepBattery.translations'

const props = withDefaults(
  defineProps<{
    prices: Array<Price>
    isOpened?: boolean
    isLoading?: boolean
    status?: 'accepted' | 'declined' | 'unknown'
    tracking: Record<string, string>
  }>(),
  {
    isOpened: false,
    isLoading: false,
    status: 'unknown',
  },
)

const emit = defineEmits<{
  (e: 'click'): void
  (e: 'accept'): void
  (e: 'decline'): void
}>()

const i18n = useI18n()
const { trackClick } = useTracking()

const tracking = computed(() => {
  const acceptPrice = parseFloat(props.prices[0].amount)
  const declinePrice = parseFloat(props.prices[1].amount)

  return {
    ...props.tracking,
    price_accept: props.prices[0].amount,
    price_decline: props.prices[1].amount,
    good_deal: acceptPrice < declinePrice,
  }
})

function openBatteryModal() {
  trackClick({
    name: 'battery_step_modal',
    zone: 'no_grade_battery',
  })
  openModal(MODAL_NAMES.NEW_BATTERY)
}
</script>
