export default {
  goodDeal: { id: 'good_deal', defaultMessage: 'Hot deal' },
  comingSoon: {
    id: 'product_page_customization_coming_soon',
    defaultMessage: 'Coming soon',
  },
  soldOut: {
    id: 'already_sold',
    defaultMessage: 'Sold out',
  },
}
