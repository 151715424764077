<template>
  <ul class="-m-6 flex flex-wrap">
    <li
      v-for="specification in specificationsToDisplay"
      :key="specification.display"
      class="flex w-1/2 grow md:w-1/5 [&:nth-child(n+7)]:hidden md:[&:nth-child(n+7)]:flex"
    >
      <p
        class="bg-surface-default-low rounded-md m-6 flex w-full flex-col items-center justify-center px-8 py-24"
      >
        <span class="text-static-default-hi body-2-bold">
          {{ specification.values?.[0].label }}
        </span>
        <span class="text-static-default-low caption text-center">
          {{ specification.display }}
        </span>
      </p>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type GetTechnicalSpecificationsResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/technical-specifications'

import { MAX_VISIBLE_TIEMS } from './SpecificationsOverview.constants'

const props = defineProps<{
  specifications: GetTechnicalSpecificationsResponse['specifications']
}>()

const specificationsToDisplay = computed(() =>
  props.specifications.slice(0, MAX_VISIBLE_TIEMS),
)
</script>
