import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

export default {
  resultTitle: {
    id: 'product_page_customization_result_title',
    defaultMessage: 'Tadaaaa!',
  },
  resultAddToCart: {
    id: 'product_page_customization_result_add_to_cart',
    defaultMessage: 'Add to cart',
  },
} as Record<string, I18nDefinition>
