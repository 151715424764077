<template>
  <p v-if="hasFlashOffers" class="text-static-default-hi body-2 pb-16">
    {{ i18n(translations.expirationDateLabel) }}
  </p>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import type { GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import getbenefitsFromMobilePlanOffers from '~/scopes/product/utils/getBenefitsFromMobilePlanOffers'

import translations from './StepDescriptionMobilePlan.translations'

const i18n = useI18n()

const props = defineProps<{
  offers:
    | MobilePlanOffer[]
    | GetProductResponse['includedServiceOffers']['mobilePlans']
}>()

const hasFlashOffers = computed(() => {
  const benefits = getbenefitsFromMobilePlanOffers(props.offers)

  return benefits.some(
    (benefit) =>
      benefit === 'FLASH_OFFER_130_GO_INSTEAD_OF_90_GO' ||
      benefit === 'FLASH_OFFER_180_EURO_SUBSIDY_INSTEAD_OF_150_EURO',
  )
})
</script>
