export default {
  labelUnlock: {
    id: 'product_page_carriers_unlock',
    defaultMessage: 'Works with all carriers',
  },
  labelLock: {
    id: 'product_page_carriers_lock',
    defaultMessage: 'Only compatible with',
  },
}
