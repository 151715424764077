<template>
  <div
    ref="root"
    class="md:flex md:justify-center"
    :class="[
      {
        'md:items-stretch': stretch,
        'md:items-center': !stretch,
      },
    ]"
  >
    <div
      v-if="!withoutLeftTemplate"
      class="mr-32 hidden max-w-[498px] md:relative md:block md:min-w-[337px] md:grow lg:mr-64"
    >
      <div
        class="flex justify-center opacity-100 transition-opacity duration-500 ease-out motion-reduce:transition-none"
        :class="[slotClasses.left, { 'md:opacity-50': !isFocused }]"
      >
        <slot name="left" />
      </div>
    </div>

    <div class="md:shrink-0" :class="$style.rightContainer">
      <div
        class="opacity-100 transition-opacity duration-500 ease-out motion-reduce:transition-none"
        :class="[slotClasses.right, { 'md:opacity-50': !isFocused }]"
      >
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onUnmounted, ref } from 'vue'

import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { useIntersectionObserver } from '@vueuse/core'

const { trackProductBlockImpression } = useTracking()
const root = ref<HTMLElement | null>()

const THRESHOLDS: Record<string, number> = {
  default: 0.5,
  recap: 0.25,
}

const props = withDefaults(
  defineProps<{
    slotClasses?: { left: string; right: string }
    isFocused?: boolean
    tracking?: {
      trackingTags?: {
        tagDisplayed: string
        tagSelected: boolean
      }
      trackingId?: string
      trackingModel?: string
      trackingCategory?: string
    }
    stretch?: boolean
    withoutLeftTemplate?: boolean
  }>(),
  {
    slotClasses: () => ({
      left: '',
      right: '',
    }),
    isFocused: true,
    tracking: () => ({}),
    stretch: false,
    withoutLeftTemplate: false,
  },
)

function trackImpression() {
  if (props.tracking.trackingId) {
    trackProductBlockImpression({
      product_price_tag_displayed:
        props.tracking.trackingTags?.tagDisplayed ?? 'no_tag',
      product_price_tag_selected: Boolean(
        props.tracking.trackingTags?.tagSelected,
      ),
      block_name: props.tracking.trackingId,
      product_model: props.tracking.trackingModel || '',
      product_category: props.tracking.trackingCategory || '',
    })
  }
}

const { stop } = useIntersectionObserver(
  root,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      trackImpression()
    }
  },
  {
    threshold:
      props.tracking.trackingId && props.tracking.trackingId in THRESHOLDS
        ? THRESHOLDS[props.tracking.trackingId]
        : THRESHOLDS.default,
  },
)

onUnmounted(() => {
  stop()
})
</script>

<style module>
.rightContainer {
  @media (min-width: 768px) {
    width: 360px;
  }
  @media (min-width: 900px) {
    width: 456px;
  }
}
</style>
