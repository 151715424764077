export default {
  selectionTitle: {
    id: 'product_page_no_grade_premium_step_comparison_area_title1',
    defaultMessage: 'Your selection',
  },
  premiumTitle: {
    id: 'product_page_no_grade_premium_step_comparison_area_title2',
    defaultMessage: 'With Premium',
  },
  premiumItem1: {
    id: 'product_page_no_grade_premium_step_comparison_area_item1',
    defaultMessage: 'Flawless screen',
  },
  premiumItem2: {
    id: 'product_page_no_grade_premium_step_comparison_area_item2',
    defaultMessage: 'Almost no signs of used shell',
  },
  premiumItem3: {
    id: 'product_page_no_grade_premium_step_comparison_area_item3',
    defaultMessage: '{brand} battery',
  },
  premiumItem4: {
    id: 'product_page_no_grade_premium_step_comparison_area_item4',
    defaultMessage: '{brand} parts',
  },

  screen_10: {
    id: 'product_page_no_grade_premium_step_comparison_area_screen_10',
    defaultMessage: 'Flawless screen',
  },
  screen_11: {
    id: 'product_page_no_grade_premium_step_comparison_area_screen_11',
    defaultMessage: 'Flawless screen',
  },
  screen_12: {
    id: 'product_page_no_grade_premium_step_comparison_area_screen_12',
    defaultMessage: 'Gently used screen',
  },

  body_10: {
    id: 'product_page_no_grade_premium_step_comparison_area_body_10',
    defaultMessage: 'Almost no signs of use shell',
  },
  body_11: {
    id: 'product_page_no_grade_premium_step_comparison_area_body_11',
    defaultMessage: 'Gently used shell',
  },
  body_12: {
    id: 'product_page_no_grade_premium_step_comparison_area_body_12',
    defaultMessage: 'Gently used shell',
  },

  batteryLabel_default: {
    id: 'product_page_no_grade_premium_step_comparison_area_battery_label_0',
    defaultMessage: 'Standard battery',
  },
  batteryLabel_new_battery: {
    id: 'product_page_no_grade_premium_step_comparison_area_battery_label_7',
    defaultMessage: 'New battery',
  },

  testedParts: {
    id: 'product_page_no_grade_premium_step_comparison_area_tested_parts',
    defaultMessage: 'Tested parts',
  },
} as const
