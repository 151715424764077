<template>
  <div>
    <div class="grid gap-x-12" :class="$style.gridTemplateArea">
      <div
        class="text-static-default-hi body-1-bold mb-8 py-8"
        :class="$style.gridAreaCurrentTitle"
      >
        {{ i18n(translations.selectionTitle) }}
      </div>
      <ComparisonItems :items="selection" styleName="gridAreaCurrentText" />
      <div
        class="border-static-brand-mid text-action-brand-mid rounded-md body-1-bold mb-8 border p-8"
        :class="$style.gridAreaPremiumTitle"
      >
        {{ i18n(translations.premiumTitle) }}
      </div>
      <ComparisonItems
        classNames="text-action-brand-mid"
        :items="premium"
        styleName="gridAreaPremiumText"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { MODAL_NAMES } from '~/scopes/product/constants'

import ComparisonItems from '../ComparisonItems/ComparisonItems.vue'

import translations from './ComparisonArea.translations'

export type ComparisonAreaProps = {
  brand: string
  offerTypeWithoutPremium: 'default' | 'new_battery'
  offerGradeWithoutPremium: string
}

const props = defineProps<ComparisonAreaProps>()

const i18n = useI18n()

const premium = ref([
  {
    label: i18n(translations.premiumItem1),
  },
  {
    label: i18n(translations.premiumItem2),
  },
  {
    label: i18n(translations.premiumItem3, { brand: props.brand }),
    modalName: MODAL_NAMES.NEW_BATTERY_NO_GRADE,
    tracking: {
      name: 'battery_selection',
      zone: 'no_grade_premium',
    },
  },

  {
    label: i18n(translations.premiumItem4, { brand: props.brand }),
  },
])

const selection = computed(() => {
  return [
    {
      label: i18n(
        translations[
          `screen_${props.offerGradeWithoutPremium}` as keyof typeof translations
        ],
      ),
    },
    {
      label: i18n(
        translations[
          `body_${props.offerGradeWithoutPremium}` as keyof typeof translations
        ],
      ),
    },
    {
      label: i18n(
        translations[
          `batteryLabel_${props.offerTypeWithoutPremium}` as keyof typeof translations
        ],
      ),
      modalName: MODAL_NAMES.NEW_BATTERY_NO_GRADE,
      tracking: {
        name: 'battery_premium',
        zone: 'no_grade_premium',
      },
    },

    {
      label: i18n(translations.testedParts),
      modalName: MODAL_NAMES.VERIFIED_REFURBISHED,
      tracking: {
        name: 'verified_refursbished_premium',
        zone: 'no_grade_premium',
      },
    },
  ]
})
</script>

<style module>
.gridTemplateArea {
  grid-auto-columns: minmax(0, 1fr);
  grid-template-areas:
    'current-title premium-title'
    'current-text1 premium-text1'
    'current-text2 premium-text2'
    'current-text3 premium-text3'
    'current-text4 premium-text4'
    'current-text5 premium-text5';
}
</style>
