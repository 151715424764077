import type { PartnerPromoCode } from '@backmarket/http-api/src/api-specs-b2c-services/partner-promo-code/types/partner-promo-code'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'

export const visiblePremiumOfferId = '568f4390-5895-4a23-b2a8-da91594f782d'

export function hasVisiblePremium(partnerPromoCode: PartnerPromoCode) {
  const experiments = useExperiments()

  return (
    partnerPromoCode.id === visiblePremiumOfferId &&
    experiments['experiment.visiblePremium'] === 'withPremiumOffer'
  )
}
