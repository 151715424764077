<template>
  <div>
    <Accessories
      class="mb-32"
      :comes-with="product.comesWith"
      :partner-promo-codes="product.includedServiceOffers.partnerPromoCodes"
    />

    <SellerComment
      v-if="selectedOffer && selectedOffer.sellerComment"
      :comment="selectedOffer.sellerComment"
    />
    <TheCatcherComponent>
      <SwapBlock
        v-if="selectedOffer && selectedOffer.price && shouldDisplaySwap"
        class="mb-32"
        :has-offer="Boolean(buybackOffer)"
        :modal-name="MODAL_NAMES.SWAP_PRODUCT"
        :model="product.titles.raw"
        :price="i18n.price(getDiscountedPrice(selectedOffer.price))"
        :product-category
        :swap-model="buybackOffer?.product.shortTitle"
        tracking-label="swap_from_pp"
        zone="swap_pp_block"
      />
    </TheCatcherComponent>

    <AdditionalInfo
      v-if="
        technicalSpecifications && selectedOffer && selectedOffer.offerLegacyId
      "
      :listing-id="selectedOffer.offerLegacyId"
      :technical-specifications
    />

    <Seller v-if="selectedOffer && shouldDisplaySeller" :selectedOffer />

    <Vat v-if="isVatIncluded" class="my-32 md:my-56" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { type GetTechnicalSpecificationsResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/technical-specifications'
import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import TheCatcherComponent from '~/scopes/buyback/components/TheCatcher/TheCatcherComponent.vue'
import { MODAL_NAMES } from '~/scopes/buyback/constants'
import SwapBlock from '~/scopes/buyback/swap/components/SwapBlock/SwapBlock.vue'

import Accessories from '../Accessories/Accessories.vue'
import AdditionalInfo from '../AdditionalInfo/AdditionalInfo.vue'
import Seller from '../Seller/Seller.vue'
import SellerComment from '../SellerComment/SellerComment.vue'
import Vat from '../Vat/Vat.vue'

const marketPlace = useMarketplace()

const props = withDefaults(
  defineProps<{
    selectedOffer?: GetPickersResponse['selectedOffer'] | null
    hasSwap: boolean
    product: GetProductResponse
    productCategory: string
    showSellerBlock?: boolean
    shouldDisplayVat?: boolean
    technicalSpecifications?: GetTechnicalSpecificationsResponse | null
  }>(),
  {
    selectedOffer: null,
    showSellerBlock: true,
    shouldDisplayVat: true,
    technicalSpecifications: undefined,
  },
)

const i18n = useI18n()
const { getDiscountedPrice, buybackOffer } = useBuybackOffer()

const shouldDisplaySwap = computed(() => {
  return props.hasSwap && props.product.isTradeInEligible
})

const isAccessory = computed(() => {
  return props.product.type === 'Brand new' || props.product.type === 'Digital'
})

const shouldDisplaySeller = computed(() => {
  return (
    !isAccessory.value && props.selectedOffer?.merchant && props.showSellerBlock
  )
})

const isVatIncluded = computed(() => {
  return marketPlace.features.vatIncluded && props.shouldDisplayVat
})
</script>
