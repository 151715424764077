import { h } from 'vue'

import type { Price } from '@backmarket/http-api'
import {
  type PickerService,
  type PickerWithExtraData,
  type PickersResponseServiceItem,
  hasItemExtraData,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import type { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import BouyguesLogo from '~/components/BouyguesLogo/BouyguesLogo.vue'
import { useFormatMonthsYearsDuration } from '~/composables/useFormatMonthsYearsDuration'
import { MODAL_NAMES } from '~/scopes/product/constants'
import { applyPriceOperation } from '~/scopes/product/utils/applyPriceOperation'

import BouyguesPickerAddon from '../../components/BouyguesPickerAddon/BouyguesPickerAddon.vue'
import type { Step } from '../types'
import { mapOption } from '../utils'

import translations from './mobilePlan.translations'

const getMobilePlanPrices = (item: PickerWithExtraData) => {
  const keepPhoneNumberPriceOption = item.mobilePlanOffer.priceOptions.find(
    ({ option }) => option === 'KEEP_PHONE_NUMBER',
  )

  if (!keepPhoneNumberPriceOption) return []

  return keepPhoneNumberPriceOption.prices
}

export function getGuidance(
  i18n: ReturnType<typeof useI18n>,
  items: PickerWithExtraData[],
) {
  const areBothPlanDisabled = items.every(({ available }) => !available)
  if (areBothPlanDisabled) return undefined

  const hasOnlyBAndYouOffers = items.every(
    (item) => item.mobilePlanOffer.brand === 'B&You',
  )

  return {
    label: hasOnlyBAndYouOffers
      ? i18n(translations.guidanceMobilePlanBandYou)
      : i18n(translations.guidanceMobilePlanBouyguesTelecom),
    icon: BouyguesLogo,
    modalName: MODAL_NAMES.BOUYGUES,
    clickable: true,
  }
}

export function getSubLabel(
  i18n: ReturnType<typeof useI18n>,
  option: PickerWithExtraData,
) {
  const prices = getMobilePlanPrices(option)
  if (prices.length === 0) return null

  const firstPeriodPrice = i18n.price(prices[0].price)
  const isSamePrice = prices.length === 1

  const { getFormattedDuration } = useFormatMonthsYearsDuration()

  const enrollmentPeriod =
    option.mobilePlanOffer.enrollmentPeriod === 0
      ? i18n(translations.withoutEnrollmentPeriod)
      : i18n(translations.withEnrollmentPeriod, {
          duration: getFormattedDuration(
            option.mobilePlanOffer.enrollmentPeriod,
          ),
        })

  if (isSamePrice) {
    return i18n(translations.mobilePlanOptionDescriptionSamePrice, {
      price: firstPeriodPrice,
    }).concat(enrollmentPeriod)
  }

  return i18n(translations.mobilePlanOptionDescriptionPriceFirstYear, {
    price: firstPeriodPrice,
  }).concat(enrollmentPeriod)
}

export function getMobilePlanOption(
  item: PickersResponseServiceItem,
  i18n: ReturnType<typeof useI18n>,
  price: Price | null | undefined,
) {
  if (!hasItemExtraData(item)) {
    return {
      ...mapOption(item),
      price: price || undefined,
      goodDeal: false,
    }
  }

  const bouyguesOption = {
    ...item,
    sublabel: getSubLabel(i18n, item),
    price: applyPriceOperation(price, item.operation) || undefined,
    addon:
      item.trackingValue !== 'smartphone_only' &&
      h(BouyguesPickerAddon, {
        offer: item.mobilePlanOffer,
        selected: item.selected,
      }),
    tag: {
      label: i18n(translations.mobilePlanSubsisdy, {
        subsidy: i18n.price(item.mobilePlanOffer.subsidy, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      }),
      type: 'success' as const,
    },
    // Should be managed by the backend, but blocked until we migrate to the new picker endpoint.
    goodDeal: false,
  }

  return mapOption(bouyguesOption)
}

export function getMobilePlanStep(
  i18n: ReturnType<typeof useI18n>,
  picker: PickerService,
  price: Price | null | undefined,
): Step | null {
  if (!picker || picker.items.length === 0) return null

  const { items } = picker

  const lastItem = items[items.length - 1]

  return {
    id: 'mobile_plan',
    type: 'service',
    title: translations.titleStepMobilePlan,
    titleEmphasis: translations.titleEmphasisStepMobilePlan,
    titlePrice: lastItem?.mobilePlanOffer?.subsidy,
    trackingId: picker.trackingId,
    desktopIllustration: '/img/product/funnel/step-mobile-plan-square.svg',
    guidance: getGuidance(i18n, items.filter(hasItemExtraData)),
    options: items.map((item) => getMobilePlanOption(item, i18n, price)),
  }
}
