<template>
  <ul class="flex list-none flex-col gap-y-12">
    <LargePicker
      :addon="RakutenCarouselPickerAddon"
      :index="1"
      :label="i18n(translations.option1)"
      :prefix
      :selected="selectedOption === 'with_rakuten'"
      :tag="i18n(translations.hotDealTag)"
      @click="handlePickerClick('with_rakuten')"
    />
    <LargePicker
      :index="2"
      :label="i18n(translations.option2)"
      :prefix
      :selected="selectedOption === 'without_rakuten'"
      @click="handlePickerClick('without_rakuten')"
    />
  </ul>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import type { Prefixes as PickerPrefixes } from '../../../../../LargePicker/LargePicker.types'
import LargePicker from '../../../../../LargePicker/LargePicker.vue'

import translations from './RakutenCarouselPickers.translations'
import RakutenCarouselPickerAddon from './components/RakutenCarouselPickerAddon/RakutenCarouselPickerAddon.vue'

const tracking = useTracking()

const i18n = useI18n()
const selectedOption = ref<'with_rakuten' | 'without_rakuten'>('with_rakuten')

const prefix = 'circle' as PickerPrefixes

function handlePickerClick(option: 'with_rakuten' | 'without_rakuten') {
  selectedOption.value = option

  tracking.trackClick({
    name: option,
    zone: 'rakuten_product_page_step',
  })
}
</script>
