export default {
  alternativeController: {
    id: 'product_page_carousel_alternative_controller',
    defaultMessage: 'Controller',
  },
  alternativeNext: {
    id: 'product_page_carousel_alternative_next',
    defaultMessage: 'Next',
  },
  alternativePrevious: {
    id: 'product_page_carousel_alternative_previous',
    defaultMessage: 'Previous',
  },
  alternativeClose: {
    id: 'product_page_carousel_alternative_close',
    defaultMessage: 'Close',
  },
  certifiedDescription: {
    id: 'product_page_carousel_certified_description',
    defaultMessage: 'Refurbished and tested by certified professionals',
  },
  batteryDescription: {
    id: 'product_page_carousel_battery_description',
    defaultMessage: 'Great battery health guaranteed',
  },
  longevityDescription: {
    id: 'product_page_carousel_longevity_description',
    defaultMessage: 'Long lasting devices with a 1-year warranty',
  },
}
