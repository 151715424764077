import { createHttpEndpoint } from '../../utils'

/**
 * @see {@link https://devportal.backmarket.io/catalog/default/api/search-service-api/definition#/public/breadcrumb}
 */
export const getBreadcrumb = createHttpEndpoint<GetBreadcrumbResponse>({
  method: 'GET',
  operationId: 'getBreadcrumb',
  path: '/bm/landing_page/product/:productId/breadcrumb',
})

export type GetBreadcrumbResponse = {
  breadcrumb: Array<{
    label: string
    url: string
  }>
}
