export default {
  titleStep: {
    id: 'swap_step_title',
    defaultMessage: 'Trade in an old device for cashback on today’s purchase',
  },
  swapCardGuidance: {
    id: 'swap_step_card_guidance',
    defaultMessage: 'See how Trade-in works',
  },
  noLabel: {
    id: 'swap_step_no_label',
    defaultMessage: 'No trade in',
  },
  swapSelectExample: {
    id: 'swap_step_select_example',
    defaultMessage: 'e.g. iPhone 11, Samsung Galaxy 10...',
  },
}
