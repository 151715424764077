import { type GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'

/**
 * For an upcoming A/B test, we want to remove disabled grades pickers instead of showing them disabled.
 * If the experiment is a success, the permanent change will be done on the endpoint.
 */
export const filterOutOfStockPickers = (
  pickers: GetPickersResponse,
  experiment: string,
): GetPickersResponse => {
  if (experiment !== 'hideOutOfStockPickers') {
    return pickers
  }

  return {
    ...pickers,
    pickerGroups: pickers.pickerGroups.map((pickerGroup) => ({
      ...pickerGroup,
      items: pickerGroup.items.filter(
        (picker) => pickerGroup.id !== 'grades' || !!picker?.available,
      ),
    })),
  }
}
