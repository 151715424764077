export default {
  stepSimTitle: {
    id: 'product_page_customization_step_esim_title',
    defaultMessage: 'Get flexible with eSIM',
  },
  optionPhysicalTitle: {
    id: 'product_picker_dual_sim_option_physical_title',
    defaultMessage: 'Physical SIM',
  },
  optionESimTitle: {
    id: 'product_picker_dual_sim_option_esim_title',
    defaultMessage: 'e-SIM',
  },
  optionESimTagLabel: {
    id: 'product_picker_dual_sim_option_esim_tag_label',
    defaultMessage: 'popular',
  },
  helpCardSimExperiment: {
    id: 'product_sim_modal_opener_customization_funnel_experiment',
    defaultMessage: "See what makes e-SIM's more secure and convenient",
  },
}
