<template>
  <div class="rounded-b-lg relative isolate text-center">
    <ImgComparisonSlider
      aria-hidden="true"
      class="w-full rounded-[32px] outline-none"
      :class="$style.separator"
      @click="trackHandle"
    >
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute - template slots are not working with the lib -->
      <div slot="first">
        <RevIllustration
          :alt="image1.label"
          class="w-full"
          :height="498"
          sizes="498px"
          :src="image1.src"
          :width="498"
        />
      </div>

      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute - template slots are not working with the lib -->
      <div slot="second">
        <RevIllustration
          :alt="image2.label"
          class="w-full"
          :height="498"
          sizes="498px"
          :src="image2.src"
          :width="498"
        />
      </div>

      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute - template slots are not working with the lib -->
      <div slot="handle">
        <RevButton
          class="!bg-action-default-low !text-action-brand-mid rounded-full !px-0 !py-8"
          variant="primary"
        >
          <div class="flex">
            <IconChevronLeft />
            <span>{{ i18n(translations.dragButton) }}</span>
            <IconChevronRight />
          </div>
        </RevButton>
      </div>
    </ImgComparisonSlider>
    <RevTag
      class="absolute left-24 top-24 z-20 hidden w-fit text-balance md:block"
      :label="image1.label"
      variant="secondary"
    />
    <RevTag
      class="absolute right-24 top-24 z-20 hidden w-fit text-balance md:block"
      :label="image2.label"
      variant="secondary"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'
import { RevTag } from '@ds/components/Tag'
import { IconChevronLeft } from '@ds/icons/IconChevronLeft'
import { IconChevronRight } from '@ds/icons/IconChevronRight'
import { ImgComparisonSlider } from '@img-comparison-slider/vue'

import translations from './ImageComparison.translations'

const i18n = useI18n()
const { trackClick } = useTracking()
// We want to track only once the interaction on the slider. The only event we have will be triggered
// over 9000 times by sliding the button
const hasTriggeredInterractionEvent = ref(false)

export type ImageComparisonItem = {
  src: string
  label: string
}

const props = defineProps<{
  image1: ImageComparisonItem
  image2: ImageComparisonItem
  tracking: {
    zone: string
  }
}>()

function trackHandle() {
  if (!hasTriggeredInterractionEvent.value) {
    hasTriggeredInterractionEvent.value = true

    trackClick({
      name: 'draggable_slider',
      zone: props.tracking.zone,
    })
  }
}
</script>

<style module>
.separator {
  --divider-width: 2px;
  --divider-color: #a9abb0;
  --divider-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.1);
}
</style>
