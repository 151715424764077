export default {
  productVatIncludedInfo: {
    id: 'product_vat_included_info',
    defaultMessage: '',
  },
  productVatFaqLink: {
    id: 'product_vat_faq_link',
    defaultMessage: '',
  },
  productVatFaq: {
    id: 'product_vat_faq',
    defaultMessage: 'Learn more',
  },
}
