import { type Component } from 'vue'

import type { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconBattery } from '@ds/icons/IconBattery'
import { IconListViewChecks } from '@ds/icons/IconListViewChecks'
import { IconThumbsUpOutlined } from '@ds/icons/IconThumbsUpOutlined'

import translations from './Gallery.translations'

export type ReassuranceImagesType = Array<{
  src: string
  alt: string
  description?: { text: string; icon: Component }
}>

// This list is on the front side for the experiment, but if it wins this needs to go on the backend side.
const CATEGORY_WITH_BATTERY = [
  '2',
  '110',
  '528',
  '5',
  '170',
  '9',
  '12',
  '11',
  '117',
  '216',
  '109',
  '559',
  '217',
  '115',
  '158',
  '18',
]

const getImages = (
  experiment: string,
  category: string,
  i18n: ReturnType<typeof useI18n>,
): ReassuranceImagesType => {
  const images = []

  const certified = {
    src:
      experiment === 'withFunctionalImages'
        ? '/img/product/gallery/functional-certified.png'
        : '/img/product/gallery/lifestyle-certified.png',
    description: {
      icon: IconListViewChecks,
      text: i18n(translations.certifiedDescription),
    },
    alt: i18n(translations.certifiedDescription),
  }

  const battery = {
    src:
      experiment === 'withFunctionalImages'
        ? '/img/product/gallery/functional-battery.png'
        : '/img/product/gallery/lifestyle-battery.png',
    description: {
      icon: IconBattery,
      text: i18n(translations.batteryDescription),
    },
    alt: i18n(translations.batteryDescription),
  }

  const longevity = {
    src:
      experiment === 'withFunctionalImages'
        ? '/img/product/gallery/functional-longevity.png'
        : '/img/product/gallery/lifestyle-longevity.png',
    description: {
      icon: IconThumbsUpOutlined,
      text: i18n(translations.longevityDescription),
    },
    alt: i18n(translations.longevityDescription),
  }

  images.push(certified)
  if (CATEGORY_WITH_BATTERY.includes(category)) {
    images.push(battery)
  }
  images.push(longevity)

  return images
}

export const getReassuranceImages = (
  experiment: string,
  category: string,
  type: string,
  i18n: ReturnType<typeof useI18n>,
) => {
  if (type !== 'Refurbished') {
    return []
  }

  if (
    experiment === 'withFunctionalImages' ||
    experiment === 'withLifestyleImages'
  ) {
    return getImages(experiment, category, i18n)
  }

  return []
}
