export default {
  title: {
    id: 'product_macbook_processor_modal_title',
    defaultMessage: 'Processor',
  },
  close: {
    id: 'product_macbook_processor_modal_close',
    defaultMessage: 'Close',
  },
}
