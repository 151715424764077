<template>
  <AcceptedOffer
    v-if="buybackOffer"
    :name="buybackOffer?.product.shortTitle"
    :price="buybackOffer?.listing.price"
    :tier="buybackOffer?.tier"
    @remove="deleteBuybackOffer"
  />
  <NoOfferV2
    v-else
    :model="model || ''"
    with-search-bar
    @default-item-selected="
      () => {
        deleteBuybackOffer()
        $emit('default-item-selected')
      }
    "
    @item-selected="
      (item) => {
        deleteBuybackOffer()
        $emit('item-selected', item)
      }
    "
  />
</template>

<script setup lang="ts">
import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'

import type { BuybackProduct } from '~/scopes/buyback/composables/useBuybackSearch'

import AcceptedOffer from '../AcceptedOffer/AcceptedOffer.vue'
import NoOfferV2 from '../NoOfferV2/NoOfferV2.vue'

defineProps<{
  model?: string
}>()

defineEmits<{
  (e: 'item-selected', value: BuybackProduct): void
  (e: 'default-item-selected'): void
}>()

const { deleteBuybackOffer, buybackOffer } = useBuybackOffer()
</script>
