<template>
  <div v-if="shouldShowScoreCard">
    <Scorecard :productModel :scores />
  </div>
</template>

<script lang="ts" setup>
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'

import { useMasterPPFeatureFlags } from '../../composables/useMasterPPFeatureFlags'

import Scorecard from './components/ScoreCard/ScoreCard.vue'

defineProps<{
  scores: GetProductResponse['scores']
  productModel?: string
}>()

const { shouldShowScoreCard } = useMasterPPFeatureFlags()
</script>
