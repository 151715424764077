export default {
  pickerAddonTitle: {
    id: 'rakuten_carousel_picker_addon_title',
    defaultMessage: 'Up to 11,000 points to switch plan.',
  },
  pickerAddonSubtitle: {
    id: 'rakuten_carousel_picker_addon_subtitle',
    defaultMessage: '6,000 points for a new phone line.',
  },
}
