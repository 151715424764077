import type { PartnerNames } from '@backmarket/http-api/src/api-specs-b2c-services/partner-promo-code/types/partner-promo-code'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

export function hasPartnerPromoCode(
  partnerPromoCodes: GetProductResponse['includedServiceOffers']['partnerPromoCodes'],
  partnerName: PartnerNames,
) {
  if (isEmpty(partnerPromoCodes)) return false

  return partnerPromoCodes?.some(
    (partnerPromoCode) => partnerPromoCode.partnerName === partnerName,
  )
}
