import type { Operation } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'

import { applyPriceOperation } from '~/scopes/product/utils/applyPriceOperation'

import type { Step } from './types'

/**
 * Loops through the pickers to apply the service pickers operations to the price.
 * This will never be computed backend side, as trade-in is tied to user's cart, which would completely
 * destroy the cache on our slowest endpoint (pickers, ~300ms of p95)
 */
export const applyOperationOnPickers = (
  steps: Array<Step>,
  id: string | undefined,
  operation: Operation,
): Array<Step> => {
  return steps.map((step) => ({
    ...step,
    options: (step?.options ?? []).map((option) => ({
      ...option,
      price:
        // Don't apply the operation on the step itself
        step.id !== id &&
        // Don't apply the operation on trade in step the price is the one of the swap offer
        step.id !== 'trade_in' &&
        // Checking option.price as it can be undefined - for instance if the option is disabled
        option.price &&
        typeof option.price !== 'string'
          ? applyPriceOperation(option.price, operation) || undefined
          : option.price,
    })),
  }))
}
