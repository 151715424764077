<template>
  <!-- eslint-disable-next-line tailwindcss/no-custom-classname -->
  <div class="pp-message"></div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports'
import { computed, onMounted } from 'vue'

import { MarketCountryCode } from '@backmarket/http-api/src/api-models/MarketCountryCode'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { noop } from '@backmarket/utils/function/noop'

import { usePaymentMethodsWithFallback } from '../../../methods'
import { type PaymentAdvertisingModalProps } from '../../types/PaymentAdvertisingModalProps'
import { PAYPAL_PAY_LATER_AVAILABLE_COUNTRIES } from '../PaypalModal/PaypalModal.config'
import { usePaypalLibrary } from '../PaypalModal/usePaypalLibrary'

const { countriesWithSplitPrice } = useRuntimeConfig().public.payment

const props = withDefaults(defineProps<PaymentAdvertisingModalProps>(), {
  name: 'PaypalAdvertisement',
  paymentMethods: null,
})

const paymentMethods = usePaymentMethodsWithFallback(() => props.paymentMethods)

const paypalPaymentMethod = computed(() => {
  const paypal = paymentMethods.value.find(({ bmCode }) => bmCode === 'paypal')

  if (!paypal) {
    throw new Error('Could not find PayPal payment method')
  }

  return paypal
})

const paypalOptions = computed(() => {
  const { market } = useMarketplace()

  const buyerCountry =
    PAYPAL_PAY_LATER_AVAILABLE_COUNTRIES.find(
      (countryCode) =>
        countriesWithSplitPrice.includes(countryCode) &&
        countryCode === market.countryCode,
    ) || MarketCountryCode.US

  return {
    amount: parseFloat(props.basePrice.amount),
    currency: props.basePrice.currency,
    buyerCountry,
  }
})

const { load } = usePaypalLibrary(
  paypalPaymentMethod,
  noop,
  // Lazy-load the library, to improve performance (especially important on the product page).
  { immediate: false, manual: true },
)

const displayAdvertisement = async () => {
  if (!window.paypal) await load()
  if (window.paypal) {
    window.paypal.Messages(paypalOptions.value).render('.pp-message')
  } else {
    throw new Error('Failed to initialize PayPal library')
  }
}

onMounted(() => {
  displayAdvertisement()
})
</script>
