<template>
  <ul class="flex w-full list-none gap-8">
    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions vuejs-accessibility/click-events-have-key-events -->
    <li
      v-for="(item, index) in count"
      :key="index"
      class="rounded-xs flex h-16 flex-1 cursor-pointer items-center justify-start"
      @click="currentIndex = index"
    >
      <span
        class="rounded-xs relative h-2 w-full"
        :class="{
          'bg-action-default-mid': index >= currentIndex,
          'bg-action-default-hi': index < currentIndex,
        }"
      >
        <span
          v-if="index === currentIndex"
          class="bg-action-default-hi rounded-xs absolute inset-0 h-2"
          :class="[$style.filling, { '[animation-play-state:paused]': paused }]"
          :style="{ 'animation-duration': `${duration}s` }"
        />
      </span>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  items: Array<{ duration: number }>
  paused?: boolean
}>()

const currentIndex = defineModel<number>({ required: true })

const count = computed(() => {
  return props.items.length
})

const duration = computed(() => {
  return props.items[currentIndex.value].duration
})
</script>

<style module>
.filling {
  animation-name: fill;
  animation-timing-function: linear;
  animation-duration: inherit;
}
@keyframes fill {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
