export default {
  bouyguesChipContent: {
    id: 'product_page_bouygues_chip',
    defaultMessage: "Jusqu'à { subsidy } de remise",
  },
  bouyguesIcon: {
    id: 'product_page_bouygues_chip_icon',
    defaultMessage: 'Icone Bouygues',
  },
  bouyguesChipContentWhenEnabled: {
    id: 'product_page_bouygues_chip_enabled',
    defaultMessage: '{subsidy} de réduc’ avec le forfait',
  },
  bouyguesChipContent180EuroFlashOffer: {
    id: 'product_page_bouygues_chip_180_euro_flash_offer',
    defaultMessage: 'Offre flash : jusqu’à 180€ de réduction',
  },
}
