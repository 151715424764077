<template>
  <RevIllustration
    :alt="countryCode"
    :height="sizeAttributes.height"
    :src="`/img/flags/Flag${countryCode}.svg`"
    :width="sizeAttributes.width"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { Country, MarketCountryCode } from '@backmarket/http-api'
import { RevIllustration } from '@ds/components/Illustration'

import type { FlagSize } from './Flag.types'

const props = withDefaults(
  defineProps<{
    countryCode: `${Country}` | `${MarketCountryCode}`
    size?: FlagSize
  }>(),
  {
    size: 'm',
  },
)

const sizeAttributes = computed(() => {
  const attributes: Record<FlagSize, { width: number; height: number }> = {
    s: { width: 12, height: 8 },
    m: { width: 18, height: 12 },
    l: { width: 24, height: 16 },
    xl: { width: 30, height: 20 },
    xxl: { width: 36, height: 24 },
  }

  return attributes[props.size]
})
</script>
