export default {
  screenUsedHeader: {
    id: 'no_grade_step_unavailable_screen_used_header',
    defaultMessage: 'Gently used screen',
  },
  screenUsedTitle: {
    id: 'no_grade_step_unavailable_screen_used_title',
    defaultMessage: 'Your phone comes with a gently used screen',
  },
  screenFlawlessHeader: {
    id: 'no_grade_step_unavailable_screen_flawless_header',
    defaultMessage: 'Flawless screen',
  },
  screenFlawlessTitle: {
    id: 'no_grade_step_unavailable_screen_flawless_title',
    defaultMessage: 'Your phone comes with a flawless screen',
  },
  bodyUsedHeader: {
    id: 'no_grade_step_unavailable_shell_used_header',
    defaultMessage: 'Gently used shell',
  },
  bodyUsedTitle: {
    id: 'no_grade_step_unavailable_shell_used_title',
    defaultMessage: 'Your phone comes with a gently used shell',
  },
  bodyFlawlessHeader: {
    id: 'no_grade_step_unavailable_shell_flawless_header',
    defaultMessage: 'Almost no signs of use shell',
  },
  bodyFlawlessTitle: {
    id: 'no_grade_step_unavailable_shell_flawless_title',
    defaultMessage: 'Your phone comes with almost no signs of use shell',
  },
  batteryHeader: {
    id: 'no_grade_step_unavailable_battery_header',
    defaultMessage: 'Healthy battery',
  },
  batteryTitle: {
    id: 'no_grade_step_unavailable_battery_title',
    defaultMessage: 'Your phone comes with a healthy battery',
  },
  batteryDescription: {
    id: 'no_grade_step_unavailable_battery_description',
    defaultMessage:
      'All devices are guaranteed industry standard battery health, perfect for daily use.',
  },
  buttonLabel: {
    id: 'no_grade_disabled_button_label',
    defaultMessage: 'Continue',
  },
}
