<template>
  <div v-if="selectedOffer.merchant">
    <p class="body-1 mb-4 overflow-hidden">
      {{ i18n(translations.title) }}

      <RevLink
        v-if="isValidMerchantUrl"
        data-qa="seller-info"
        :to="selectedOffer.merchant.merchantUrl"
        @click="onSellerClick"
      >
        {{ selectedOffer.merchant.company }}
      </RevLink>
    </p>
    <p class="text-static-default-low body-2">
      {{
        i18n(translations.subtitle, {
          location: selectedOffer.merchant.country.name,
          since: selectedOffer.merchant.sellerSinceYear,
        })
      }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevLink } from '@ds/components/Link'

import translations from '../../Seller.translations'

const props = defineProps<{
  selectedOffer: NonNullable<GetPickersResponse['selectedOffer']>
}>()

const i18n = useI18n()
const { trackClick } = useTracking()

const isValidMerchantUrl = computed(() => {
  return Boolean(props.selectedOffer.merchant?.merchantUrl)
})

function onSellerClick() {
  trackClick({
    zone: 'recap',
    name: 'seller',
    value: props.selectedOffer.merchant?.country.code || '',
  })
}
</script>
