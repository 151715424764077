<template>
  <section class="text-center">
    <h2 class="heading-1 pt-24">
      {{ i18n(translations.section1Title) }}
    </h2>
    <p class="text-static-default-low body-1 pt-8">
      {{ i18n(translations.section1Description) }}
    </p>

    <ComparisonArea class="pt-32" variant="new_vs_premium" />
  </section>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from '../../NewBatteryModal.translations'
import ComparisonArea from '../ComparisonArea/ComparisonArea.vue'

const i18n = useI18n()
</script>
