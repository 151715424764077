export default {
  title: {
    id: 'product_page_customization_step_mobile_plan_incompatible_sim_info_block_title',
    defaultMessage:
      'Votre configuration de téléphone n’est pas compatible avec ces offres',
  },
  description: {
    id: 'product_page_customization_step_mobile_plan_incompatible_sim_info_block_description',
    defaultMessage:
      'Seuls les téléphones acceptant les cartes SIM physiques sont compatibles. Pour y remédier, changez votre choix à l’étape SIM.',
  },
}
