<template>
  <div
    class="flex h-full items-center gap-8 md:flex-col md:gap-16 md:px-16 md:py-20"
  >
    <div class="shrink-0">
      <RevIllustration
        :alt="title"
        :height="136"
        sizes="136px"
        :src="imageSrc"
        :width="136"
      />
    </div>

    <div
      class="flex flex-col items-start gap-8 md:mt-auto md:items-center md:self-end md:text-center"
    >
      <div class="flex flex-col items-start gap-4 md:items-center">
        <div class="body-1-bold line-clamp-2">
          {{ title }}
        </div>

        <div class="md:text-center">
          <div v-if="description">
            {{ description }}
          </div>
        </div>
      </div>

      <ProductPrice
        :discounted-price="discountedPrice && i18n.price(discountedPrice)"
        :price="i18n.price(price)"
        :price-new="priceNew && i18n.price(priceNew)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Price } from '@backmarket/http-api'
import ProductPrice from '@backmarket/nuxt-layer-recommendation/ProductPrice.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'

defineProps<{
  title: string
  description?: string
  imageSrc: string
  price: Price
  priceNew?: Price
  discountedPrice?: Price
}>()

const i18n = useI18n()
</script>
