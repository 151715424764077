import { useState } from '#imports'

import {
  addServiceToCart,
  addToCart as addToCartAPI,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import type { I18n } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useVisitorId } from '@backmarket/nuxt-module-identification/useVisitorId'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { openModal } from '@ds/components/ModalBase'

import { MODAL_NAMES as SWAP_MODAL_NAMES } from '~/scopes/buyback/constants'
import { useSwapModalStore } from '~/scopes/buyback/swap/stores/useSwapModalStore'
import type { SearchTrackingData } from '~/scopes/search/composables/useProductsSearch'

import { MODAL_NAMES } from '../constants'

import translations from './useAddToCart.translations'
import { useUrlParams } from './useUrlParams'

type ErrorWithType = {
  type: string
}

function hasType(error: unknown): error is ErrorWithType {
  return (
    typeof error === 'object' &&
    error !== null &&
    Object.prototype.hasOwnProperty.call(error, 'type')
  )
}

function getErrorToastParams(error: unknown, i18n: I18n) {
  if (hasType(error)) {
    if (error.type === '/errors/cart/add-product-quantity') {
      return {
        title: i18n(translations.addProductQuantityErrorTitle),
        content: i18n(translations.addProductQuantityErrorDescription),
      }
    }

    if (
      error.type === '/errors/cart/add-service-to-bag/already-one-mobile-plan'
    ) {
      return {
        title: i18n(translations.alreadyOneMobilePlanErrorTitle),
        content: i18n(translations.alreadyOneMobilePlanErrorDescription),
      }
    }
  }

  return {}
}

export const useAddToCart = () => {
  const { openErrorToast } = useTheToast()
  const { trackAddToCart, trackErrors, trackSearchAnalytics } = useTracking()
  const { mobilePlan } = useUrlParams()
  const i18n = useI18n()
  const { hasBuybackOffer } = useBuybackOffer()
  const swapModalStore = useSwapModalStore()

  const visitorId = useVisitorId()

  const addToCart = async ({
    listingId,
    listingPublicId,
    tracking,
    partnerPromoCodes,
    isTradeInEligible,
  }: {
    listingId?: number | null
    listingPublicId: string
    tracking: object
    partnerPromoCodes: GetProductResponse['includedServiceOffers']['partnerPromoCodes']
    isTradeInEligible: boolean
  }) => {
    trackAddToCart({
      ...tracking,
      quantity: 1,
    })

    const searchTrackingData = useState<SearchTrackingData>('search-query')

    try {
      // This fields is optional in the new endpoint but this should no happen
      if (!listingId) {
        throw new Error()
      }

      await $httpFetch(addToCartAPI, {
        body: {
          listing_id: listingId,
          searchRelatedQuery: {
            id: searchTrackingData.value?.id ?? null,
            index: searchTrackingData.value?.index ?? null,
          },
        },
      })

      if (mobilePlan.value) {
        await $httpFetch(addServiceToCart, {
          body: {
            type: 'MOBILE_PLAN',
            listingPublicId,
            offerId: mobilePlan.value,
          },
        })
      }

      const activatedPromoCodeOffer = ['VISIBLE', 'RAKUTEN']

      const promoCodeOffer = partnerPromoCodes.find((partnerPromoCode) =>
        activatedPromoCodeOffer.includes(partnerPromoCode.partnerName),
      )

      if (promoCodeOffer) {
        await $httpFetch(addServiceToCart, {
          body: {
            type: 'PARTNER_PROMO_CODE',
            listingPublicId,
            offerId: promoCodeOffer.id,
            partnerName: promoCodeOffer.partnerName,
          },
        })
      }

      if (
        !hasBuybackOffer.value &&
        swapModalStore.showAddToCartModal &&
        isTradeInEligible
      ) {
        openModal(SWAP_MODAL_NAMES.SWAP_ATC)
      } else {
        openModal(MODAL_NAMES.ADD_TO_CART)
      }

      if (
        searchTrackingData.value?.id &&
        searchTrackingData.value.productObjectId
      ) {
        trackSearchAnalytics({
          type: 'conversion',
          objectId: searchTrackingData.value.productObjectId,
          searchQueryID: searchTrackingData.value.id ?? '',
          tokenId: visitorId,
          index: searchTrackingData.value.index ?? '',
        })
      }
    } catch (error) {
      trackErrors({
        zone: 'add_to_cart',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        message: (error as any)?.payload?.detail ?? 'unknown_error',
      })

      const toastParams = getErrorToastParams(error, i18n)

      openErrorToast(toastParams)
    }
  }

  return {
    addToCart,
  }
}
