<template>
  <div ref="root" class="relative">
    <h2 class="heading-3">
      {{ i18n(translations.SeoSellerContentTitle, { model: title }) }}
    </h2>
    <slot />
    <div
      :class="[
        'text-static-default-low',
        {
          'max-h-[12rem] overflow-hidden text-ellipsis': shouldShowMore,
        },
      ]"
    >
      <SpecificationsOverview
        class="my-12"
        :specifications="technicalSpecificationsArray"
      />
      <!-- The product description is sent by the API as HTML. -->
      <!-- eslint-disable vue/no-v-html -->
      <!-- nosemgrep: javascript.vue.security.audit.xss.templates.avoid-v-html.avoid-v-html -->
      <div class="body-1 my-12" data-selector="pp-text" v-html="description" />

      <SpecificationsTable
        class="my-12"
        :specifications="technicalSpecificationsArray"
        :title="technicalSpecifications.title"
      />
    </div>

    <div
      class="from-bg-surface-default-hi bottom-0 flex w-full justify-center bg-gradient-to-t to-transparent py-12"
      :class="{ absolute: shouldShowMore }"
    >
      <RevButton size="small" variant="primary" @click="toggleShowMore">
        <span class="body-1-bold">
          <span v-if="shouldShowMore">
            {{ i18n(translations.showMore) }}
          </span>

          <span v-if="!shouldShowMore">
            {{ i18n(translations.showLess) }}
          </span>
        </span>
      </RevButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref, watch } from 'vue'

import { type GetTechnicalSpecificationsResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/technical-specifications'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { useIntersectionObserver } from '@vueuse/core'

import translations from './SeoContent.translations'
import SpecificationsOverview from './components/SpecificationsOverview/SpecificationsOverview.vue'
import SpecificationsTable from './components/SpecificationsTable/SpecificationsTable.vue'

const props = defineProps<{
  title: string
  description: string
  model: string | undefined
  technicalSpecifications: GetTechnicalSpecificationsResponse
}>()

const i18n = useI18n()
const shouldShowMore = ref(true)
const hasTriggeredImpression = ref(false)
const root = ref<HTMLElement | null>()

const { trackProductBlockImpression, trackClick } = useTracking()

const technicalSpecificationsArray = computed(() => {
  const { specifications = [] } = props.technicalSpecifications

  return specifications.filter(({ values }) => !isEmpty(values))
})

function toggleShowMore() {
  shouldShowMore.value = !shouldShowMore.value
  if (!shouldShowMore.value) {
    trackClick({ zone: 'pp_tech_specs' })
  }
}

const { stop } = useIntersectionObserver(root, ([{ isIntersecting }]) => {
  if (isIntersecting) {
    trackProductBlockImpression({
      block_name: 'pp_tech_specs',
      product_model: props.model || '',
    })
    hasTriggeredImpression.value = true
  }
})

watch(hasTriggeredImpression, () => {
  if (hasTriggeredImpression.value) {
    stop()
  }
})

onUnmounted(() => {
  stop()
})
</script>
