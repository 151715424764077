export default {
  drawerTitle: {
    id: 'product_page_condition_comparison_modal_title',
    defaultMessage: 'Compare conditions',
  },
  closeButtonLabel: {
    id: 'product_page_condition_comparison_modal_close_button_label',
    defaultMessage: 'Close "Compare conditions" drawer',
  },
  section1Title: {
    id: 'product_page_condition_comparison_modal_section1_title',
    defaultMessage: 'Verified Refurbished every time.',
  },
  section1Description: {
    id: 'product_page_condition_comparison_section1_description',
    defaultMessage:
      'Verified Refurbished means every device has been restored and tested by industry professionals to guarantee high quality and high performance, based on a 25-point inspection.',
  },
}
