import { type MaybeRefOrGetter, computed, toValue } from 'vue'

import type {
  GetPickersResponse,
  Picker,
  PickersResponseItem,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import type { GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { createInjectionState } from '@vueuse/core'

import translations from './useESimExperiment.translations'

const [useProvideESimExperiment, useESimExperimentStore] = createInjectionState(
  (product: MaybeRefOrGetter<GetProductResponse | null>) => {
    const i18n = useI18n()
    const experiment = useExperiments()

    const isExperimentEnabled = computed(
      () => experiment['experiment.ppESimExperiment'] === 'withESimExperiment',
    )
    const isModelForExperiment = computed(() =>
      /iPhone 1[4-9]/.test(toValue(product)?.model ?? ''),
    )

    const isEnabled = computed(
      () => toValue(isExperimentEnabled) && toValue(isModelForExperiment),
    )

    function transformPickersESimExperiment(
      pickers: GetPickersResponse | null,
    ): GetPickersResponse | null {
      if (!pickers || !toValue(isEnabled)) {
        return pickers
      }

      const pickerGroups = pickers.pickerGroups.map((group) => {
        if (group.id !== 'dual_sim') {
          return group
        }

        const items = group.items
          .map(
            ({ label, tag, sortingKey, ...item }) =>
              ({
                ...item,
                sortingKey,
                sublabel: '',
                label: /physical/i.test(`${sortingKey}`)
                  ? i18n(translations.optionPhysicalTitle)
                  : i18n(translations.optionESimTitle),
                tag:
                  !/physical/i.test(`${sortingKey}`) &&
                  /esim/i.test(`${sortingKey}`)
                    ? {
                        label: i18n(translations.optionESimTagLabel),
                        type: 'primary',
                      }
                    : tag,
              }) satisfies PickersResponseItem,
          )
          .sort((a, b) => {
            const aIsPhycical = /physical/i.test(`${a.sortingKey}`)
            const bIsPhycical = /physical/i.test(`${b.sortingKey}`)

            if (aIsPhycical && !bIsPhycical) {
              return -1
            }
            if (!aIsPhycical && bIsPhycical) {
              return 1
            }

            return `${a.sortingKey}`.localeCompare(`${b.sortingKey}`)
          })

        return {
          ...group,
          sectionTitle: i18n(translations.stepSimTitle),
          guidanceCard: group.guidanceCard
            ? {
                ...group.guidanceCard,
                text: i18n(translations.helpCardSimExperiment),
              }
            : undefined,
          items,
        } satisfies Picker
      })

      return {
        ...pickers,
        pickerGroups,
      }
    }

    return {
      isEnabled,
      transformPickersESimExperiment,
    }
  },
)

function useESimExperiment() {
  const store = useESimExperimentStore()

  if (!store) {
    throw new Error(
      'Please call `useProvideESimExperiment` on the appropriate parent component',
    )
  }

  return store
}

export { useProvideESimExperiment, useESimExperiment }
