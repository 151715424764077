import { type Link } from '../../api-models'
import { type HttpResponsePayload, createHttpEndpoint } from '../../utils'

/**
 * Get the data for the client to build the impact of renewed modal, based on a product
 * @see {@link https://devportal.backmarket.io/catalog/default/api/product-page-api/definition#tag/getRenewedImpact/operation/getRenewedImpact}
 */
export const getRenewedImpact = createHttpEndpoint<GetRenewedImpactResponse>({
  method: 'GET',
  operationId: 'getRenewedImpact',
  path: '/bm/product/:productId/renewed-impact',
})

export type GetRenewedImpactResponse = HttpResponsePayload<{
  title: string
  image: string
  paragraphs: Array<{
    title: string
    text: string
    icon: string
  }>
  links: Array<{
    title: string
    link: Link
  }>
}>
