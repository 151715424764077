<template>
  <section
    class="border-static-info-mid rounded-lg grid grid-cols-2 gap-16 border py-8 pe-8 ps-8 md:grid-cols-[1fr_1fr_40%] md:gap-24 md:pe-24"
  >
    <div />
    <div class="flex h-auto w-full items-center">
      <slot name="reviews" />
    </div>
    <div />
  </section>
</template>
