<template>
  <RevIllustration
    v-if="type === 'image'"
    v-bind="$attrs"
    ref="illustration"
    :alt
    :height="1200"
    :loading
    :src="url"
    :width="1200"
    @error="onImageError"
    @load="onMediaLoaded"
  />
  <video
    v-if="type === 'video'"
    v-bind="$attrs"
    ref="video"
    aria-hidden="true"
    class="pointer-events-none"
    muted
    playsinline
    preload="metadata"
    @canplaythrough="onMediaLoaded"
    @error="onVideoError"
    @loadeddata="checkLoaded"
    @loadedmetadata="onVideoMetadataLoaded"
  >
    <source :src="url" type="video/mp4" />
  </video>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref } from 'vue'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { RevIllustration } from '@ds/components/Illustration'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    type: 'image' | 'video'
    url: string
    alt?: string
    loading?: 'lazy' | 'eager'
  }>(),
  {
    alt: '',
    loading: 'lazy',
  },
)

const emits = defineEmits<{
  (e: 'error'): void
  (e: 'loaded'): void
}>()

const logger = useLogger()

const video = ref<HTMLVideoElement | null>(null)
const illustration = ref<typeof RevIllustration | null>(null)
const isPaused = ref(false)

function onImageError() {
  logger.error('[PRODUCT][ImmersiveStories] image cannot be loaded', {
    owners: ['bot-squad-product-page-front'],
  })

  emits('error')
}

function onVideoError() {
  logger.error('[PRODUCT][ImmersiveStories] video cannot be loaded', {
    owners: ['bot-squad-product-page-front'],
  })

  emits('error')
}

function play(force = true) {
  if (force || !isPaused.value) {
    isPaused.value = false
    video.value?.play()
  }
}

function pause() {
  isPaused.value = true
  video.value?.pause()
}

function rewind() {
  if (!video.value) return

  video.value.pause()
  video.value.currentTime = 0

  if (!isPaused.value) {
    video.value.play()
  }
}

function onMediaLoaded() {
  emits('loaded')
}

function onVideoMetadataLoaded() {
  // For iOS, we need to trigger play to force the video to load
  video.value?.play()
  // Then we pause it, because we want to play it only when the video is fully loaded
  video.value?.pause()
}

function checkLoaded() {
  if (
    (props.type === 'video' &&
      video.value?.readyState === HTMLMediaElement.HAVE_ENOUGH_DATA) ||
    (props.type === 'image' &&
      (illustration.value?.$el as HTMLImageElement)?.complete)
  ) {
    onMediaLoaded()
  }
}

onMounted(() => {
  nextTick(checkLoaded)
})

defineExpose({ play, pause, rewind })
</script>
