export default {
  conditions: {
    id: 'grades_modal_condition',
    defaultMessage: 'Conditions',
  },
  moreInfo: {
    id: 'grades_modal_more_info',
    defaultMessage: 'More info',
  },
  alternativeController: {
    id: 'alternative-controller',
    defaultMessage: 'Controller',
  },
  alternativeCurrent: {
    id: 'alternative-current',
    defaultMessage: 'Current Slide',
  },
  alternativeNext: {
    id: 'alternative-next',
    defaultMessage: 'Next',
  },
  alternativePrevious: {
    id: 'alternative-previous',
    defaultMessage: 'Previous',
  },
}
