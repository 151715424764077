import { useRequestURL } from '#imports'
import { type Ref, computed, ref, watch } from 'vue'

import {
  type GetPickersResponse,
  type GetServicesPickersResponse,
  type PickerService,
  hasItemExtraData,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isBrowser } from '@backmarket/utils/env/isBrowser'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { isEqual } from '@backmarket/utils/object/isEqual'

import { useDeliveryBeforeChristmas } from '~/scopes/product/composables/useDeliveryBeforeChristmas'
import { MAX_REVIEWS_DISPLAYED_IN_LIST } from '~/scopes/reviews/reviews-display/constants'

import { StepIndexes } from '../components/NoGrade/NoGrade.constants'
import { hasVisiblePremium } from '../utils/hasVisiblePremium'

import { useNoGradeSteps } from './useNoGradeSteps'
import { useUrlParams } from './useUrlParams'

function getMobilePlanOffers(picker?: PickerService): string {
  if (!picker) return 'no_offer'

  const items = picker.items.filter(
    (item) => item.trackingValue !== 'smartphone_only',
  )

  return (
    items
      .filter((item) => hasItemExtraData(item))
      .map((item) => item.trackingValue)
      .join('-') ?? 'no_offer'
  )
}

export function useProductTracking(
  product: Ref<GetProductResponse | null>,
  selectedOffer: Ref<GetPickersResponse['selectedOffer']>,
  rating: Ref<Rating | null>,
  pickers: Ref<GetPickersResponse | null>,
  isPickersResponsePending: Ref<boolean | null>,
  servicesPickers: Ref<GetServicesPickersResponse | null>,
  isServicesPickersResponsePending: Ref<boolean | null>,
) {
  const { offerType, grade } = useUrlParams()
  const { trackProductPage } = useTracking()
  const previousEvent = ref<unknown>(null)
  const {
    isStandardDeliveryBeforeChristmas,
    isExpressDeliveryBeforeChristmas,
  } = useDeliveryBeforeChristmas(selectedOffer.value?.shipping)

  const { stepStatuses } = useNoGradeSteps(product.value?.model)

  const productEndpointData = computed(() => ({
    dealType: 'normal',
    brand: product.value?.brand ?? '',
    category: product.value?.tracking.categoryName ?? '',
    color: product.value?.tracking.color ?? '',
    image: product.value?.images?.[0]?.url ?? '',
    isSwapEnabled: product.value?.isTradeInEligible ?? false,
    model: product.value?.model ?? '',
    name: product.value?.titles.raw ?? '',
    uuid: product.value?.productId ?? '',
  }))

  const partnerPromoCodes = computed(() => {
    if (
      !product.value ||
      isEmpty(product.value.includedServiceOffers.partnerPromoCodes)
    ) {
      return 'no_offer'
    }

    return product.value.includedServiceOffers.partnerPromoCodes.length > 0
      ? product.value.includedServiceOffers.partnerPromoCodes
          .map((item) => {
            if (item.partnerName === 'VISIBLE' && hasVisiblePremium(item)) {
              return 'VISIBLE_PREMIUM'
            }
            if (item.partnerName === 'VISIBLE' && !hasVisiblePremium(item)) {
              return 'VISIBLE_BASE'
            }

            return item.partnerName
          })
          .join('-')
      : 'no_offer'
  })

  const offerEndpointData = computed(() => {
    let shippingEvent = 'not_on_time_before_event'

    if (
      isStandardDeliveryBeforeChristmas.value &&
      isExpressDeliveryBeforeChristmas.value
    ) {
      shippingEvent = 'on_time_before_event'
    } else if (isExpressDeliveryBeforeChristmas.value) {
      shippingEvent = 'express_on_time_before_event'
    }

    return {
      currency: selectedOffer.value?.price?.currency ?? '',
      hasExpressShipping: !!selectedOffer.value?.shipping?.express,
      listingId: selectedOffer.value?.offerLegacyId ?? 0,
      merchantId: String(selectedOffer.value?.merchant?.merchantId) ?? '',
      sellerOrigin:
        selectedOffer.value?.merchant?.country.code ||
        selectedOffer.value?.merchant?.country.name ||
        '',
      price: selectedOffer.value?.price?.amount ?? '',
      shippingDelay: selectedOffer.value?.shipping?.free?.delayInHours ?? 0,
      shippingEvent,
      variant: selectedOffer.value?.grade?.value ?? 0,
    }
  })

  const pickersEndpointData = computed(() => {
    const productHasGoodDealGrade =
      pickers.value?.pickerGroups
        .find((group) => group.id === 'grades')
        ?.items.findIndex((picker) => picker.goodDeal) !== -1

    const gradeSelectedPicker = pickers.value?.pickerGroups
      .find((group) => group.id === 'grades')
      ?.items.find((picker) => picker.selected)

    const hasPremium =
      pickers.value?.pickerGroups.find((group) => group.id === 'grades')?.items
        .length === 4

    const hasPremiumInStock =
      pickers.value?.pickerGroups
        .find((group) => group.id === 'grades')
        ?.items.find((item) => item.parameters?.grade?.value === 9)
        ?.available ?? false

    const batteryPicker = pickers.value?.pickerGroups.find(
      (group) => group.id === 'battery',
    )

    const hasNewBattery = Boolean(batteryPicker)

    const hasNewBatteryInStock = Boolean(batteryPicker?.items[1].available)

    return {
      productPriceTagSelected: gradeSelectedPicker?.goodDeal || false,
      productPriceTagType: productHasGoodDealGrade
        ? 'good_deal_grade'
        : 'no_tag',
      hasPremium,
      hasPremiumInStock,
      hasNewBattery,
      hasNewBatteryInStock,
      isOutOfStock: pickers.value?.isOutOfStock ?? true,
    }
  })

  const servicesPickersEndpointData = computed(() => {
    const mobilePlanPicker = servicesPickers.value?.pickerGroups.find(
      (group) => group.id === 'mobile_plan',
    )

    const mobilePlanOffers = getMobilePlanOffers(mobilePlanPicker)

    const mobilePlanOfferSelected =
      mobilePlanPicker?.items
        .filter((item) => item.trackingValue !== 'smartphone_only')
        .find((picker) => picker.selected)?.trackingValue ?? ''

    return {
      mobilePlanOffers,
      mobilePlanOfferSelected,
    }
  })

  const rateEndpointData = computed(() => ({
    averageRate: rating.value?.averageRate ?? 0,
    hasReviews: (rating.value?.count ?? 0) > 0,
    numberTotalReviews: rating.value?.count ?? 0,
    numberReviewsDisplayed: Math.min(
      rating.value?.count ?? 0,
      MAX_REVIEWS_DISPLAYED_IN_LIST,
    ),
  }))

  const urlData = computed(() => ({
    mobileDeeplink: `backmarket://product/${
      product.value?.productId
    }?specialOfferType=${offerType?.value ?? '0'}`,
    specialOfferType: offerType?.value ?? '0',
  }))

  // This is not yet set in front-apps, but in pastrami we get some information for the tracking
  // set in the session storage. It's used to know where the user comes from (reco, search, bundle...)
  const sessionStorageData = computed(() => {
    try {
      if (!product.value?.productId || !isBrowser() || !window.sessionStorage) {
        return { list: '' }
      }

      const item = sessionStorage.getItem(product.value?.productId)
      if (!isEmpty(item)) {
        return { list: JSON.parse(item as string).source }
      }
    } catch {
      // fails silently
    }

    return { list: '' }
  })

  const noGradeData = computed(() => ({
    noGradeScreen: stepStatuses.value[StepIndexes.SCREEN],
    noGradeBody: stepStatuses.value[StepIndexes.SHELL],
    noGradeBattery: stepStatuses.value[StepIndexes.BATTERY],
    noGradePremium: stepStatuses.value[StepIndexes.PREMIUM],
  }))

  // This is used for braze campaigns
  const { hostname, protocol, pathname } = useRequestURL()
  const webUrl = `${protocol}//${hostname}${pathname}`

  const productTracking = computed(() => ({
    ...productEndpointData.value,
    ...offerEndpointData.value,
    ...pickersEndpointData.value,
    ...servicesPickersEndpointData.value,
    ...rateEndpointData.value,
    ...urlData.value,
    ...sessionStorageData.value,
    ...noGradeData.value,
    partnerPromoCodes: partnerPromoCodes.value,
    webUrl,
  }))

  // Makes sure we're sending the real data once both calls are finished.
  const isRequestPending = computed(() => {
    return (
      isPickersResponsePending.value || isServicesPickersResponsePending.value
    )
  })

  // Skip tracking during SSR
  if (!process.server) {
    watch(
      [grade, offerType, isRequestPending],
      () => {
        if (
          !isRequestPending.value &&
          !isEqual(productTracking.value, previousEvent.value)
        ) {
          trackProductPage(productTracking.value)

          previousEvent.value = productTracking.value
        }
      },
      { immediate: true },
    )
  }

  return {
    product: productTracking,
  }
}
