import {
  type GetPickersResponse,
  type GetServicesPickersResponse,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'

import { STEP_COLUMNS, STEP_GUIDANCE } from '../CustomizationFunnel.constants'
import { type Prefixes as PickerPrefixes } from '../components/LargePicker/LargePicker.types'

import { getDesktopIllustrations } from './getDesktopIllustrations'
import { getOptionIcon } from './getOptionIcon'
import { type Option, type Step } from './types'

export const mapOption = (
  {
    sublabel = undefined,
    ...option
  }:
    | GetPickersResponse['pickerGroups'][number]['items'][number]
    | GetServicesPickersResponse['pickerGroups'][number]['items'][number],
  pickerPrefix: PickerPrefixes = 'circle',
): Option => {
  return {
    ...option,
    subLabel: sublabel ?? undefined,
    style: {
      prefix: pickerPrefix,
    },
  }
}

export const mapStep = (
  product: GetProductResponse,
  group:
    | GetPickersResponse['pickerGroups'][number]
    | GetServicesPickersResponse['pickerGroups'][number],
): Step => {
  const stepDesktopIllustration = getDesktopIllustrations(product)

  return {
    id: group.id,
    title: group.sectionTitle ?? group.label,
    titleEmphasis: null,
    trackingId: group.trackingId,
    options:
      group.items.map((option) =>
        mapOption(option, getOptionIcon(group, option)),
      ) ?? [],
    desktopIllustration: stepDesktopIllustration[group.id] ?? null,
    columns: STEP_COLUMNS[group.id] ?? undefined,
    guidance:
      group.guidanceCard && STEP_GUIDANCE[group.id]
        ? {
            modalName: STEP_GUIDANCE[group.id],
            ...(group.guidanceCard.icon?.name
              ? { icon: group.guidanceCard.icon.name }
              : {}),
            ...(group.guidanceCard.icon?.url
              ? { image: group.guidanceCard.icon.url }
              : {}),
            label: group.guidanceCard.text,
            clickable: group.guidanceCard.clickable || false,
          }
        : undefined,
    sideContent: group.sideContent,
  }
}

export const mapServiceStep = (
  product: GetProductResponse,
  group: GetServicesPickersResponse['pickerGroups'][number],
): Step => {
  return {
    type: 'service',
    ...mapStep(product, group),
  }
}
