<template>
  <div v-if="shouldDisplayBlock">
    <div class="text-center">
      <IconArrowDown class="m-auto" />
      <h2 class="text-static-default-hi heading-2 pt-16">
        {{ i18n(translations.section2Item1Title) }}
      </h2>
    </div>

    <section class="flex h-[90vh] max-h-[650px] w-full justify-center pt-16">
      <BaseAnimatedProductCheckpoints
        :categories
        class="h-full w-full max-w-[1440px]"
        :i18n
        :trackClick
        trackingZone="product modal"
        variant="row"
      />
    </section>
  </div>
</template>

<script lang="ts" setup>
import { useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import BaseAnimatedProductCheckpoints from '@backmarket/nuxt-layer-cms/BaseAnimatedProductCheckpoints.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { IconArrowDown } from '@ds/icons/IconArrowDown'

import { CATEGORIES_MAP } from './Checkpoints.constants'
import translations from './Checkpoints.translations'

const { trackClick } = useTracking()
const i18n = useI18n()
const { countryCode } = useMarketplace().market
const config = useRuntimeConfig()

const props = defineProps<{
  categoryId: string
}>()

const categories = computed(() => {
  return CATEGORIES_MAP[props.categoryId]
    ? [CATEGORIES_MAP[props.categoryId]]
    : []
})

const shouldDisplayBlock = computed(() => {
  return (
    !isEmpty(categories.value) &&
    config.public.FF_ENABLE_VERIFIED_REFURBISHED_LINK_PP?.includes(countryCode)
  )
})
</script>
