<template>
  <div v-if="shouldDisplayBlock" class="bg-surface-default-low">
    <h2 class="heading-2 mb-24 px-24 text-center">
      {{ i18n(translations.title) }}
    </h2>

    <div class="relative" :class="$style.gradient">
      <ul
        class="text-static-default-mid-disabled flex gap-24 overflow-x-auto px-64 scrollbar-none"
      >
        <li
          v-for="checkpoint in CHECKPOINTS"
          :key="checkpoint.text.id"
          class="flex w-160 shrink-0 flex-col items-center gap-6 text-center"
        >
          <component :is="checkpoint.icon" size="20" />
          <span>{{ i18n(checkpoint.text) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { ProductCategory } from '../../constants'

import { CHECKPOINTS } from './CheckpointsCarousel.constants'
import translations from './CheckpointsCarousel.translations'

const { countryCode } = useMarketplace().market
const config = useRuntimeConfig()
const experiments = useExperiments()
const i18n = useI18n()

const props = defineProps<{
  categoryId: string
}>()

const shouldDisplayBlock = computed(() => {
  return (
    props.categoryId === ProductCategory.SMARTPHONE &&
    config.public.FF_ENABLE_VERIFIED_REFURBISHED_LINK_PP?.includes(
      countryCode,
    ) &&
    experiments['experiment.ppVerifiedRefurbishedCarousel'] === 'withCarousel'
  )
})
</script>

<style module>
.gradient::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    #fff 0%,
    rgba(255, 255, 255, 0) 49.81%,
    #fff 100%
  );
  pointer-events: none;
}
</style>
