export default {
  modalTitle: {
    id: 'product_trade_in_modal_title_main',
    defaultMessage: 'Trade-in',
  },
  close: {
    id: 'product_trade_in_modal_close',
    defaultMessage: 'Close',
  },
  title: {
    id: 'product_trade_in_modal_title',
    defaultMessage: 'How to trade in',
  },
  item1Label: {
    id: 'product_trade_in_modal_item_1_label',
    defaultMessage: 'Get an offer',
  },
  item1Description: {
    id: 'product_trade_in_modal_item_1_description',
    defaultMessage:
      'See an offer from a professional refurbisher in about 2 minutes.',
  },
  item2Label: {
    id: 'product_trade_in_modal_item_2_label',
    defaultMessage: 'Add an item to your cart',
  },
  item2Description: {
    id: 'product_trade_in_modal_item_2_description',
    defaultMessage: 'Shop as usual and see your trade-in value at checkout.',
  },
  item3Label: {
    id: 'product_trade_in_modal_item_3_label',
    defaultMessage: 'Ship your old item for free',
  },
  item3Description: {
    id: 'product_trade_in_modal_item_3_description',
    defaultMessage:
      'You’ll have 21 days to transfer your data to another device and ship your trade-in.',
  },
  item4Label: {
    id: 'product_trade_in_modal_item_4_label',
    defaultMessage: 'Get cash money',
  },
  item4Description: {
    id: 'product_trade_in_modal_item_4_description',
    defaultMessage: 'Receive a direct deposit within 5 days.',
  },
}
