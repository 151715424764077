<template>
  <StepLayout
    :is-focused
    :tracking="{
      trackingTags: {
        tagDisplayed: 'no_tag',
        tagSelected: false,
      },
      trackingId: 'swap',
      trackingModel: model,
      trackingCategory: category,
    }"
    :without-left-template
  >
    <template #left>
      <RevIllustration
        alt=""
        class="block min-w-[337px] max-w-[498px] rounded-[32px]"
        :height="498"
        src="/img/product/funnel/step-trade-in-2.jpg"
        :width="498"
      />
    </template>
    <template #right>
      <div class="flex flex-col">
        <h3 class="heading-1 mb-16">
          {{ i18n(translations.titleStep) }}
        </h3>
        <CardGuidance
          :clickable="true"
          :icon="IconSwap"
          :label="i18n(translations.swapCardGuidance)"
          :modal-name="PRODUCT_MODAL_NAMES.TRADE_IN"
        />
        <ul class="list-none">
          <li class="mb-12">
            <LargePicker
              v-if="buybackOffer"
              :index="1"
              :label="i18n(translations.pickerLabel)"
              prefix="circle"
              :price="i18n.price(buybackOffer.listing.price)"
              :selected="true"
              :sub-label="
                i18n(translations.pickerSubLabel, {
                  price: i18n.price(getDiscountedPrice(price ?? null)),
                })
              "
              :tag="i18n(translations.pickerTag)"
              @click="onClickSummaryPicker"
            />
            <div v-else>
              <RevInputSelectSearchable
                id="swap-select"
                :clear-button-aria-label="
                  i18n(translations.clearButtonAriaLabel)
                "
                :defaultOption
                :hideCheckboxes="true"
                :label="i18n(translations.swapSelectPlaceholder)"
                listbox-placement="bottom"
                :model-value="selectModel"
                :no-options-message="
                  i18n(translations.swapSelectNoOptionsMessage)
                "
                :options="filteredOptions"
                @focus="handleFocus"
                @text-change="handleTextChange"
                @update:model-value="handleModelValueUpdate as unknown"
              />
              <span class="text-static-default-low body-2">
                {{ i18n(translations.swapSelectExample) }}
              </span>
            </div>
          </li>
          <li>
            <LargePicker
              :index="0"
              :label="i18n(translations.noLabel)"
              :selected="noOptionSelected"
              @click="onClickNoPicker"
            />
          </li>
        </ul>
      </div>
    </template>
  </StepLayout>
  <ViewOfferModal
    :modal-name="MODAL_NAMES.SWAP_PP_VIEW_OFFER"
    trackingZone="SWAP_PP_VIEW_OFFER"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue'

import type { Price } from '@backmarket/http-api'
import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'
import { RevIllustration } from '@ds/components/Illustration'
import { RevInputSelectSearchable } from '@ds/components/InputSelectSearchable'
import { openModal } from '@ds/components/ModalBase'
import { IconSwap } from '@ds/icons/IconSwap'

import type { ErrorData } from '~/scopes/buyback/components/TheCatcher/useCatcher'
import { useBuybackPopularSearches } from '~/scopes/buyback/composables/useBuybackPopularSearches'
import {
  type BuybackProduct,
  useBuybackSearch,
} from '~/scopes/buyback/composables/useBuybackSearch'
import { MODAL_NAMES } from '~/scopes/buyback/constants'
import { useSwapModalStore } from '~/scopes/buyback/swap/stores/useSwapModalStore'
import CardGuidance from '~/scopes/product/components/CustomizationFunnel/components/CardGuidance/CardGuidance.vue'
import LargePicker from '~/scopes/product/components/CustomizationFunnel/components/LargePicker/LargePicker.vue'
import StepLayout from '~/scopes/product/components/CustomizationFunnel/components/StepLayout/StepLayout.vue'
import { MODAL_NAMES as PRODUCT_MODAL_NAMES } from '~/scopes/product/constants'

import { SWAP_INFO_MESSAGE } from '../SwapModal/constants'
import ViewOfferModal from '../ViewOfferModal/ViewOfferModal.vue'

import translations from './StepSwap.translations'

type OptionItem = {
  id: number | string
  label: string
  value: string
}

interface SearchProduct {
  label: string
  suffix: string
  id: string
  value: string
}

const props = defineProps<{
  isFocused: boolean
  model?: string
  category?: string
  price?: Price
  withoutLeftTemplate?: boolean
}>()

const i18n = useI18n()
const { setInitialPayload, resetInitialPayload, setShowAddToCartModal } =
  useSwapModalStore()
const { trackClick, trackFormSubmit } = useTracking()
const { deleteBuybackOffer, getDiscountedPrice, buybackOffer } =
  useBuybackOffer()
const { query, hits } = useBuybackSearch('standard-search', {
  debounce: true,
  debounceTime: 300,
})
const { popularSearches } = useBuybackPopularSearches()

const defaultOption = {
  id: 'default',
  label: i18n(translations.swapSelectLabelOptionDefault),
  value: 'default',
}

const selectModel = ref<OptionItem | null>(null)
const noOptionSelected = ref(false)

const errorData = inject<ErrorData>('errorData', ref({ message: '', step: '' }))
errorData.value = { message: SWAP_INFO_MESSAGE.SWAP_STEP, step: '' }

function buybackProductsToSearchProduct(
  products: BuybackProduct[],
): SearchProduct[] {
  return products.map((product) => ({
    label: product.model,
    suffix: i18n(translations.swapSelectSuffixOption, {
      price: i18n.price(product.price, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    }),
    id: product.id,
    value: product.id,
  }))
}

const filteredOptions = computed(() => {
  if (query.value === '') {
    return buybackProductsToSearchProduct(popularSearches.value)
  }

  return buybackProductsToSearchProduct(hits.value)
})

watch(
  () => props.isFocused,
  (isFocused) => {
    if (!isFocused) {
      const focusedElement = document.activeElement as HTMLElement
      focusedElement?.blur()
    }
  },
)

function handleTextChange(value: string) {
  query.value = value
}
function handleModelValueUpdate(option: OptionItem | null) {
  selectModel.value = option
  noOptionSelected.value = false

  if (option) {
    if (option.value === defaultOption.value) {
      resetInitialPayload()

      openModal(MODAL_NAMES.SWAP_MIX_AND_MATCH)

      trackFormSubmit({
        zone: 'tradein_searchbar',
        name: option.value,
        payload: {
          search_type: 'no result',
          query: query.value,
        },
      })

      return
    }

    const selectedHit = [...hits.value, ...popularSearches.value].find(
      (hit) => hit.id === option.id,
    )
    const initialValue = selectedHit
      ? removeEmptyValuesInObject({
          category: selectedHit.category,
          brand: selectedHit.brand,
          model: selectedHit.model,
          model_family: selectedHit.productLine,
        })
      : null
    if (initialValue) {
      setInitialPayload(initialValue)

      openModal(MODAL_NAMES.SWAP_MIX_AND_MATCH)

      trackFormSubmit({
        zone: 'tradein_searchbar',
        name: initialValue?.model ?? '',
        payload: {
          product_public_id: option.id,
          search_type: query.value === '' ? 'popularSearches' : 'suggestions',
        },
      })
    }
  }
}

async function onClickNoPicker() {
  selectModel.value = null
  noOptionSelected.value = true

  resetInitialPayload()
  setShowAddToCartModal(false)
  void deleteBuybackOffer()

  trackClick({
    zone: 'swap',
    name: 'no',
  })
}

function handleFocus() {
  trackClick({
    zone: 'swap',
    name: 'yes',
  })
}

function onClickSummaryPicker() {
  openModal(MODAL_NAMES.SWAP_PP_VIEW_OFFER)
}
</script>
