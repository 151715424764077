<template>
  <div>
    <p class="mb-16">
      {{ i18n(translations.unlockedP1) }}
    </p>
    <p>
      <FormattedMessage :definition="translations.unlockedP2Wrapper">
        <template #link>
          <RevLink :to="link">
            {{ i18n(translations.unlockedP2Link) }}
          </RevLink>
        </template>
      </FormattedMessage>
    </p>
  </div>
</template>

<script lang="ts" setup>
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { RevLink } from '@ds/components/Link'

import { ROUTES as PRODUCT_LIST_ROUTES } from '~/scopes/product-list/route-names'

import translations from './CarriersCompatibilityModal.translations'

const i18n = useI18n()
const locale = useI18nLocale()

const link = {
  name: PRODUCT_LIST_ROUTES.PRODUCT_LIST,
  params: {
    locale,
    slugV2: 'unlocked-cell-phones',
    uuid: '32f9fadc-084b-4227-8f98-0cc5caf69383',
  },
}
</script>
