<template>
  <Chip
    :class="{ 'order-first': isActive }"
    :is-active
    isInteractive
    :label
    @click="openDaModal"
  >
    <template #prefix>
      <IconSwap class="text-static-brand-mid" />
    </template>
  </Chip>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { openModal } from '@ds/components/ModalBase'
import { IconSwap } from '@ds/icons/IconSwap'

import Chip from '~/scopes/product/components/Chip/Chip.vue'
import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './TradeInChip.translations'

const i18n = useI18n()
const { trackClick } = useTracking()
const { buybackOffer } = useBuybackOffer()

const isActive = computed(() => Boolean(buybackOffer.value))

const label = computed(() => {
  if (isActive.value) {
    return i18n(translations.enabled, {
      price: i18n.price(buybackOffer.value?.listing.price || ''),
    })
  }

  return i18n(translations.advertisement)
})

function openDaModal() {
  openModal(MODAL_NAMES.TRADE_IN)
  trackClick({
    zone: 'trade-in pill',
    name: 'trade-in info',
  })
}
</script>
