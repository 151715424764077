<template>
  <RevDrawer
    :close-button-label="i18n(translations.close)"
    :has-padding="false"
    large
    :name="MODAL_NAMES.ADD_TO_CART"
    variant="panel"
  >
    <template #body>
      <div class="pb-[130px] pt-20 md:pb-[74px]">
        <header
          class="bg-static-success-mid absolute top-0 z-10 flex h-72 w-full items-center pl-24 pr-16 md:rounded-tl-lg"
        >
          <IconCheckInCircleFilled class="mr-8 h-32 w-32 flex-none" />
          <h3 class="heading-3 line-clamp-2 grow">
            {{ headerTitle }}
          </h3>
          <RevButtonIcon
            :alternative-text="i18n(translations.close)"
            class="ml-8 justify-self-end"
            :icon="IconCross"
            variant="secondary"
            @click="handleClose"
          />
        </header>

        <div class="space-y-36 p-24 pt-40">
          <RecommendationCarousel :listingId />

          <CrossSellCarousel :listing-id />
        </div>

        <footer class="absolute inset-x-0 bottom-0">
          <div
            class="bg-surface-default-low rounded-bl-lg flex flex-col px-24 py-12 md:flex-row"
            data-qa="sticky-bar"
            data-test="sticky-bar"
          >
            <RevButton
              class="mb-8 md:mb-0 md:mr-12"
              data-qa="continue-shopping"
              full-width="always"
              variant="secondary"
              @click="handleContinue"
            >
              {{ i18n(translations.continue) }}
            </RevButton>
            <RevButton
              data-qa="go-to-cart"
              full-width="always"
              variant="primary"
              @click="handleAddToCartRedirect"
            >
              {{ i18n(translations.goToCart) }}
            </RevButton>
          </div>
        </footer>
      </div>
    </template>
  </RevDrawer>
</template>

<script lang="ts" setup>
import { useRouter } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevButtonIcon } from '@ds/components/ButtonIcon'
import { RevDrawer } from '@ds/components/Drawer'
import { closeModal } from '@ds/components/ModalBase'
import { IconCheckInCircleFilled } from '@ds/icons/IconCheckInCircleFilled'
import { IconCross } from '@ds/icons/IconCross'

import { CHECKOUT } from '~/scopes/checkout/routes-names'

import { MODAL_NAMES } from '../../constants'

import translations from './AddToCartModal.translations'
import CrossSellCarousel from './components/CrossSellCarousel/CrossSellCarousel.vue'
import RecommendationCarousel from './components/RecommendationCarousel/RecommendationCarousel.vue'

const MAX_PRODUCT_TITLE_LENGTH = 25

const { trackClick } = useTracking()

const i18n = useI18n()
const locale = useI18nLocale()
const router = useRouter()

const props = withDefaults(
  defineProps<{
    title: string
    listingId: number
    additionalListings?: Array<string>
  }>(),
  {
    additionalListings: () => [],
  },
)

function truncate(input: string) {
  return input.length > MAX_PRODUCT_TITLE_LENGTH
    ? `${input.substring(0, MAX_PRODUCT_TITLE_LENGTH)}...`
    : input
}

const headerTitle = computed(() => {
  const itemsToATC =
    props.additionalListings.length > 0
      ? props.additionalListings
      : [props.listingId]

  return i18n(translations.title, {
    count: itemsToATC.length,
    product: truncate(props.title),
  })
})

function handleAddToCartRedirect() {
  trackClick({
    name: 'go_to_cart',
    zone: 'add_to_cart_modal',
  })

  router.push({
    name: CHECKOUT.CART,
    params: { locale },
  })
}

function handleContinue() {
  trackClick({
    name: 'continue_shopping',
    zone: 'add_to_cart_modal',
  })

  closeModal(MODAL_NAMES.ADD_TO_CART)
}

function handleClose() {
  trackClick({
    name: 'dismiss_atc_modal',
    zone: 'add_to_cart_modal',
  })
  closeModal(MODAL_NAMES.ADD_TO_CART)
}
</script>
