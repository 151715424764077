<template>
  <div v-if="shouldDisplayCarousel" aria-hidden class="text-center">
    <div class="md:inline-block md:w-300">
      <RevCarousel
        :alternative-button-label="i18n(translations.alternativeController)"
        :alternative-next="i18n(translations.alternativeNext)"
        :alternative-previous="i18n(translations.alternativePrevious)"
        current-index-id="trust-carousel"
        :tracking="{ name: 'social-proof' }"
      >
        <RevIllustration
          v-for="image in carouselImages"
          :key="image"
          alt=""
          :height="180"
          role="img"
          :src="image"
          :width="180"
        />
      </RevCarousel>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevCarousel } from '@ds/components/Carousel'
import { RevIllustration } from '@ds/components/Illustration'

import { SOCIAL_PROOF_IMAGES } from './TrustCarousel.constants'
import translations from './TrustCarousel.translations'

const {
  market: { countryCode },
} = useMarketplace()
const i18n = useI18n()

const carouselImages = SOCIAL_PROOF_IMAGES[countryCode] ?? []
const shouldDisplayCarousel = !isEmpty(carouselImages)
</script>
