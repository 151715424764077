export default {
  cardHeader_unknown: {
    id: 'no_grade_step_body_card_header',
    defaultMessage: 'Shell',
  },
  cardHeader_accepted: {
    id: 'product_page_no_grade_labels_body_flawless',
    defaultMessage: 'Almost no signs of use shell',
  },
  cardHeader_declined: {
    id: 'product_page_no_grade_labels_body_gently_used',
    defaultMessage: 'Gently used shell',
  },
  title: {
    id: 'no_grade_step_body_title',
    defaultMessage: 'Want a shell with almost no signs of use?',
  },
  example: {
    id: 'no_grade_step_body_example',
    defaultMessage: 'Example image',
  },
  accept: {
    id: 'no_grade_step_body_accept',
    defaultMessage: 'Almost no signs of use',
  },
  decline: {
    id: 'no_grade_step_body_decline',
    defaultMessage: 'Gently used',
  },
}
