export default {
  overlayLabelMobile: {
    id: 'product_page_out_of_stock_label_mobile',
    defaultMessage: "Darn, it's out of stock <br />(for now)",
  },
  overlayLabelDesktop: {
    id: 'product_page_out_of_stock_label_desktop',
    defaultMessage: 'Out of stock',
  },
  warningMessage: {
    id: 'product_page_out_of_stock_warning_message',
    defaultMessage: 'We found something similar!',
  },
  unavailableLabel: {
    id: 'product_page_out_of_stock_unavailable_label',
    defaultMessage: 'is currently unavailable.',
  },
  cta: {
    id: 'product_page_out_of_stock_cta',
    defaultMessage: 'Take a look at it',
  },
}
