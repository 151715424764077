<template>
  <div>
    <RecommendationBundle
      v-if="selectedOffer && selectedOffer.offerLegacyId && selectedOffer.price"
      class="mb-72 overflow-hidden"
      :main-product="{
        title: product.titles.default,
        description: product.subtitleElements.join(' - '),
        image: product.images?.[0]?.url,
        productPageLink: {
          type: 'internal',
          name: PRODUCT.HOME,
          params: routeParams,
          query: {},
          hash: {},
        },
        grade: selectedOffer.grade,
        price: selectedOffer.price,
        priceNew: product.priceWhenNew,
        listingId: `${selectedOffer?.offerLegacyId}`,
        brand: product.brand,
        category: product.tracking.categoryName,
        id: product.productId,
        model: product.model ?? '',
        specifications: {},
        listingPublicId: selectedOffer?.offerId,
        mobilePlanOfferId: mobilePlan,
        partnerPromoCodes: product.includedServiceOffers.partnerPromoCodes,
      }"
      :title="i18n(translations.bundleTitle)"
      @add-to-cart="onBundleAddToCart"
    />

    <RecommendationAlgoliaCarousel
      v-if="isAlgoliaRecommendationActive"
      :category-id="product.tracking.categoryId"
      class="mb-72"
      :product-object-id
      :title="i18n(translations.moreTitle)"
      :trackingData="{
        list: 'product page cousins 1',
      }"
      :with-add-to-cart="false"
      @error="onRecommendationsError"
      @loaded="onRecommendationsLoaded"
    />

    <RecommendationCarousel
      v-else
      class="mb-72"
      :options="{
        withCrossedPrice: true,
        withStartingFrom: true,
        withGrade: true,
        withAddToCart: false,
      }"
      :recommendation-query="{
        category: 'cousinsUp',
        scope: 'product',
        scopeId: productId,
        limit: 4,
      }"
      :title="i18n(translations.moreTitle)"
      :trackingData="{
        list: 'product page cousins 1',
      }"
      @error="onRecommendationsError"
      @loaded="onRecommendationsLoaded"
    />

    <RecommendationCarousel
      class="mb-72"
      :options="{
        withCrossedPrice: true,
        withStartingFrom: true,
        withGrade: true,
        withAddToCart: false,
      }"
      :recommendation-query="{
        category: 'cousinsDown',
        scope: 'product',
        scopeId: productId,
        limit: 4,
      }"
      :title="i18n(translations.crossTitle)"
      :trackingData="{
        list: 'product page cousins 2',
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import RecommendationAlgoliaCarousel from '@backmarket/nuxt-layer-recommendation/RecommendationAlgoliaCarousel.vue'
import RecommendationCarousel from '@backmarket/nuxt-layer-recommendation/RecommendationCarousel.vue'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import RecommendationBundle from '~/scopes/recommendation/components/RecommendationBundle.vue'

import { useUrlParams } from '../../composables/useUrlParams'
import { PRODUCT } from '../../route-names'

import translations from './Recommendations.translations'

const { selectedOffer, product } = defineProps<{
  product: GetProductResponse
  selectedOffer: GetPickersResponse['selectedOffer']
}>()

const emit = defineEmits<{
  'bundle-add-to-cart': [Array<string>]
  'recommendations-loaded': [Array<Product>]
  'recommendations-error': []
}>()

const i18n = useI18n()
const { productId, mobilePlan, slug, grade } = useUrlParams()
const routeParams = { uuid: productId, slugV2: slug }
const experiments = useExperiments()
const isAlgoliaRecommendationActive =
  experiments['experiment.algoliaRecommend'] === 'Algolia'

const productObjectId = computed(() => {
  if (!grade.value) return undefined

  return `${productId}_${grade.value}_0`
})

function onBundleAddToCart(listings: Array<string>) {
  emit('bundle-add-to-cart', listings)
}

function onRecommendationsLoaded(products: Array<Product>) {
  emit('recommendations-loaded', products)
}

function onRecommendationsError() {
  emit('recommendations-error')
}
</script>
