import { navigateTo, useRoute } from '#imports'
import { type Ref } from 'vue'

import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { useMasterPP } from '~/scopes/master-pp/composables/useMasterPP'

import { useUrlParams } from './useUrlParams'

// If ProductV2 endpoint has rematched the requested product to a different product,
// we redirect to this rematched product instead.
// /!\ Only product_v2 and best_offers_V3 endpoints handles the rematch feature, as it's quite
// heavy on the database. All the other calls will results in a 404.
export async function useProductRematch(
  product: Ref<GetProductResponse | null>,
) {
  const route = useRoute()
  const logger = useLogger()
  const { slug, productId } = useUrlParams()
  const { isPlpUrl } = useMasterPP()

  if (
    !isPlpUrl.value &&
    product.value &&
    (slug !== product.value?.seo.slug || productId !== product.value?.productId)
  ) {
    logger.info('[PRODUCT] Product rematch redirection', {
      owners: ['bot-squad-product-page-front'],
    })

    const redirect = {
      ...route,
      params: {
        slugV2: product.value.seo.slug,
        uuid: product.value.productId,
      },
    }

    await navigateTo(redirect, { redirectCode: 301 })
  }
}
