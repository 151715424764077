<template>
  <RevList :hasExternalBorders="false">
    <RevListItemInteractive
      class="!px-0"
      :tracking="{
        zone: 'sim',
        name: 'visible_info',
      }"
      @click="openModal(MODAL_NAMES.VISIBLE_BY_VERIZON_BENEFITS)"
    >
      <template #prefix>
        <RevIllustration
          :alt="i18n(translations.logo)"
          :height="40"
          src="/img/visible-by-verizon/visible-by-verizon-logo-square.svg"
          :width="40"
        />
      </template>
      <template #label>
        <p class="body-2-bold ml-8 max-w-[300px]">
          {{ i18n(translations.content) }}
        </p>
      </template>
      <template #suffix>
        <IconChevronRight />
      </template>
    </RevListItemInteractive>
  </RevList>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'
import { RevList } from '@ds/components/List'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'
import { openModal } from '@ds/components/ModalBase'
import { IconChevronRight } from '@ds/icons/IconChevronRight'

import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './VisibleByVerizonSIMAdvertisement.translations'

const i18n = useI18n()
</script>
