<template>
  <div
    class="bg-static-info-mid text-static-default-hi rounded-md body-2 mx-12 mt-8 flex items-center space-x-4 px-12 py-8"
  >
    <IconRocket />
    <FormattedMessage :definition="translations.title" tag="p">
      <template #flashOffer>
        <span class="body-2-bold">
          {{ i18n(translations.flashOfferLabel) }}
        </span>
      </template>
      <template #previousBenefit>
        <span class="text-static-default-low body-2-striked">
          <slot name="previous" />
        </span>
      </template>
      <template #newBenefit>
        <span class="body-2">
          <slot name="new" />
        </span>
      </template>
    </FormattedMessage>
  </div>
</template>

<script setup lang="ts">
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconRocket } from '@ds/icons/IconRocket'

import translations from './BouyguesFlashOffer.translations'

const i18n = useI18n()
</script>
