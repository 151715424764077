<template>
  <div>
    <p class="mb-16">
      {{ i18n(translations.lockedP1) }}
    </p>
    <p>
      <FormattedMessage :definition="translations.lockedP2Wrapper">
        <template #link>
          <RevLink :to="link">
            {{ i18n(translations.lockedP2Link) }}
          </RevLink>
        </template>
      </FormattedMessage>
    </p>
  </div>
</template>

<script lang="ts" setup>
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { RevLink } from '@ds/components/Link'

import { ROUTES as PRODUCT_LIST_ROUTES } from '~/scopes/product-list/route-names'

import translations from './CarriersCompatibilityModal.translations'

const i18n = useI18n()
const locale = useI18nLocale()

const link = {
  name: PRODUCT_LIST_ROUTES.PRODUCT_LIST,
  params: {
    locale,
    slugV2: 'smartphones',
    uuid: '0744fd27-8605-465d-8691-3b6dffda5969',
  },
}
</script>
