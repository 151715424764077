<template>
  <div class="flex items-start">
    <span
      v-for="(part, i) in parts"
      :key="i"
      :class="{
        'caption-bold pt-4': SMALL_PARTS.includes(part.type),
        'heading-2': !SMALL_PARTS.includes(part.type),
      }"
    >
      {{ part.value }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type Price } from '@backmarket/http-api'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'

const props = defineProps<{ price: Price }>()
const locale = useI18nLocale()

const SMALL_PARTS = ['fraction', 'currency', 'decimal']

const parts = computed(() => {
  const intl = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: props.price.currency,
  })

  return intl.formatToParts(parseFloat(props.price.amount))
})
</script>
