<template>
  <component :is="getIcon(icon)" />
</template>

<script lang="ts" setup>
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { ICONS_COMPONENTS } from './DynamicIcon.constants'

defineProps<{
  icon: string
}>()

const logger = useLogger()

function getIcon(icon: string) {
  if (icon in ICONS_COMPONENTS) {
    return ICONS_COMPONENTS[icon as keyof typeof ICONS_COMPONENTS]
  }

  logger.error(
    `[PRODUCT_PAGE] Unable to find load "${icon}". Available icons: ${Object.keys(ICONS_COMPONENTS).join(',')}.`,
    { owners: ['bot-squad-product-page-front'] },
  )

  return null
}
</script>
