import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'

import { ProductCategory } from '~/scopes/product/constants'

import {
  STEP_DESKTOP_ILLUSTRATION_AIRPODS,
  STEP_DESKTOP_ILLUSTRATION_GENERIC,
  STEP_DESKTOP_ILLUSTRATION_MACBOOK,
  STEP_DESKTOP_ILLUSTRATION_SERVICES,
  STEP_DESKTOP_ILLUSTRATION_SMARTPHONE,
} from '../CustomizationFunnel.constants'

export const getDesktopIllustrations = (
  product: GetProductResponse,
): Record<string, string> => {
  let variantIllustrations = {}

  if (product.tracking.categoryId === ProductCategory.SMARTPHONE) {
    variantIllustrations = STEP_DESKTOP_ILLUSTRATION_SMARTPHONE
  } else if (
    [ProductCategory.MACBOOK_INTEL, ProductCategory.MACBOOK_M].includes(
      product.tracking.categoryId,
    )
  ) {
    variantIllustrations = STEP_DESKTOP_ILLUSTRATION_MACBOOK
  } else if (product.tracking.categoryId === ProductCategory.AIRPODS) {
    variantIllustrations = STEP_DESKTOP_ILLUSTRATION_AIRPODS
  } else {
    variantIllustrations = STEP_DESKTOP_ILLUSTRATION_GENERIC
  }

  return {
    ...variantIllustrations,
    ...STEP_DESKTOP_ILLUSTRATION_SERVICES,
  }
}
