import { computed } from 'vue'

import type { GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'

export const useDeliveryBeforeChristmas = (
  shipping?: NonNullable<GetPickersResponse['selectedOffer']>['shipping'],
) => {
  const experiments = useExperiments()

  const deliveryBeforeChristmasTimeFrame = {
    from: Date.parse(`01 Dec ${new Date().getFullYear()} 00:00:00 UTC`),
    to: Date.parse(`24 Dec ${new Date().getFullYear()} 23:59:59 UTC`),
  }

  const isChristmasTime = computed(() => {
    if (
      experiments['experiment.ppDeliveryBeforeXmas'] !==
      'withDeliveryBeforeXmas'
    ) {
      return false
    }

    return (
      Date.now() > deliveryBeforeChristmasTimeFrame.from &&
      Date.now() < deliveryBeforeChristmasTimeFrame.to
    )
  })

  const isStandardDeliveryBeforeChristmas = computed(() => {
    return (
      (isChristmasTime.value &&
        shipping?.free &&
        new Date(shipping.free.latestArrivalDate).valueOf() <
          deliveryBeforeChristmasTimeFrame.to) ??
      false
    )
  })

  const isExpressDeliveryBeforeChristmas = computed(() => {
    return (
      (isChristmasTime.value &&
        shipping?.express &&
        new Date(shipping.express.latestArrivalDate).valueOf() <
          deliveryBeforeChristmasTimeFrame.to) ??
      false
    )
  })

  const hasDeliveryBeforeChristmas = computed(() => {
    return (
      isStandardDeliveryBeforeChristmas.value ||
      isExpressDeliveryBeforeChristmas.value
    )
  })

  return {
    isStandardDeliveryBeforeChristmas,
    isExpressDeliveryBeforeChristmas,
    hasDeliveryBeforeChristmas,
    isChristmasTime,
  }
}
