import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'

type StoriesItem = {
  duration: number
  url: string
  alt: string
  type: 'image' | 'video'
  source?: GetProductResponse['images'][number]['source']
}

export const getStoriesItems = (
  images: GetProductResponse['images'],
): Array<StoriesItem> => {
  return [
    ...images
      .filter((image) => image.source !== 'PARTNER')
      .slice(0, 3)
      .map(
        ({ url, description, source }) =>
          ({
            duration: 2.5,
            url,
            alt: description,
            type: 'image',
            source,
          }) satisfies StoriesItem,
      ),
    {
      duration: 10,
      url: 'https://front-office.statics.backmarket.com/browsing/the_story_of_refurbished.mp4',
      alt: '',
      type: 'video',
    },
    {
      duration: 17,
      url: 'https://front-office.statics.backmarket.com/browsing/unboxing.mp4',
      alt: '',
      type: 'video',
    },
    ...images
      .filter((image) => image.source === 'PARTNER')
      .map(
        ({ url, description, source }) =>
          ({
            duration: 5,
            url,
            alt: description,
            type: 'image',
            source,
          }) satisfies StoriesItem,
      ),
  ]
}
