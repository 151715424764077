import { useState } from '#imports'
import { watch } from 'vue'

import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'

import {
  DEFAULT_NO_GRADE_ACTIVE_STEP,
  DEFAULT_NO_GRADE_STATUSES,
  type StepStatus,
} from '../components/NoGrade/NoGrade.constants'

export function useNoGradeSteps(model: GetProductResponse['model']) {
  const prevModel = useState('prevModel', () => '')

  const activeStep = useState(
    'noGradeActiveStep',
    () => DEFAULT_NO_GRADE_ACTIVE_STEP,
  )

  const stepStatuses = useState<Record<number, StepStatus>>(
    'noGradeStepStatuses',
    () => ({ ...DEFAULT_NO_GRADE_STATUSES }),
  )

  function setStatus(index: number, status: StepStatus) {
    stepStatuses.value[index] = status
  }

  function setActiveStep(index: number) {
    activeStep.value = index
  }

  /**
   * In order to reset no grade steps when we switch to another model
   * Like when we use the recommendation
   * We need to have a watch to reset steps and active step
   */
  watch(
    () => model,
    () => {
      if (model && model !== prevModel.value) {
        prevModel.value = model
        activeStep.value = DEFAULT_NO_GRADE_ACTIVE_STEP
        stepStatuses.value = { ...DEFAULT_NO_GRADE_STATUSES }
      }
    },
    { immediate: true },
  )

  return {
    activeStep,
    stepStatuses,
    setStatus,
    setActiveStep,
  }
}
