export default {
  section1Item1Title: {
    id: 'product_page_new_battery_modal_section1_item1_title',
    defaultMessage: 'With current battery',
  },
  section1Item1TitleNoGradeStandard: {
    id: 'product_page_new_battery_modal_section1_item1_title_no_grade_standard',
    defaultMessage: 'Standard',
  },
  section1Item1TitleNoGradeNew: {
    id: 'product_page_new_battery_modal_section1_item1_title_no_grade_new',
    defaultMessage: 'New battery',
  },
  section1Item1Paragraph1: {
    id: 'product_page_new_battery_modal_section1_item1_paragraph1',
    defaultMessage: 'At least 80% battery capacity',
  },
  section1Item1Paragraph1NoGrade: {
    id: 'product_page_new_battery_modal_section1_item1_paragraph1_no_grade',
    defaultMessage: 'At least 80% battery capacity',
  },
  section1Item1Paragraph1NoGradePremium: {
    id: 'product_page_new_battery_modal_section1_item1_paragraph1_no_grade_premium',
    defaultMessage: '100% battery capacity',
  },
  section1Item1Paragraph2: {
    id: 'product_page_new_battery_modal_section1_item1_paragraph2',
    defaultMessage: 'Long lasting charges for normal use',
  },
  section1Item1Paragraph2NoGrade: {
    id: 'product_page_new_battery_modal_section1_item1_paragraph2_no_grade',
    defaultMessage: 'Expert-approved spare battery',
  },
  section1Item1Paragraph3: {
    id: 'product_page_new_battery_modal_section1_item1_paragraph3',
    defaultMessage: 'Lowest environmental impact',
  },
  section1Item1Paragraph3NoGradeStandard: {
    id: 'product_page_new_battery_modal_section1_item1_paragraph3_no_grade_standard',
    defaultMessage: "Get's you through the day",
  },
  section1Item1Paragraph3NoGradePremium: {
    id: 'product_page_new_battery_modal_section1_item1_paragraph3_no_grade_premium',
    defaultMessage: 'Installed by experts',
  },
  section1Item2Title: {
    id: 'product_page_new_battery_modal_section1_item2_title',
    defaultMessage: 'With new battery',
  },
  section1Item2TitleNoGrade: {
    id: 'product_page_new_battery_modal_section1_item2_title_no_grade',
    defaultMessage: '{brand} battery',
  },
  section1Item2Paragraph1: {
    id: 'product_page_new_battery_modal_section1_item2_paragraph1',
    defaultMessage: '100% battery capacity',
  },
  section1Item2Paragraph1NoGrade: {
    id: 'product_page_new_battery_modal_section1_item2_paragraph1_no_grade',
    defaultMessage: 'Minimum 90% battery capacity',
  },
  section1Item2Paragraph2: {
    id: 'product_page_new_battery_modal_section1_item2_paragraph2',
    defaultMessage: 'Longer lasting charges for heavy use',
  },
  section1Item2Paragraph2NoGrade: {
    id: 'product_page_new_battery_modal_section1_item2_paragraph2_no_grade',
    defaultMessage: 'Geniune {brand} battery',
  },
  section1Item2Paragraph3: {
    id: 'product_page_new_battery_modal_section1_item2_paragraph3',
    defaultMessage: 'Low environmental impact',
  },
  section1Item2Paragraph3NoGradeStandardVSPremium: {
    id: 'product_page_new_battery_modal_section1_item2_paragraph3_no_grade_standard_vs_premium',
    defaultMessage:
      '1.5 hours more battery streaming time daily, subject to use',
  },
  section1Item2Paragraph3NoGradeNewVSPremium: {
    id: 'product_page_new_battery_modal_section1_item2_paragraph3_no_grade_new_vs_premium',
    defaultMessage: 'Checked, tested, and installed by experts',
  },
}
