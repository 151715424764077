import { useHead, useRequestURL, useRouter } from '#imports'
import { type Ref, computed } from 'vue'

import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'

import { ROUTES as PRODUCT_LIST_ROUTES } from '~/scopes/product-list/route-names'

import { useMasterPP } from './useMasterPP'
import { useMasterPPFeatureFlags } from './useMasterPPFeatureFlags'

/**
 * This composable aims to complete `apps/front-office/scopes/product/composables/useProductHead.ts`
 * by overriding some attributes for MasterPP.
 */
export function useMasterPPHead(product: Ref<GetProductResponse | null>) {
  const i18n = useI18n()

  const router = useRouter()
  const { protocol, hostname } = useRequestURL()
  const { getPlpId, getPlpSlug } = useMasterPP()
  const { shouldAddCanonicalUrl } = useMasterPPFeatureFlags()

  /**
   * Canonical is here to help preventing duplicate content with URLs containing query params for instance.
   */
  const link = computed(() => {
    // Data not fetched yet
    if (!product.value) {
      return []
    }

    // Feature flag is disabled
    if (!shouldAddCanonicalUrl.value) {
      return []
    }

    const plpId = getPlpId(product.value?.productId)

    // We are on either Product page or a PLP that are not part of the PoC
    if (!plpId) {
      return []
    }

    const slugV2 = getPlpSlug(plpId)
    const canonicalLink = {
      href: `${protocol}//${hostname}${router.resolve(toNuxtLink({ name: PRODUCT_LIST_ROUTES.PRODUCT_LIST, params: { locale: toBcp47Locale(i18n.currentLocale), slugV2, uuid: plpId } })).href}`,
      rel: 'canonical',
    }

    return [canonicalLink]
  })

  // The way useHead works is it won't delete already added values (eg: links)
  // but eventually override them if the exact same was already defined.
  useHead({
    link,
  })
}
