export default {
  customSelectionTitle: {
    id: 'no_grade_custom_selection_title',
    defaultMessage: 'Custom selection',
  },
  customSelectionPrice: {
    id: 'no_grade_custom_selection_price',
    defaultMessage: 'Starting at {price}',
  },
  previousSelectionTitle: {
    id: 'no_grade_previous_selection_title',
    defaultMessage: 'Previous selection',
  },
  previousSelectionPrice: {
    id: 'no_grade_previous_selection_price',
    defaultMessage: '{price}',
  },
  yourSelectionTitle: {
    id: 'no_grade_your_selection_title',
    defaultMessage: 'Your selection',
  },
  yourSelectionPrice: {
    id: 'no_grade_your_selection_price',
    defaultMessage: '{price}',
  },
} as const
