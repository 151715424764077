<template>
  <RevDrawer
    :close-button-label="i18n(translations.closeButtonLabel)"
    :has-padding="false"
    :name="MODAL_NAMES.VISIBLE_BY_VERIZON_BENEFITS"
    :title="i18n(translations.drawerTitle)"
  >
    <template #body>
      <VisibleByVerizonDrawerBody :has-visible-premium-offer />
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevDrawer } from '@ds/components/Drawer'

import { MODAL_NAMES } from '~/scopes/product/constants'
import { hasVisiblePremium } from '~/scopes/product/utils/hasVisiblePremium'

import translations from './VisibleByVerizonDrawer.translations'
import VisibleByVerizonDrawerBody from './components/VisibleByVerizonDrawerBody/VisibleByVerizonDrawerBody.vue'

const i18n = useI18n()

const props = defineProps<{
  promoCode: GetProductResponse['includedServiceOffers']['partnerPromoCodes'][0]
}>()

const hasVisiblePremiumOffer = computed(() => {
  return hasVisiblePremium(props.promoCode)
})
</script>
