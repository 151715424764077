export default {
  title: {
    id: 'product_accessories_title',
    defaultMessage: 'Comes with',
  },
  modalTrigger: {
    id: 'product_excluded_accessories_modal_trigger',
    defaultMessage: 'See accessories we don’t include',
  },
}
