<template>
  <StepLayout
    :is-focused
    :tracking="{
      trackingTags: {
        tagDisplayed: 'no_tag',
        tagSelected: false,
      },
      trackingId: 'swap',
      trackingModel: model,
      trackingCategory: category,
    }"
    :without-left-template
  >
    <template #left>
      <RevIllustration
        alt=""
        class="block min-w-[337px] max-w-[498px] rounded-[32px]"
        :height="498"
        src="/img/product/funnel/step-trade-in-2.jpg"
        :width="498"
      />
    </template>
    <template #right>
      <SwapOffer
        v-if="hasBuybackOfferAvailable"
        :model
        @default-item-selected="handleDefaultItemSelected"
        @item-selected="handleItemSelected"
      />
      <template v-else>
        <div v-if="selectedModel">Swap Question Flow</div>
        <SwapIntro
          v-else
          @default-item-selected="handleDefaultItemSelected"
          @item-selected="handleItemSelected"
        />
      </template>
    </template>
  </StepLayout>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { type LocationQuery } from 'vue-router'

import type { Price } from '@backmarket/http-api'
import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'
import { RevIllustration } from '@ds/components/Illustration'

import type { BuybackProduct } from '~/scopes/buyback/composables/useBuybackSearch'
import StepLayout from '~/scopes/product/components/CustomizationFunnel/components/StepLayout/StepLayout.vue'

import SwapIntro from '../SwapIntro/SwapIntro.vue'
import SwapOffer from '../SwapOffer/SwapOffer.vue'

const props = defineProps<{
  isFocused: boolean
  withoutLeftTemplate?: boolean
  price?: Price
  model?: string
  category?: string
}>()

const { hasBuybackOfferAvailable } = useBuybackOffer()

const selectedModel = ref<LocationQuery | null>(null)

function handleItemSelected(item: BuybackProduct) {
  selectedModel.value = removeEmptyValuesInObject({
    category: item.category,
    brand: item.brand,
    model: item.model,
    model_family: item.productLine,
  })
}

function handleDefaultItemSelected() {
  selectedModel.value = {}
}

watch(
  () => props.isFocused,
  (isFocused) => {
    if (!isFocused) {
      const focusedElement = document.activeElement as HTMLElement
      focusedElement?.blur()
    }
  },
)
</script>
