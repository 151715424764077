export default {
  option1: {
    id: 'rakuten_carousel_pickers_option_1',
    defaultMessage: 'Get Rakuten Mobile loyalty points',
  },
  hotDealTag: {
    id: 'rakuten_carousel_pickers_hot_deal_tag',
    defaultMessage: 'Hot deal',
  },
  option2: {
    id: 'rakuten_carousel_pickers_option_2',
    defaultMessage: 'Skip for now',
  },
}
