export default {
  moreTitle: {
    id: 'product_page_recommendation_also_title',
    defaultMessage: 'You may also like',
  },
  crossTitle: {
    id: 'product_page_recommendation_cross_title',
    defaultMessage: 'In case you need them',
  },
  bundleTitle: {
    id: 'product_page_recommendation_bundle_title',
    defaultMessage: 'Often bought together',
  },
}
