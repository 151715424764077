export default {
  alternativeController: {
    id: 'product_page_carousel_alternative_controller',
    defaultMessage: 'Controller',
  },
  alternativeNext: {
    id: 'product_page_carousel_alternative_next',
    defaultMessage: 'Next',
  },
  alternativePrevious: {
    id: 'product_page_carousel_alternative_previous',
    defaultMessage: 'Previous',
  },
  alternativeClose: {
    id: 'product_page_carousel_alternative_close',
    defaultMessage: 'Close',
  },
}
