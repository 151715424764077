export default {
  cta: {
    id: 'product_page_out_of_stock_cta',
    defaultMessage: 'Take a look at it',
  },
  recommendedProductTag: {
    id: 'product_page_out_of_stock_recommended_product_tag',
    defaultMessage: 'Best alternative',
  },
  recommendedProductPriceFrom: {
    id: 'product_page_out_of_stock_recommended_product_price_from',
    defaultMessage: 'Starting from',
  },
  recommendedProductPriceStriked: {
    id: 'product_page_out_of_stock_recommended_product_price_striked',
    defaultMessage:
      "Crossed out prices may be the manufacturer's suggested retail price, or may be calculated from the average price of the product from different retail distributors.",
  },
}
