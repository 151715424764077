import { type Price } from '@backmarket/http-api'
import {
  type GetPickersResponse,
  type GetServicesPickersResponse,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { ProductCategory } from '~/scopes/product/constants'

import { applyOperationOnPickers } from './applyOperationOnPickers'
import { getMobilePlanStep } from './steps/mobilePlan'
import { getTradeInStep } from './steps/tradeIn'
import type { Step } from './types'
import { mapStep } from './utils'
/**
 * This function is here to map the response of existing endpoints (pickers, best_offers) to
 * something easily usable by the Customization Funnel.
 *
 * We chose to reuse existing endpoints over a new dedicated one to gain some time developing the
 * MVP that will be A/B tested on smartphones only. This is not meant to be scaled to any other category
 * without a new endpoint to be created.
 */
export const createCustomizationFunnelData = (
  i18n: ReturnType<typeof useI18n>,
  pickers: GetPickersResponse['pickerGroups'],
  pickersServices: GetServicesPickersResponse['pickerGroups'],
  product: GetProductResponse,
  price: Price | null | undefined,
  discountedPrice: Price,
  withNoGrade: boolean,
  ppNoGrade = 'noVariation',
): Step[] => {
  const variantsSteps: Array<Step | null> = pickers
    .map((group) => mapStep(product, group))
    .filter(Boolean)

  const servicesSteps: Array<Step | null> = pickersServices
    .map((group) => {
      switch (group.id) {
        case 'mobile_plan':
          return getMobilePlanStep(i18n, group, price)
        case 'trade_in':
          return getTradeInStep(product, group, i18n, discountedPrice)
        default:
          return null
      }
    })
    .filter(Boolean)

  // If there is no pickers, we don't want to display only services
  let steps = [...variantsSteps]
  if (variantsSteps.length > 0) {
    steps.push(...servicesSteps)
  }

  // All operations to be applied on pickers
  // We iterate over service pickers to retrieve the operations associated to the selected ones.
  const operations = servicesSteps.map((step) => {
    const operation = step?.options.find(({ selected }) => !!selected)
      ?.operation || { type: 'NoOperation' as const, price: null }

    return {
      id: step?.id,
      operation,
    }
  })

  operations.forEach(({ id, operation }) => {
    steps = applyOperationOnPickers(
      steps.filter((step) => !!step) as Step[],
      id,
      operation,
    )
  })

  if (
    product.tracking.categoryId === ProductCategory.SMARTPHONE &&
    (ppNoGrade === 'withNoGrade' || withNoGrade)
  ) {
    steps = steps
      .filter((step) => !!step)
      .filter((step) => !['grades', 'battery'].includes(step?.id ?? ''))
  }

  return steps as unknown as Step[]
}
