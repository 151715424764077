<template>
  <Chip
    isInteractive
    :label="i18n(translations.chipLabel)"
    :link="i18n(translations.chipLink)"
    :suffix-icon="IconExternalLink"
    @click="handleClick"
  >
    <template #prefix>
      <RevIllustration
        alt=""
        :height="20"
        src="/img/rakuten/rakuten-logo-point.svg"
        :width="20"
      />
    </template>
  </Chip>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevIllustration } from '@ds/components/Illustration'
import { IconExternalLink } from '@ds/icons/IconExternalLink'

import Chip from '~/scopes/product/components/Chip/Chip.vue'

import translations from './RakutenChip.translations'

const props = defineProps<{
  trackingZone: string
}>()

const i18n = useI18n()
const { trackClick } = useTracking()

function handleClick() {
  trackClick({
    name: 'rakuten_chip',
    zone: props.trackingZone,
  })
}
</script>
