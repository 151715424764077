<template>
  <div
    class="border-static-default-low mt-12 flex w-full grow border-t pt-12"
  />
  <div v-if="prices && prices[0]" class="flex w-full gap-16 pl-8 pr-16">
    <RevIllustration
      :alt="offer.partner?.label"
      :height="24"
      src="/img/bouygues/bouyguesLogoSquare.svg"
      :width="24"
    />
    <div class="text-left">
      <div class="body-1">
        <span v-if="prices[0].monthsDuration">
          {{
            i18n(translations.firstMonths, {
              price: i18n.price(prices[0].price),
              duration: getFormattedDuration(prices[0].monthsDuration),
            })
          }}
        </span>
        <span v-else>
          {{
            i18n(translations.price, {
              price: i18n.price(prices[0].price),
            })
          }}
        </span>
      </div>

      <div class="text-action-default-low caption">
        <span v-if="prices[1]">
          {{
            i18n(translations.rest, {
              price: i18n.price(prices[1].price),
              enrollmentPeriod: getFormattedDuration(offer.enrollmentPeriod),
            })
          }}
        </span>

        <EnrollmentPeriod
          v-else
          :enrollment-period="offer.enrollmentPeriod"
          :enrollment-translation="translations.enrollmentPeriod"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'

import { useFormatMonthsYearsDuration } from '~/composables/useFormatMonthsYearsDuration'
import EnrollmentPeriod from '~/scopes/checkout/components/EnrollmentPeriod/EnrollmentPeriod.vue'

import translations from './BouyguesOfferSelected.translations'

const props = defineProps<{
  offer: MobilePlanOffer
}>()

const i18n = useI18n()
const { getFormattedDuration } = useFormatMonthsYearsDuration()

const prices = computed(() => {
  return props.offer.priceOptions.find(
    (opt) => opt.option === 'KEEP_PHONE_NUMBER',
  )?.prices
})
</script>
