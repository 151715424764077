export default {
  goodDeal: { id: 'good_deal', defaultMessage: 'Hot deal' },
  soldOut: {
    id: 'already_sold',
    defaultMessage: 'Sold out',
  },
  bestValue: {
    id: 'product_page_pickers_best_value',
    defaultMessage: 'Recommended',
  },
}
