export default {
  cardHeader: {
    id: 'product_page_no_grade_step_premium_card_header',
    defaultMessage: 'Premium',
  },
  title: {
    id: 'product_page_no_grade_step_premium_title',
    defaultMessage: 'Max-out with {highlight, html}',
  },
  titleHighlight: {
    id: 'product_page_no_grade_step_premium_title_highlight',
    defaultMessage: 'Premium',
  },
  accept: {
    id: 'product_page_no_grade_step_premium_accept',
    defaultMessage: 'Premium',
  },
  decline: {
    id: 'product_page_no_grade_step_premium_decline',
    defaultMessage: 'Current selection',
  },
}
