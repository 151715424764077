<template>
  <div v-if="showVatInfoBlock" class="body-2">
    {{ i18n(translations.productVatIncludedInfo) }}
    <RevLink target="_blank" :to="i18n(translations.productVatFaqLink)">
      {{ i18n(translations.productVatFaq) }}
    </RevLink>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevLink } from '@ds/components/Link'

import { COUNTRIES_WITHOUT_VAT_TEXT } from './Vat.constants'
import translations from './Vat.translations'

const i18n = useI18n()
const {
  market: { countryCode },
} = useMarketplace()

const showVatInfoBlock = computed(() => {
  // In some countries (hello Japan!), we want to display the VAT included info below the price
  // (using the `vatIncluded` boolean in config/app.js), but we don't want to display the * with more details
  // below the seller block.
  return !COUNTRIES_WITHOUT_VAT_TEXT.includes(countryCode)
})
</script>
