<template>
  <RevStickyBar
    class="block md:hidden"
    :class="{ '!px-12': isChristmasTime }"
    position="top"
    :visible="showBar"
  >
    <div v-if="!isChristmasTime" class="caption-bold py-4">
      {{ title }}
    </div>
    <div v-else class="flex items-center gap-12">
      <div class="shrink-0">
        <RevIllustration
          v-if="hasDeliveryBeforeChristmas"
          alt=""
          :height="36"
          src="/img/product/delivery-before-christmas.svg"
          :width="36"
        />
        <IconTruckExpress v-else />
      </div>
      <ShippingBlock :shipping />
    </div>
    <slot name="progress-bar" />
  </RevStickyBar>
</template>

<script lang="ts" setup>
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue'

import type { GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { RevIllustration } from '@ds/components/Illustration'
import { RevStickyBar } from '@ds/components/StickyBar'
import { IconTruckExpress } from '@ds/icons/IconTruckExpress'

import { useDeliveryBeforeChristmas } from '~/scopes/product/composables/useDeliveryBeforeChristmas'

import ShippingBlock from '../../../ReassuranceItems/components/ItemShipping/components/ShippingBlock/ShippingBlock.vue'

const props = defineProps<{
  title: string
  shipping: NonNullable<GetPickersResponse['selectedOffer']>['shipping']
}>()

const emit = defineEmits<{
  (event: 'show'): void
  (event: 'hide'): void
}>()

const showBar = ref(false)
const lastYPosition = ref(0)

const { isChristmasTime, hasDeliveryBeforeChristmas } =
  useDeliveryBeforeChristmas(props.shipping)

function updateTopBarDisplay() {
  // To have the correct behavior we need to target the first component of the bottom of the page.
  // Reco are not mandatory so if the reco block is failing, we don't have our limit anymore.
  // Fallback would be reviews but we can have products without reviews.
  // Fallback would be the eco block but we have it only for smartphones.
  // Fallback would be SEO tech specs has it's on every page.
  const recoSection = document.querySelector('[data-id="reco-section"]')
  const reviewsSection = document.querySelector('#reviews')
  const ecoBlockSection = document.querySelector('[data-id="eco-block"]')
  const seoTechSpecSection = document.querySelector(
    '[data-id="seo-technical-specs"]',
  )
  const bottomPageSection =
    recoSection || reviewsSection || ecoBlockSection || seoTechSpecSection
  const bottomPageSectionTopScroll =
    bottomPageSection?.getBoundingClientRect().top

  // Show the Sticky bar when ATC is no more visible
  // Show the Sticky bar when scrolling down
  if (
    window.scrollY > 300 &&
    (lastYPosition.value < window.scrollY ||
      (bottomPageSectionTopScroll && bottomPageSectionTopScroll > 0))
  ) {
    showBar.value = true
    emit('show')
  } else if (lastYPosition.value > window.scrollY) {
    showBar.value = false
    emit('hide')
  }
  lastYPosition.value = window.scrollY
}

function handleScroll() {
  if (window.matchMedia('(max-width: 767px)').matches) {
    window.requestAnimationFrame(updateTopBarDisplay)
  }
}

// The component generally gets unmounted when changing product, but when we play with the pickers
// we want to keep the bar displayed. Here we make sure to have the right display value when mounting the
// component to avoid repeating the appearance animation of RevStickyBar
onBeforeMount(() => {
  updateTopBarDisplay()
})

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>
