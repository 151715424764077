<template>
  <div
    class="relative mx-auto mb-16 max-w-[498px] md:mb-0 md:block md:w-full md:min-w-[337px]"
  >
    <RevCarousel
      v-if="slides.length"
      :alternative-button-label="i18n(translations.alternativeController)"
      :alternative-next="i18n(translations.alternativeNext)"
      :alternative-previous="i18n(translations.alternativePrevious)"
      class="w-full"
      current-index-id="condition-guidance"
      @navigation-click="() => trackCarousel('thumbnail_carousel')"
      @next-button-click="() => trackCarousel('right_arrow')"
      @prev-button-click="() => trackCarousel('left_arrow')"
      @slide-impression="onSlideImpression"
      @swipe="() => trackCarousel('swipe')"
    >
      <div
        v-for="(slide, slideIndex) in slides"
        :key="slide.sectionId"
        class="relative flex w-full rounded-[20px] md:rounded-[32px]"
        data-test="condition-guidance-slide"
      >
        <RevIllustration
          ref="illustrations"
          :alt="slide.title || ''"
          class="h-auto w-full rounded-[20px] md:min-w-[337px] md:max-w-[498px] md:rounded-[32px]"
          :class="grade && $style[`grade-gradient-${grade}`]"
          :height="498"
          :src="slide.imageUrl"
          :width="498"
          @load="onSlideImageLoaded(slideIndex)"
        />

        <ExampleImage
          class="bg-static-default-low absolute right-12 top-12 px-4"
          :with-icon="false"
        />

        <div
          v-if="showTags && slide.tags"
          class="absolute inset-x-0 bottom-0 flex flex-col rounded-b-[20px] p-16 md:flex-row md:flex-wrap md:items-center md:rounded-b-[32px] md:pb-24 md:pl-32 md:pt-48"
          :class="$style.bgGradient"
        >
          <p
            class="text-static-light-hi heading-2 mr-8 mt-8"
            :class="{ flex: isPremiumGrade }"
          >
            <img
              v-if="isPremiumGrade"
              alt=""
              height="33"
              :src="toPublicPath('/img/product/funnel/bay_leaf_left.svg')"
              width="20"
            />
            <span>{{ slide.title }}</span>
            <img
              v-if="isPremiumGrade"
              alt=""
              height="33"
              :src="toPublicPath('/img/product/funnel/bay_leaf_right.svg')"
              width="20"
            />
          </p>
          <div class="flex grow flex-row flex-wrap">
            <div
              v-for="tag in slide.tags"
              :key="tag.id"
              class="bg-static-default-low rounded-full caption mr-8 mt-8 flex w-fit items-center p-4 pr-8 text-center md:body-2"
            >
              <DynamicIcon class="h-16 w-16 shrink-0" :icon="tag.icon" />
              <span class="ml-4 text-left">
                {{ tag.text }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </RevCarousel>
    <div
      v-if="shouldDisplayLoader"
      class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
    >
      <RevSpinner size="large" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toPublicPath } from '#imports'
import { computed, ref, watch } from 'vue'

import type { Picker } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import type { EventData } from '@backmarket/nuxt-module-tracking'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCarousel } from '@ds/components/Carousel'
import { RevIllustration } from '@ds/components/Illustration'
import { RevSpinner } from '@ds/components/Spinner'

import { useUrlParams } from '../../../../composables/useUrlParams'
import ExampleImage from '../../../ExampleImage/ExampleImage.vue'
import DynamicIcon from '../DynamicIcon/DynamicIcon.vue'

import translations from './ConditionGuidance.translations'

const props = withDefaults(
  defineProps<{
    trackingModel?: string
    brand?: string
    content?: NonNullable<Picker['sideContent']>['content'] | undefined
    showTags?: boolean
  }>(),
  {
    trackingModel: '',
    brand: '',
    content: undefined,
    showTags: true,
  },
)

const { trackClick } = useTracking()
const { grade } = useUrlParams()
const i18n = useI18n()

const currentSlideIndex = ref(0)
const loadedImageSlideIndex = ref<Record<number, boolean>>({})
const illustrations = ref<(typeof RevIllustration)[]>([])

watch(
  grade,
  () => {
    loadedImageSlideIndex.value = {}
  },
  { immediate: false },
)

const slides = computed(() => {
  return (
    props.content?.find((entry) => entry.grade === grade.value)?.items || []
  )
})

const shouldDisplayLoader = computed(() => {
  if (!grade.value) {
    return false
  }

  return loadedImageSlideIndex.value[currentSlideIndex.value] !== true
})

const isPremiumGrade = computed(() => {
  return grade.value === 9
})

function onSlideImpression({ index }: { index: number }) {
  if (index !== currentSlideIndex.value) {
    currentSlideIndex.value = index
  }
  loadedImageSlideIndex.value[index] =
    loadedImageSlideIndex.value[index] ||
    illustrations.value?.[index].$el.complete
}

function onSlideImageLoaded(index: number) {
  loadedImageSlideIndex.value[index] = true
}

function trackCarousel(name: string): void {
  const trackingValues: EventData = {
    name,
    zone: 'pp_step_grade_carousel',
    product_model: props.trackingModel,
  }

  if (grade.value) {
    trackingValues.current_grade = grade.value
  }

  trackClick(trackingValues)
}
</script>

<style module>
@media only screen and (max-width: 767px) {
  .bgGradient {
    background-size: 100% 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.5)
    );
  }
}
@media only screen and (min-width: 768px) {
  .bgGradient {
    background-size: 100% 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.3)
    );
  }
}

/* Grade gradient background */
/* PREMIUM */
.grade-gradient-9 {
  background:
    linear-gradient(236deg, rgba(0, 0, 0, 0) 11.69%, #000 91.87%), #686a6e;
}
/* EXCELLENT */
.grade-gradient-10 {
  background:
    linear-gradient(146deg, rgba(143, 162, 223, 0) 7.32%, #cce3ff 87.5%),
    linear-gradient(180deg, #8fa2de 0%, #c4b8f7 100%);
}
/* GOOD */
.grade-gradient-11 {
  background:
    linear-gradient(146deg, rgba(162, 105, 232, 0) 7.32%, #ffccfd 87.5%),
    linear-gradient(180deg, #a269e8 0%, #e2b8f7 100%);
}
/* FAIR */
.grade-gradient-12 {
  background:
    linear-gradient(
      145deg,
      rgba(208, 132, 195, 0) 0.71%,
      rgba(232, 222, 197, 0.9) 86.15%
    ),
    linear-gradient(180deg, #d084c4 0%, #e1b2de 100%);
}
</style>
