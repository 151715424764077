export default {
  cardHeader_unknown: {
    id: 'no_grade_step_battery_card_header',
    defaultMessage: 'Battery',
  },
  cardHeader_declined: {
    id: 'product_page_no_grade_labels_battery_standard',
    defaultMessage: 'Standard battery',
  },
  cardHeader_accepted: {
    id: 'product_page_no_grade_labels_battery_new',
    defaultMessage: 'New battery',
  },
  title: {
    id: 'no_grade_step_battery_title',
    defaultMessage: 'Want a new battery?',
  },
  text: {
    id: 'no_grade_step_battery_text',
    defaultMessage:
      'All devices are guaranteed industry standard battery health, perfect for daily use.',
  },
  link: {
    id: 'no_grade_step_battery_read_more',
    defaultMessage: 'Learn more',
  },
  accept: {
    id: 'no_grade_step_battery_accept',
    defaultMessage: 'New battery',
  },
  decline: {
    id: 'no_grade_step_battery_decline',
    defaultMessage: 'Industry standard',
  },
}
