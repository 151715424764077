export default {
  less: {
    id: 'product_advertisement_chips_less_button',
    defaultMessage: 'View less',
  },
  more: {
    id: 'product_advertisement_chips_more_button',
    defaultMessage: 'View more',
  },
}
