<template>
  <RevCarousel
    :alternative-button-label="i18n(translations.alternativeController)"
    :alternative-next="i18n(translations.alternativeNext)"
    :alternative-previous="i18n(translations.alternativePrevious)"
    class="w-full"
    current-index-id="rakuten-offer-step"
    @next-button-click="
      trackClick({
        name: 'rakuten_arrow',
        zone: 'rakuten_product_page_step',
      })
    "
    @prev-button-click="
      trackClick({
        name: 'rakuten_arrow',
        zone: 'rakuten_product_page_step',
      })
    "
  >
    <CarouselStep
      illustration-src="/img/rakuten/rakuten-pp-carousel-image.svg"
      :title="i18n(translations.carouselTitle1)"
    >
      <div class="flex flex-wrap gap-8">
        <CarouselChip :icon="IconRocket" :label="i18n(translations.chip1)" />
        <CarouselChip
          :icon="IconThumbsUpOutlined"
          :label="i18n(translations.chip2)"
        />
        <CarouselChip :icon="IconMoney" :label="i18n(translations.chip3)" />
      </div>
    </CarouselStep>

    <CarouselStep
      illustration-src="/img/rakuten/rakuten-pp-carousel-image.svg"
      :title="i18n(translations.carouselTitle2)"
    >
      <CarouselChip :icon="IconSmartphone" :label="i18n(translations.chip4)" />
      <CarouselChip :icon="IconPromo" :label="i18n(translations.chip5)" />
      <CarouselChip :icon="IconBill" :label="i18n(translations.chip6)" />
    </CarouselStep>
  </RevCarousel>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCarousel } from '@ds/components/Carousel'
import { IconBill } from '@ds/icons/IconBill'
import { IconMoney } from '@ds/icons/IconMoney'
import { IconPromo } from '@ds/icons/IconPromo'
import { IconRocket } from '@ds/icons/IconRocket'
import { IconSmartphone } from '@ds/icons/IconSmartphone'
import { IconThumbsUpOutlined } from '@ds/icons/IconThumbsUpOutlined'

import CarouselChip from '../../../CarouselChip/CarouselChip.vue'
import CarouselStep from '../../../CarouselStep/CarouselStep.vue'

import translations from './RakutenCarousel.translations'

const i18n = useI18n()
const { trackClick } = useTracking()
</script>
