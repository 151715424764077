export default {
  SeoSellerContentTitle: {
    id: 'product_seo_seller_content',
    defaultMessage: 'Everything you ever wanted to know about the {model}',
  },
  showMore: {
    id: 'show_more',
    defaultMessage: 'Show more',
  },
  showLess: {
    id: 'show_less',
    defaultMessage: 'Show less',
  },
}
