<template>
  <span v-if="vatIncluded" class="text-static-default-low caption">
    {{ i18n(translations.productVatIncluded) }}
  </span>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import translations from './VatIncluded.translations'

const i18n = useI18n()

const {
  features: { vatIncluded },
} = useMarketplace()
</script>
