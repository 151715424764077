<template>
  <div v-if="shouldDisplayBlock">
    <div
      class="text-static-default-low body-1 relative grid gap-x-12 text-left"
      :class="$style.gridTemplateArea"
    >
      <div
        class="bg-surface-default-low rounded-t-md grid px-12 pt-12"
        :class="$style.gridAreaTitle1"
      >
        <h2 class="text-static-default-hi heading-2">
          {{ i18n(translations.section1Item1Title) }}
        </h2>
      </div>
      <div
        class="bg-surface-default-low mood-inverse rounded-t-md flex items-center px-12 pt-12"
        :class="$style.gridAreaTitle2"
      >
        <h2 class="text-static-default-hi heading-2">
          {{ i18n(translations.section1Item2Title) }}
        </h2>
        <IconDiamond class="text-static-default-hi shrink-0" size="24" />
      </div>
      <div
        class="bg-surface-default-low px-8 py-12"
        :class="$style.gridAreaCurrentText1"
      >
        <p
          class="bg-surface-brand-mid text-static-default-hi rounded-md body-2 flex h-full items-center px-12 py-8"
        >
          {{ i18n(translations.section1Item1Paragraph1) }}
        </p>
      </div>
      <div
        class="bg-surface-default-low px-8 pb-12"
        :class="$style.gridAreaCurrentText2"
      >
        <div
          class="bg-surface-brand-mid text-static-default-hi rounded-md flex h-full flex-col justify-center px-12 py-8"
        >
          <p class="text-static-default-hi body-2">
            {{ i18n(translations.section1Item1Paragraph2) }}
          </p>
          <p class="text-static-default-low caption">
            {{ i18n(translations.section1Item1Paragraph2Description) }}
          </p>
        </div>
      </div>
      <div
        class="bg-surface-default-low rounded-b-md px-8 pb-12"
        :class="$style.gridAreaCurrentText3"
      >
        <div
          class="bg-surface-brand-mid text-static-default-hi rounded-md flex h-full flex-col justify-center px-12 py-8"
        >
          <p class="text-static-default-hi body-2">
            {{ i18n(translations.section1Item1Paragraph3) }}
          </p>
          <p class="text-static-default-low caption">
            {{ i18n(translations.section1Item1Paragraph3Description) }}
          </p>
        </div>
      </div>
      <div
        class="bg-surface-default-low mood-inverse px-8 py-12"
        :class="$style.gridAreaNewText1"
      >
        <p
          class="bg-surface-brand-mid text-static-default-hi rounded-md body-2 flex h-full items-center px-12 py-8"
        >
          {{ i18n(translations.section1Item2Paragraph1, { brand }) }}
        </p>
      </div>
      <div
        class="bg-surface-default-low mood-inverse px-8 pb-12"
        :class="$style.gridAreaNewText2"
      >
        <p
          class="bg-surface-brand-mid text-static-default-hi rounded-md body-2 flex h-full items-center px-12 py-8"
        >
          {{ i18n(translations.section1Item2Paragraph2) }}
        </p>
      </div>
      <div
        class="bg-surface-default-low mood-inverse rounded-b-md px-8 pb-12"
        :class="$style.gridAreaNewText3"
      >
        <div
          class="bg-surface-brand-mid text-static-default-hi rounded-md flex h-full flex-col justify-center px-12 py-8"
        >
          <p class="text-static-default-hi body-2">
            {{ i18n(translations.section1Item2Paragraph3, { brand }) }}
          </p>
          <p class="text-static-default-low caption">
            {{ i18n(translations.section1Item2Paragraph3Description) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconDiamond } from '@ds/icons/IconDiamond'

import type { Option } from '~/scopes/product/components/CustomizationFunnel/utils/types'

import translations from './ComparisonArea.translations'

const i18n = useI18n()

const props = defineProps<{
  brand: string
  options?: Option[]
}>()

const shouldDisplayBlock = computed(() => {
  // Using trackingValue as parameters.grade.value is not defined when the picker is out of stock.
  return props.options?.find((option) => option.trackingValue === '9')
})
</script>

<style module>
.gridTemplateArea {
  grid-auto-columns: 1fr;
  grid-template-areas:
    'title1 title2'
    'current-text1 new-text1'
    'current-text2 new-text2'
    'current-text3 new-text3';
}
.gridAreaTitle1 {
  grid-area: title1;
}
.gridAreaCurrentText1 {
  grid-area: current-text1;
}
.gridAreaCurrentText2 {
  grid-area: current-text2;
}
.gridAreaCurrentText3 {
  grid-area: current-text3;
}
.gridAreaTitle2 {
  grid-area: title2;
}
.gridAreaNewText1 {
  grid-area: new-text1;
}
.gridAreaNewText2 {
  grid-area: new-text2;
}
.gridAreaNewText3 {
  grid-area: new-text3;
}
</style>
