export default {
  titleStepMobilePlan: {
    id: 'product_page_customization_step_mobile_plan_title',
    defaultMessage:
      'Jusqu’à {price, html} de réduction immédiate en ajoutant un {emphasis, html}',
  },
  titleEmphasisStepMobilePlan: {
    id: 'product_page_customization_step_mobile_plan_emphasis',
    defaultMessage: 'forfait mobile',
  },
  guidanceMobilePlanBouyguesTelecom: {
    id: 'product_page_customization_step_mobile_plan_guidance',
    defaultMessage: 'Voir le détail des forfaits Bouygues Telecom',
  },
  guidanceMobilePlanBandYou: {
    id: 'product_page_customization_step_mobile_plan_guidance_B&You',
    defaultMessage: 'Voir le détail des forfaits B&You de Bouygues Telecom.',
  },
  mobilePlanOptionDescriptionSamePrice: {
    id: 'product_page_customization_step_mobile_plan_option_description_same_price',
    defaultMessage: '{price}/mois',
  },
  withoutEnrollmentPeriod: {
    id: 'product_page_customization_step_mobile_plan_option_description_without_enrollment_period',
    defaultMessage: ' • Sans engagement',
  },
  withEnrollmentPeriod: {
    id: 'product_page_customization_step_mobile_plan_option_description_with_enrollment_period',
    defaultMessage: " • {duration} d'engagement",
  },
  mobilePlanOptionDescriptionPriceFirstYear: {
    id: 'product_page_customization_step_mobile_plan_option_description_price_first_year',
    defaultMessage: 'à partir de {price}/mois la première année',
  },
  mobilePlanSubsisdy: {
    id: 'product_page_customization_mobile_plan_subsidy_tag',
    defaultMessage: '{subsidy} de remise',
  },
}
