export default {
  title: {
    id: 'product_page_customization_keyboard_title',
    defaultMessage: 'Select the keyboard',
  },
  warning: {
    id: 'product_page_customization_keyboard_warning',
    defaultMessage:
      'This product’s keyboard differs from your country’s standard for now',
  },
  selectAnother: {
    id: 'product_page_customization_keyboard_link',
    defaultMessage: 'Select another Macbook',
  },
}
