<template>
  <component
    :is="item.modalName ? 'button' : 'div'"
    v-for="(item, index) in items"
    :key="item.label"
    class="border-b-static-default-low body-2 flex items-center justify-between border-b py-8"
    :class="[
      $style[`${styleName}${index + 1}`],
      classNames,
      { 'border-b-0': index === items.length - 1 },
    ]"
    @click="handleClick(item)"
    @keydown="($event: KeyboardEvent) => handleKeyDown($event, item)"
  >
    <div class="text-left">
      {{ item.label }}
    </div>
    <IconChevronRight v-if="item.modalName" class="text-static-default-hi" />
  </component>
</template>

<script setup lang="ts">
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { openModal } from '@ds/components/ModalBase'
import { IconChevronRight } from '@ds/icons/IconChevronRight'

import type { ComparisonItem } from './ComparisonItems.types'

defineProps<{
  items: ComparisonItem[]
  styleName: string
  classNames?: string
}>()

const { trackClick } = useTracking()

function openDaModal(item: ComparisonItem): void {
  if (item.tracking) {
    trackClick(item.tracking)
  }
  if (item.modalName) {
    openModal(item.modalName)
  }
}

const handleKeyDown = (event: KeyboardEvent, item: ComparisonItem) => {
  if (event.key === 'Enter' || event.key === ' ') {
    openDaModal(item)
  }
}

const handleClick = (item: ComparisonItem) => {
  openDaModal(item)
}
</script>

<style module>
.gridAreaCurrentTitle {
  grid-area: current-title;
}
.gridAreaCurrentText1 {
  grid-area: current-text1;
}
.gridAreaCurrentText2 {
  grid-area: current-text2;
}
.gridAreaCurrentText3 {
  grid-area: current-text3;
}
.gridAreaCurrentText4 {
  grid-area: current-text4;
}

.gridAreaPremiumTitle {
  grid-area: premium-title;
}
.gridAreaPremiumText1 {
  grid-area: premium-text1;
}
.gridAreaPremiumText2 {
  grid-area: premium-text2;
}
.gridAreaPremiumText3 {
  grid-area: premium-text3;
}
.gridAreaPremiumText4 {
  grid-area: premium-text4;
}
</style>
