import { Locale } from '@backmarket/http-api'

import { ProductCategory } from '~/scopes/product/constants'

export const COMPATIBLE_CATEGORIES = [
  ProductCategory.MACBOOK_INTEL,
  ProductCategory.MACBOOK_M,
]

export const LOCALE_DEFAULT_KEYBOARD: Partial<Record<Locale, string>> = {
  [Locale.de_AT]: 'QWERTZ - Deutsch',
  [Locale.de_DE]: 'QWERTZ - Deutsch',
  [Locale.el_GR]: 'QWERTY - Ελληνική',
  [Locale.en_GB]: 'QWERTY - English',
  [Locale.en_AU]: 'QWERTY - English',
  [Locale.en_IE]: 'QWERTY - English',
  [Locale.en_US]: 'QWERTY - English',
  [Locale.es_ES]: 'QWERTY - Español',
  [Locale.fi_FI]: 'QWERTY - Suomi',
  [Locale.fr_BE]: 'AZERTY - Français',
  [Locale.fr_FR]: 'AZERTY - Français',
  [Locale.it_IT]: 'QWERTY - Italiano',
  [Locale.ja_JP]: 'JIS配列キーボード',
  [Locale.nl_NL]: 'QWERTY - Nederlands',
  [Locale.pt_PT]: 'QWERTY - Português',
  [Locale.sv_SE]: 'QWERTY - Svensk',
  [Locale.sk_SK]: 'QWERTZ - Slovenská',
}
