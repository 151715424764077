import { useRoute, useRouteParams } from '#imports'
import { computed } from 'vue'
import type { LocationQueryValue } from 'vue-router'

import { type Grade } from '@backmarket/http-api'

import { useMasterPP } from '~/scopes/master-pp/composables/useMasterPP'
import { PRODUCT } from '~/scopes/product/route-names'

export type UrlParams = Readonly<{
  productId: string
  slug: string
  grade?: Grade['value']
  offerType?: 7
  mobilePlan?: string
}>

const fromQueryParam = (
  param: LocationQueryValue | LocationQueryValue[],
): string | null => (Array.isArray(param) ? param[0] : (param ?? null))

const fromQueryParamAsNumber = (
  param: LocationQueryValue | LocationQueryValue[],
): number | null => {
  const p = fromQueryParam(param)

  return p ? parseInt(p, 10) : null
}
const fromQueryParamAsBoolean = (
  param: LocationQueryValue | LocationQueryValue[],
): boolean => {
  const p = fromQueryParam(param)

  return Boolean(p && p === 'true')
}

export const useUrlParams = () => {
  const { uuid, slugV2: slug } = useRouteParams()
  const route = useRoute()
  const { masterProductId } = useMasterPP()

  const grade = computed(() => fromQueryParamAsNumber(route.query?.l))
  const offer = computed(() => fromQueryParamAsNumber(route.query?.offerType))
  const mobilePlan = computed(() =>
    fromQueryParam(route.query?.mobilePlanOfferId),
  )
  const productId =
    (route.name === PRODUCT.HOME ? uuid : masterProductId.value) ?? uuid

  const withNoGrade = fromQueryParamAsBoolean(route.query?.withNoGrade)

  return {
    productId,
    slug,
    grade,
    offerType: offer,
    mobilePlan,
    withNoGrade,
  }
}
