<template>
  <CarouselPickerAddon
    icon-src="/img/rakuten/rakuten-logo-point.svg"
    :subtitle="i18n(translations.pickerAddonSubtitle)"
    :title="i18n(translations.pickerAddonTitle)"
  />
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import CarouselPickerAddon from '../../../../../CarouselPickerAddon/CarouselPickerAddon.vue'

import translations from './RakutenCarouselPickerAddon.translations'

const i18n = useI18n()
</script>
