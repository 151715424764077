export default {
  introductionTitle: {
    id: 'product_page_introduction_title',
    defaultMessage: 'Welcome to Back Market',
  },
  introductionDescription: {
    id: 'product_page_introduction_description',
    defaultMessage:
      'The best prices for high-quality renewed goods by refurbishers we’ve checked and vetted. We don’t mind if you {link, html}.',
  },
  introductionLinkText: {
    id: 'product_page_introduction_link_text',
    defaultMessage: 'Google us',
  },
  introductionCta: {
    id: 'product_page_introduction_cta',
    defaultMessage: 'Get to know us',
  },
}
