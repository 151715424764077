import { type Price } from '@backmarket/http-api'
import { type GetServicesPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { mapServiceStep } from '../utils'

import translations from './tradeIn.translations'

export const getTradeInStep = (
  product: GetProductResponse,
  step: GetServicesPickersResponse['pickerGroups'][number],
  i18n: ReturnType<typeof useI18n>,
  discountedPrice: Price,
) => {
  const mappedStep = mapServiceStep(product, step)

  // TODO this should be handled on the back but we can't have the discounted price if we do so.
  mappedStep.options[1].subLabel = i18n(
    translations.tradeInOptionYesDescription,
    {
      price: i18n.price(discountedPrice),
    },
  )

  return mappedStep
}
