<template>
  <RevStickyBar class="block md:hidden" :visible="showBar">
    <div class="flex items-center">
      <div class="grow">
        <Price
          :mobile-plan="selectedMobilePlan"
          :price
          :product
          :selected-offer
        />
      </div>
      <RevButton
        class="ml-8 grow"
        data-qa="product-page-buy-button-mobile"
        variant="primary"
        @click="onAddToCart"
      >
        {{ i18n(translations.addToCart) }}
      </RevButton>
    </div>
  </RevStickyBar>
</template>

<script lang="ts" setup>
import { type Price as PriceType } from '@backmarket/http-api'
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { type GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevStickyBar } from '@ds/components/StickyBar'

import { useAddToCart } from '../../composables/useAddToCart'
import { useProductTracking } from '../../composables/useProductTracking'
import Price from '../Price/Price.vue'

import translations from './StickyBarBottom.translations'

const i18n = useI18n()

const props = withDefaults(
  defineProps<{
    selectedOffer: NonNullable<GetPickersResponse['selectedOffer']>
    price: PriceType
    product: GetProductResponse
    selectedMobilePlan?: MobilePlanOffer
    tracking: ReturnType<typeof useProductTracking>
    showBar?: boolean
  }>(),
  {
    selectedMobilePlan: undefined,
    showBar: false,
  },
)

const { addToCart } = useAddToCart()

function onAddToCart() {
  addToCart({
    listingId: props.selectedOffer.offerLegacyId,
    listingPublicId: props.selectedOffer.offerId,
    tracking: {
      ...props.tracking.product.value,
      list: 'product_page',
    },
    partnerPromoCodes: props.product.includedServiceOffers.partnerPromoCodes,
    isTradeInEligible: props.product.isTradeInEligible,
  })
}
</script>
