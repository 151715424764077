<template>
  <div class="grid w-full place-items-center py-32">
    <RevDivider
      class="col-start-1 col-end-1 row-start-1 row-end-1 w-full border-t-2"
    />
    <span
      class="bg-surface-default-low heading-2 col-start-1 col-end-1 row-start-1 row-end-1 max-w-[75%] px-24"
    >
      {{ i18n(translations.divider) }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevDivider } from '@ds/components/Divider'

import translations from './StepPremiumDivider.translations'

const i18n = useI18n()
</script>
