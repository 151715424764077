<template>
  <div class="flex flex-col">
    <h3 class="heading-1 mb-16">
      {{ i18n(translations.titleStep) }}
    </h3>
    <CardGuidance
      :clickable="true"
      :icon="IconSwap"
      :label="i18n(translations.swapCardGuidance)"
      :modal-name="PRODUCT_MODAL_NAMES.TRADE_IN"
    />
    <ul class="list-none">
      <li class="mb-12">
        <div>
          <TradeInSearchBar
            @default-item-selected="() => $emit('default-item-selected')"
            @item-selected="(item) => $emit('item-selected', item)"
          />
          <span class="text-static-default-low body-2">
            {{ i18n(translations.swapSelectExample) }}
          </span>
        </div>
      </li>
      <li>
        <LargePicker
          :index="0"
          :label="i18n(translations.noLabel)"
          :selected="noOptionSelected"
          @click="onClickNoPicker"
        />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { IconSwap } from '@ds/icons/IconSwap'

import type { BuybackProduct } from '~/scopes/buyback/composables/useBuybackSearch'
import CardGuidance from '~/scopes/product/components/CustomizationFunnel/components/CardGuidance/CardGuidance.vue'
import LargePicker from '~/scopes/product/components/CustomizationFunnel/components/LargePicker/LargePicker.vue'
import { MODAL_NAMES as PRODUCT_MODAL_NAMES } from '~/scopes/product/constants'

import { useSwapModalStore } from '../../stores/useSwapModalStore'
import TradeInSearchBar from '../TradeInSearchBar/TradeInSearchBar.vue'

import translations from './SwapIntro.translations'

const i18n = useI18n()
const { trackClick } = useTracking()
const { setShowAddToCartModal } = useSwapModalStore()

defineEmits<{
  (e: 'item-selected', value: BuybackProduct): void
  (e: 'default-item-selected'): void
}>()

const noOptionSelected = ref(false)

async function onClickNoPicker() {
  noOptionSelected.value = true

  setShowAddToCartModal(false)

  trackClick({
    zone: 'swap',
    name: 'no',
  })
}
</script>
