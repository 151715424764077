<template>
  <div
    class="bg-static-default-mid absolute bottom-0 left-0 right-0 h-[0.2rem] w-full"
  >
    <div
      class="bg-action-default-hi absolute bottom-0 left-0 top-0 h-full pl-72 transition-all duration-500 ease-out motion-reduce:transition-none md:pl-[100px]"
      :style="{ width }"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  currentStep: number
  totalSteps: number
}>()

const width = computed(() => {
  switch (props.currentStep) {
    case 0:
      return '0'
    case props.totalSteps:
      return '100%'
    default:
      return `${Math.round((props.currentStep / props.totalSteps) * 100)}%`
  }
})
</script>
