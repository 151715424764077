<template>
  <div>
    <div class="mb-24 flex items-center gap-x-8">
      <h3 class="heading-3 w-1/2">
        {{ titles.title1 }}
      </h3>
      <h3 class="heading-3 w-1/2">
        {{ titles.title2 }}
      </h3>
    </div>

    <div
      class="text-static-default-low body-1 relative grid grid-cols-2 gap-x-8"
      :class="$style.gridTemplateArea"
    >
      <IconReassuranceCertified
        alt=""
        class="text-static-default-hi absolute left-1/4 top-[-12px] ml-[-16px]"
        size="24"
      />
      <RevIllustration
        v-if="variant === 'standard_vs_new'"
        alt=""
        class="absolute right-1/4 top-[-12px] mr-[-16px]"
        :height="24"
        src="/img/product/new-battery/ThunderIcon.svg"
        :width="24"
      />
      <IconDiamond
        v-else
        class="text-static-default-hi absolute right-1/4 top-[-16px] mr-[-16px]"
        size="32"
      />

      <p
        v-for="(paragraph, index) in paragraphs"
        :key="index"
        class="flex items-center justify-center p-16"
        :class="{
          [tw`bg-static-default-mid`]: variant === 'standard_vs_new',
          [tw`bg-static-info-low`]: variantIncludesPremium,
          [tw`rounded-t-md pt-24`]: index === 0 || index === 1,
          [tw`rounded-b-md pb-24`]: index === 4 || index === 5,
        }"
      >
        {{ paragraph }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { tw } from '@backmarket/utils/string/tw'
import { RevIllustration } from '@ds/components/Illustration'
import { IconDiamond } from '@ds/icons/IconDiamond'
import { IconReassuranceCertified } from '@ds/icons/IconReassuranceCertified'

import { NO_GRADE_BRAND_INJECTION_KEY } from '~/scopes/product/components/NoGrade/NoGrade.constants'

import type { BatteryModalVariants } from '../../NewBatteryModal.types'

import translations from './ComparisonArea.translations'

const i18n = useI18n()

const props = defineProps<{
  /**
   * In the context of No Grade, we share the same component
   * in the NewBatteryModal, but we don't want to display the
   * same content.
   */
  variant: BatteryModalVariants
}>()

/**
 * Injected from the NoGrade component.
 * This value is only used in the context of No Grade.
 */
const brand = inject(NO_GRADE_BRAND_INJECTION_KEY, '')

const titles = computed(
  () =>
    (
      ({
        standard_vs_new: {
          title1: i18n(translations.section1Item1Title),
          title2: i18n(translations.section1Item2Title),
        },
        standard_vs_premium: {
          title1: i18n(translations.section1Item1TitleNoGradeStandard),
          title2: i18n(translations.section1Item2TitleNoGrade, {
            brand,
          }),
        },
        new_vs_premium: {
          title1: i18n(translations.section1Item1TitleNoGradeNew),
          title2: i18n(translations.section1Item2TitleNoGrade, {
            brand,
          }),
        },
      }) satisfies Record<
        BatteryModalVariants,
        { title1: string; title2: string }
      >
    )[props.variant],
)

const textConfig: Record<BatteryModalVariants, string[]> = Object.freeze({
  standard_vs_new: [
    i18n(translations.section1Item1Paragraph1),
    i18n(translations.section1Item2Paragraph1),
    i18n(translations.section1Item1Paragraph2),
    i18n(translations.section1Item2Paragraph2),
    i18n(translations.section1Item1Paragraph3),
    i18n(translations.section1Item2Paragraph3),
  ],
  standard_vs_premium: [
    i18n(translations.section1Item1Paragraph1),
    i18n(translations.section1Item2Paragraph1NoGrade),
    i18n(translations.section1Item1Paragraph3),
    i18n(translations.section1Item2Paragraph2NoGrade, { brand }),
    i18n(translations.section1Item1Paragraph2),
    i18n(translations.section1Item2Paragraph3NoGradeStandardVSPremium),
  ],
  new_vs_premium: [
    i18n(translations.section1Item2Paragraph1),
    i18n(translations.section1Item2Paragraph1NoGrade),
    i18n(translations.section1Item2Paragraph3),
    i18n(translations.section1Item2Paragraph2NoGrade, { brand }),
    i18n(translations.section1Item2Paragraph2),
    i18n(translations.section1Item2Paragraph3NoGradeNewVSPremium),
  ],
})

const paragraphs = computed(() => textConfig[props.variant] || [])

const variantIncludesPremium = computed(
  () =>
    props.variant === 'standard_vs_premium' ||
    props.variant === 'new_vs_premium',
)
</script>

<style module>
.gridTemplateArea {
  grid-template-areas:
    'current-text1 new-text1'
    'current-text2 new-text2'
    'current-text3 new-text3'
    'current-text4 new-text4';
}
.gridAreaCurrentText1 {
  grid-area: current-text1;
}
.gridAreaCurrentText2 {
  grid-area: current-text2;
}
.gridAreaCurrentText3 {
  grid-area: current-text3;
}

.gridAreaCurrentText4 {
  grid-area: current-text4;
}
.gridAreaNewText1 {
  grid-area: new-text1;
}
.gridAreaNewText2 {
  grid-area: new-text2;
}
.gridAreaNewText3 {
  grid-area: new-text3;
}

.gridAreaNewText4 {
  grid-area: new-text4;
}
</style>
