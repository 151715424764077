<template>
  <component
    :is="component"
    class="bg-action-default-mid text-action-default-hi rounded-full body-2 flex h-36 w-fit shrink-0 items-center px-12"
    :class="{
      'hover:bg-action-default-mid-hover': isInteractive,
      'bg-action-default-low border-static-success-hi text-action-success-hi body-2-bold border hover:bg-action-success-min-hover':
        isActive,
    }"
    target="blank"
    :to="link"
  >
    <div v-if="$slots.prefix" class="mr-8 shrink-0">
      <slot name="prefix" />
    </div>

    <p class="text-nowrap">
      {{ label }}
    </p>

    <IconChevronRight v-if="isInteractive && !suffixIcon" class="shrink-0" />
    <component
      :is="suffixIcon"
      v-if="suffixIcon"
      class="ml-5 h-20 w-20 shrink-0"
    />
  </component>
</template>

<script lang="ts" setup>
import { type Component, computed } from 'vue'

import { RevButtonBase } from '@ds/components/ButtonBase'
import { IconChevronRight } from '@ds/icons/IconChevronRight'

const props = defineProps<{
  isInteractive?: boolean
  isActive?: boolean
  label: string
  link?: string
  suffixIcon?: Component
}>()

const component = computed(() => {
  return props?.isInteractive ? RevButtonBase : 'div'
})
</script>
