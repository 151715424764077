<template>
  <div class="flex items-end">
    <h3 class="heading-1">{{ title }}</h3>
    <RevLink
      class="text-body-2-link ml-12 shrink-0"
      target="_blank"
      :to="link"
      :tracking
    >
      {{ i18n(translations.learnMore) }}
    </RevLink>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'

import translations from './CarouselHeader.translations'

const i18n = useI18n()

defineProps<{
  title: string
  tracking: {
    zone: string
    name: string
  }
  link: string
}>()
</script>
