<template>
  <div class="rounded-lg px-16 py-12">
    <div class="flex items-center gap-8">
      <IconCheckWithSparkles v-if="status === 'accepted'" class="mr-8" />
      <IconCheckLarge v-if="status === 'declined'" class="mr-8" />

      <span
        class="text-static-default-hi grow"
        :class="{
          'body-1': !status,
          'body-1-bold': !!status,
        }"
      >
        {{ label }}
      </span>

      <span v-if="price" class="text-static-default-low body-2">
        {{ i18n.price(price) }}
      </span>

      <IconChevronDown
        v-if="!withoutChevron"
        aria-hidden="true"
        class="text-static-default-hi"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Price } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconCheckLarge } from '@ds/icons/IconCheckLarge'
import { IconCheckWithSparkles } from '@ds/icons/IconCheckWithSparkles'
import { IconChevronDown } from '@ds/icons/IconChevronDown'

const i18n = useI18n()

defineProps<{
  label: string
  price?: Price
  status?: 'accepted' | 'declined' | 'unknown'
  withoutChevron?: boolean
}>()
</script>
