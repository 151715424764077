<template>
  <RevCard class="m-0 border" :class="classes" @click="emit('click')">
    <RevSpinner
      v-if="isOpened && isLoading"
      class="mx-auto my-96"
      size="large"
    />
    <slot v-else-if="!isOpened" name="header" />
    <div v-else class="pt-16 md:py-72">
      <slot />
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'

import type { EventData } from '@backmarket/nuxt-module-tracking'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCard } from '@ds/components/Card'
import { RevSpinner } from '@ds/components/Spinner'

const props = withDefaults(
  defineProps<{
    isOpened?: boolean
    isLoading?: boolean
    tracking: EventData
    status: 'accepted' | 'declined' | 'unknown'
  }>(),
  {
    isOpened: false,
    isLoading: false,
    status: 'unknown',
  },
)

const emit = defineEmits<{
  (e: 'click'): void
}>()

const { trackProductBlockImpression } = useTracking()

const classes = computed(() => {
  const colorWhenClosed = ['accepted', 'declined'].includes(props.status)
    ? 'border-action-default-hi'
    : 'border-action-default-low'

  return props.isOpened
    ? 'border-static-brand-mid'
    : `${colorWhenClosed} cursor-pointer`
})

watch(
  () => props.isOpened,
  () => {
    if (props.isOpened) {
      trackProductBlockImpression(props.tracking)
    }
  },
  { immediate: true },
)
</script>
