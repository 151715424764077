import { type Link } from '../../api-models'
import { type HttpResponsePayload, createHttpEndpoint } from '../../utils'

export const getWarrantyServices =
  createHttpEndpoint<GetWarrantyServicesResponse>({
    method: 'GET',
    operationId: 'getWarrantyServices',
    path: '/bm/product/:id/listing/:listingId/warranty_services',
  })

export type GetWarrantyServicesResponse = HttpResponsePayload<{
  title: string
  includedServices: Array<{
    title: string
    texts: Array<string>
    type:
      | 'warranty'
      | 'return'
      | 'shipping'
      | 'customerCare'
      | 'instalmentPayment'
    hyperlink?: {
      text: string
      link: Link
    }
  }>
  fee: {
    title: string
    texts: Array<string>
    type: string
  }
  insuranceBenefits: {
    title: string
    benefits: Array<{
      title: string
      texts: Array<string>
    }>
  }
}>
