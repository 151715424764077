import { MarketCountryCode } from '@backmarket/http-api'

export const EU_COUNTRIES_WITH_SPLIT_PRICE = [
  MarketCountryCode.FR,
  MarketCountryCode.DE,
  MarketCountryCode.ES,
  MarketCountryCode.GB,
  MarketCountryCode.IT,
]

export const COUNTRIES_WITH_SPLIT_PRICE = [
  ...EU_COUNTRIES_WITH_SPLIT_PRICE,
  MarketCountryCode.US,
]

export const enum SplitPriceType {
  MONTHLY = 'MONTHLY',
  MULTIPLE_PAYMENTS_WITHOUT_INTEREST = 'MULTIPLE_PAYMENTS_WITHOUT_INTEREST',
}

export const SPLIT_PRICE_CONFIG: Partial<{
  [key in MarketCountryCode]: {
    type: string
    numberOfPayments: number
    apr: number
  }
}> = {
  [MarketCountryCode.US]: {
    type: SplitPriceType.MONTHLY,
    numberOfPayments: 18,
    apr: 0.15,
  },
  [MarketCountryCode.DE]: {
    type: SplitPriceType.MONTHLY,
    numberOfPayments: 6,
    apr: 0.136,
  },
  [MarketCountryCode.FR]: {
    type: SplitPriceType.MULTIPLE_PAYMENTS_WITHOUT_INTEREST,
    numberOfPayments: 4,
    apr: 0,
  },
  [MarketCountryCode.GB]: {
    type: SplitPriceType.MULTIPLE_PAYMENTS_WITHOUT_INTEREST,
    numberOfPayments: 3,
    apr: 0,
  },
  [MarketCountryCode.ES]: {
    type: SplitPriceType.MULTIPLE_PAYMENTS_WITHOUT_INTEREST,
    numberOfPayments: 3,
    apr: 0,
  },
  [MarketCountryCode.IT]: {
    type: SplitPriceType.MULTIPLE_PAYMENTS_WITHOUT_INTEREST,
    numberOfPayments: 3,
    apr: 0,
  },
}
