export default {
  title: {
    id: 'bouygues_flash_offer_title',
    defaultMessage:
      '{flashOffer, html} {previousBenefit, html} {newBenefit, html}',
  },
  flashOfferLabel: {
    id: 'bouygues_flash_offer_label',
    defaultMessage: 'Flash offer :',
  },
}
