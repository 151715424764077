import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  addProductQuantityErrorTitle: {
    id: 'product_page_add_to_cart_add_product_quantity_error_title',
    defaultMessage: "Vous ne pouvez ajouter qu'un seul produit du même modèle",
  },
  addProductQuantityErrorDescription: {
    id: 'product_page_add_to_cart_add_product_quantity_error_description',
    defaultMessage:
      "Nous vous invitons à finaliser cette commande avant d'en ouvrir une nouvelle.",
  },
  alreadyOneMobilePlanErrorTitle: {
    id: 'product_page_add_to_cart_already_one_mobile_plan_error_title',
    defaultMessage: "Vous ne pouvez souscrire qu'à un seul forfait à la fois.",
  },
  alreadyOneMobilePlanErrorDescription: {
    id: 'product_page_add_to_cart_already_one_mobile_plan_error_description',
    defaultMessage:
      "Nous vous invitons à finaliser cette commande avant d'en ouvrir une nouvelle.",
  },
}

export default translations
