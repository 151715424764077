export default {
  title: {
    id: 'product_esim_modal_title',
    defaultMessage: "eSIM's",
  },
  close: {
    id: 'product_sim_modal_close',
    defaultMessage: 'Close',
  },
  introTitle: {
    id: 'product_esim_modal_intro_title',
    defaultMessage: 'e-SIMs: secure and convenient',
  },
  introText: {
    id: 'product_esim_modal_intro_text',
    defaultMessage:
      'A SIM card, whether physical or digital, allow you to activate the mobile planwith your carrier and contains important information like your phone number.',
  },

  compareSim: {
    id: 'product_esim_modal_compare_sim_title',
    defaultMessage: 'Physical SIM Phone',
  },
  compareEsim: {
    id: 'product_esim_modal_compare_esim_title',
    defaultMessage: 'e-SIM Phone',
  },
  compareSimSubtitle: {
    id: 'product_esim_modal_compare_sim_subtitle',
    defaultMessage: 'Removable card you insert into your phone',
  },
  compareEsimSubtitle: {
    id: 'product_esim_modal_compare_esim_subtitle',
    defaultMessage: 'An embedded SIM that is built into your device',
  },

  compareSimValue1: {
    id: 'product_esim_modal_compare_sim_value_1',
    defaultMessage: 'Security risk if stolen',
  },
  compareSimValue2: {
    id: 'product_esim_modal_compare_sim_value_2',
    defaultMessage: 'Delivery wait if lost',
  },
  compareSimValue3: {
    id: 'product_esim_modal_compare_sim_value_3',
    defaultMessage: 'Limited numbers',
  },

  compareESimValue1: {
    id: 'product_esim_modal_compare_esim_value_1',
    defaultMessage: 'Impossible to lose',
  },
  compareESimValue2: {
    id: 'product_esim_modal_compare_esim_value_2',
    defaultMessage: 'Instant activation',
  },
  compareESimValue3: {
    id: 'product_esim_modal_compare_esim_value_3',
    defaultMessage: 'Unlimited numbers',
  },

  infoBlockTitle: {
    id: 'product_esim_modal_info_title',
    defaultMessage: 'Next steps if you want to switch to e-SIM',
  },
  infoBlockText1: {
    id: 'product_esim_modal_info_text_1',
    defaultMessage:
      'Great! You found a device that supports e-SIM. Reach out to your carrier to confirm they support e-SIMs and request activation. They should make it super easy for you with a QR code or activation code.',
  },
  infoBlockText2: {
    id: 'product_esim_modal_info_text_2',
    defaultMessage:
      'From there, you’ll activate the e-SIM and follow on-screen instructions.',
  },

  faqTitle: {
    id: 'product_esim_modal_faq_title',
    defaultMessage: 'Frequently Asked Questions',
  },
  faqQuestion1Title: {
    id: 'product_esim_modal_faq_question_1_title',
    defaultMessage: 'Can a physical SIM and e-SIM be used on the same device?',
  },
  faqQuestion1Answer: {
    id: 'product_esim_modal_faq_question_1_answer',
    defaultMessage:
      'Many devices support dual SIM functionality, so you can have both a physical SIM and an e-SIM and use them at the same time.',
  },
  faqQuestion2Title: {
    id: 'product_esim_modal_faq_question_2_title',
    defaultMessage: 'Can multiple profiles be stored on an e-SIM?',
  },
  faqQuestion2Answer: {
    id: 'product_esim_modal_faq_question_2_answer',
    defaultMessage:
      'Yes, one of the benefits of e-SIMs is that you can store multiple carrier profiles, giving you the option of switching between different numbers or plans on the same device.',
  },
  faqQuestion3Title: {
    id: 'product_esim_modal_faq_question_3_title',
    defaultMessage: 'Do all carriers support e-SIMs?',
  },
  faqQuestion3Answer: {
    id: 'product_esim_modal_faq_question_3_answer',
    defaultMessage:
      "Not all carriers support e-SIMs, so it's important to check with your carrier to confirm.",
  },

  popular: {
    id: 'product_picker_dual_sim_option_esim_tag_label',
    defaultMessage: 'popular',
  },
}
