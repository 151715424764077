export default {
  titleStep: {
    id: 'swap_step_title',
    defaultMessage: 'Trade in an old device for cashback on today’s purchase',
  },
  swapSelectPlaceholder: {
    id: 'swap_step_select_placeholder',
    defaultMessage: 'Select brand and model',
  },
  swapSelectNoOptionsMessage: {
    id: 'swap_step_select_no_option',
    defaultMessage: 'No results available',
  },
  clearButtonAriaLabel: {
    id: 'swap_step_select_clear',
    defaultMessage: 'Clear',
  },
  swapSelectLabelOptionDefault: {
    id: 'swap_step_select_label_option_default',
    defaultMessage: "My device isn't listed here",
  },
  swapSelectExample: {
    id: 'swap_step_select_example',
    defaultMessage: 'e.g. iPhone 11, Samsung Galaxy 10...',
  },
  swapCardGuidance: {
    id: 'swap_step_card_guidance',
    defaultMessage: 'See how Trade-in works',
  },
  swapSelectLabelOption: {
    id: 'swap_step_select_label_option',
    defaultMessage: '{model} - Up to {price}',
  },
  swapSelectSuffixOption: {
    id: 'swap_step_select_suffix_option',
    defaultMessage: 'Up to {price}',
  },
  noLabel: {
    id: 'swap_step_no_label',
    defaultMessage: 'No trade in',
  },
  pickerLabel: {
    id: 'swap_step_picker_label',
    defaultMessage: 'Yes, trade in a device',
  },
  pickerTag: {
    id: 'swap_step_picker_tag',
    defaultMessage: 'Your trade in offer',
  },
  pickerSubLabel: {
    id: 'swap_step_picker_sublabel',
    defaultMessage:
      "We'll buy back your old tech for cash, which could make today's total more like { price } after your trade-in.",
  },
}
