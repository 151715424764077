import { type Ref, computed } from 'vue'

import {
  type GetPickersResponse,
  type GetServicesPickersResponse,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'

export function useProductPrice({
  pickers,
  selectedOffer,
}: {
  pickers: Ref<GetServicesPickersResponse | null>
  selectedOffer: Ref<GetPickersResponse['selectedOffer']>
}) {
  /**
   * This is the raw offer price (based on the product and seller listing) customer has to pay without any subsidy
   * nor extra service.
   */
  const priceWithoutSubsidies = computed(() => {
    return selectedOffer.value?.price
  })

  /**
   * The total price is computed using the price without subsidies, and we add/substract all services
   * added in the funnel (using services/pickers endpoint)
   */
  const price = computed(() => {
    if (!priceWithoutSubsidies.value) {
      return undefined
    }

    if (!pickers.value) {
      return priceWithoutSubsidies.value
    }

    const computedPrice = pickers.value.pickerGroups.reduce<number>(
      (total, pickerGroup) => {
        const item = pickerGroup.items.find(({ selected }) => !!selected)

        if (!total || !item || !item.operation || !item.operation.price) {
          return total
        }

        if (item.operation.type === 'Add') {
          return total + parseFloat(item.operation.price.amount)
        }

        if (item.operation.type === 'Subtract') {
          return total - parseFloat(item.operation.price.amount)
        }

        return total
      },
      parseFloat(priceWithoutSubsidies.value.amount),
    )

    return {
      amount: `${computedPrice < 0 ? 0 : computedPrice.toFixed(2)}`,
      currency: priceWithoutSubsidies.value.currency,
    }
  })

  /**
   * The total price is computed using the price without subsidies nor trade-in, and we add/substract all services
   * added in the funnel (using services/pickers endpoint)
   */
  const priceWithoutTradeIn = computed(() => {
    if (!priceWithoutSubsidies.value) {
      return undefined
    }

    if (!pickers.value) {
      return priceWithoutSubsidies.value
    }

    const computedPrice = pickers.value.pickerGroups.reduce<number>(
      (total, pickerGroup) => {
        const item = pickerGroup.items.find(({ selected }) => !!selected)

        if (!total || !item || !item.operation || !item.operation.price) {
          return total
        }

        if (pickerGroup.id === 'trade_in') {
          return total
        }

        if (item.operation.type === 'Add') {
          return total + parseFloat(item.operation.price.amount)
        }

        if (item.operation.type === 'Subtract') {
          return total - parseFloat(item.operation.price.amount)
        }

        return total
      },
      parseFloat(priceWithoutSubsidies.value.amount),
    )

    return {
      amount: `${computedPrice < 0 ? 0 : computedPrice.toFixed(2)}`,
      currency: priceWithoutSubsidies.value.currency,
    }
  })

  return {
    priceWithoutSubsidies,
    priceWithoutTradeIn,
    price,
  }
}
