<template>
  <LargePicker
    :aria-disabled="disabled"
    :color
    :description
    :disabled
    :index
    :is-good-deal="goodDeal"
    :is-small="trackingId === 'grade_picker'"
    :label
    :loading
    :prefix="pickerPrefix"
    :price="description"
    :selected
    :tag
    @click="onClick"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type Price } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import LargePicker from '../../../CustomizationFunnel/components/LargePicker/LargePicker.vue'

import translations from './Picker.translations'

const i18n = useI18n()
const { trackClick } = useTracking()

export type PickerProps = {
  shouldDisplaySoldOut?: boolean
  label: string
  trackingId?: string | null
  trackingValue?: string | null
  available: boolean
  selected: boolean
  index: number
  price?: Price | null
  color: string | null
  goodDeal?: boolean
  loading?: boolean
  groupId: string
  hasStaffPick?: boolean
}

const props = withDefaults(defineProps<PickerProps>(), {
  shouldDisplaySoldOut: false,
  trackingId: '',
  trackingValue: '',
  price: null,
  color: undefined,
  goodDeal: false,
  hasStaffPick: false,
  loading: false,
})

const emit = defineEmits(['click'])

const disabled = computed(() => !props.available)

const description = computed(() => {
  if (props.shouldDisplaySoldOut && disabled.value) {
    return i18n(translations.soldOut)
  }

  if (!isEmpty(props.price) && props.trackingId === 'grade_picker') {
    return i18n.price(props.price)
  }

  return ''
})

const tag = computed(() => {
  if (
    props.hasStaffPick &&
    props.trackingId === 'grade_picker' &&
    props.trackingValue === '11' &&
    props.available
  ) {
    return i18n(translations.bestValue)
  }

  return ''
})

const pickerPrefix = computed(() => {
  // Empty color means that we are missing the color hash but it is still a color picker
  if (props.color || props.color === '') {
    return 'color'
  }

  return undefined
})

function onClick() {
  if (disabled.value || props.selected) {
    return
  }
  // We're a bit defensive on those trackingId / trackingValue, as the API specs says that
  // both fields are optional. Let's have default values to make sure we're able to detect this
  // in amplitude no matter what
  trackClick({
    zone: props.trackingId ?? 'unknown_picker',
    name: props.trackingValue ?? props.label,
  })

  emit('click')
}
</script>
