<template>
  <div>
    <div class="body-2">
      <span v-if="isStandardDeliveryBeforeChristmas">
        {{
          i18n(translations.freeShippingBeforeChristmas, {
            earliest: freeEarliestArrivalDate,
            latest: freeLatestArrivalDate,
          })
        }}
      </span>

      <FormattedMessage
        v-else-if="shouldDisplayFreeShippingWithDates"
        :definition="translations.freeShipping"
      >
        <template #dates>
          {{
            i18n(translations.freeShippingDates, {
              earliest: freeEarliestArrivalDate,
              latest: freeLatestArrivalDate,
            })
          }}
        </template>
      </FormattedMessage>

      <span v-else-if="shouldDisplayFreeShippingWithoutDates">
        {{ i18n(translations.freeShippingWithoutDates) }}
      </span>
    </div>

    <div
      v-if="shouldDisplayExpressShipping"
      class="caption"
      :class="{
        'text-static-success-hi':
          isExpressDeliveryBeforeChristmas &&
          !isStandardDeliveryBeforeChristmas,
      }"
    >
      <span v-if="isExpressDeliveryBeforeChristmas">
        {{
          i18n(translations.expressShippingBeforeChristmas, {
            amount: expressShippingPriceLabel,
            earliest: expressEarliestArrivalDate,
            latest: expressLatestArrivalDate,
          })
        }}
      </span>
      <span v-else>
        {{
          i18n(translations.expressShipping, {
            amount: expressShippingPriceLabel,
            earliest: expressEarliestArrivalDate,
            latest: expressLatestArrivalDate,
          })
        }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { useDeliveryBeforeChristmas } from '~/scopes/product/composables/useDeliveryBeforeChristmas'
import { useDeliveryDateAdjustment } from '~/scopes/product/composables/useDeliveryDateAdjustement'

import translations from '../../ItemShipping.translations'

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  timeZone: 'UTC',
}

const props = defineProps<{
  shipping: NonNullable<GetPickersResponse['selectedOffer']>['shipping']
}>()

const i18n = useI18n()

const deliveryDateAdjustment = useDeliveryDateAdjustment()
const { isStandardDeliveryBeforeChristmas, isExpressDeliveryBeforeChristmas } =
  useDeliveryBeforeChristmas(props.shipping)

const shouldDisplayFreeShippingWithoutDates = computed(() => {
  return isEmpty(props.shipping?.free)
})

const shouldDisplayFreeShippingWithDates = computed(() => {
  return !isEmpty(props.shipping?.free)
})

const shouldDisplayExpressShipping = computed(() => {
  if (props.shipping?.express && props.shipping?.express.price) {
    return Number(props.shipping.express.price.amount) > 0
  }

  return false
})

const freeEarliestArrivalDate = computed(() => {
  return props.shipping?.free
    ? i18n.date(
        deliveryDateAdjustment(props.shipping.free.earliestArrivalDate),
        DATE_FORMAT,
      )
    : null
})

const freeLatestArrivalDate = computed(() => {
  return props.shipping?.free
    ? i18n.date(new Date(props.shipping.free.latestArrivalDate), DATE_FORMAT)
    : null
})

const expressEarliestArrivalDate = computed(() => {
  return props.shipping?.express
    ? i18n.date(
        deliveryDateAdjustment(props.shipping.express.earliestArrivalDate),
        DATE_FORMAT,
      )
    : null
})

const expressLatestArrivalDate = computed(() => {
  return props.shipping?.express
    ? i18n.date(new Date(props.shipping.express.latestArrivalDate), DATE_FORMAT)
    : null
})

const expressShippingPriceLabel = computed(() => {
  return props.shipping?.express && props.shipping?.express.price
    ? i18n.price(props.shipping.express.price)
    : null
})
</script>
