<template>
  <div class="aspect-square w-full">
    <ImageComparison
      v-if="activeStep === StepIndexes.SCREEN && isStepScreenAvailable"
      class="bg-surface-default-hi rounded-[32px]"
      :image1="{
        src: getGradeImage(pickers, activeGrade, 'screen') || '',
        label: i18n(translationsScreen.decline),
      }"
      :image2="{
        src: getGradeImage(pickers, '11', 'screen') || '',
        label: i18n(translationsScreen.accept),
      }"
      :tracking="{ zone: 'no_grade_screen' }"
    />

    <RevIllustration
      v-if="
        activeStep === StepIndexes.SCREEN &&
        !isStepScreenAvailable &&
        unavailableScreenImg
      "
      alt=""
      class="bg-surface-default-hi rounded-[32px]"
      :height="500"
      sizes="500px"
      :src="unavailableScreenImg"
      :width="500"
    />

    <ImageComparison
      v-if="activeStep === StepIndexes.SHELL && isStepBodyAvailable"
      class="bg-surface-default-hi rounded-[32px]"
      :image1="{
        src: getGradeImage(pickers, activeGrade, 'body') || '',
        label: i18n(translationsBody.decline),
      }"
      :image2="{
        src: getGradeImage(pickers, '10', 'body') || '',
        label: i18n(translationsBody.accept),
      }"
      :tracking="{ zone: 'no_grade_body' }"
    />

    <RevIllustration
      v-if="
        activeStep === StepIndexes.SHELL &&
        !isStepBodyAvailable &&
        unavailableBodyImg
      "
      alt=""
      class="bg-surface-default-hi rounded-[32px]"
      :height="500"
      sizes="500px"
      :src="unavailableBodyImg"
      :width="500"
    />

    <RevIllustration
      v-if="activeStep === StepIndexes.BATTERY"
      alt=""
      class="bg-surface-default-hi rounded-[32px]"
      :height="500"
      sizes="500px"
      :src="
        isStepBatteryAvailable
          ? '/img/product/no-grade/battery-desktop.png'
          : '/img/product/no-grade/battery-disabled-desktop.png'
      "
      :width="500"
    />

    <!-- On premium step, we don't want to display the example image -->
    <!-- However when there is no premium step and we want to display your selection (aka condition guidance), -->
    <!-- the active step is still actually the premium step -->
    <ExampleImage
      v-if="activeStep !== StepIndexes.PREMIUM || !isStepPremiumAvailable"
      class="text-static-light-hi absolute bottom-24 right-24 z-[1] mt-8 flex items-center justify-end gap-4"
      :class="{
        'bottom-56 -translate-y-24':
          activeStep > StepIndexes.PREMIUM ||
          (activeStep === StepIndexes.PREMIUM && !isStepPremiumAvailable) ||
          isPremiumStepAlone,
      }"
    />

    <div
      v-if="
        activeStep === StepIndexes.PREMIUM &&
        isStepPremiumAvailable &&
        !isPremiumStepAlone
      "
      class="border-static-brand-mid relative rounded-[32px] border p-48 pb-[128px]"
      :class="$style.premiumStep"
    >
      <ComparisonArea
        :brand
        class="relative z-[1]"
        :offer-grade-without-premium
        :offer-type-without-premium
      />
      <RevIllustration
        alt=""
        class="absolute bottom-0 left-0"
        :height="500"
        sizes="500px"
        src="/img/product/no-grade/premium-desktop.png"
        :width="500"
      />
    </div>

    <div
      v-if="
        (isPremiumStepAlone ||
          (!isStepPremiumAvailable && activeStep > StepIndexes.BATTERY) ||
          activeStep > StepIndexes.PREMIUM) &&
        pickers.pickerGroups[0].sideContent?.content
      "
    >
      <ConditionGuidance
        :content="pickers.pickerGroups[0].sideContent.content"
        :show-tags="false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'

import ConditionGuidance from '../../../CustomizationFunnel/components/ConditionGuidance/ConditionGuidance.vue'
import ExampleImage from '../../../ExampleImage/ExampleImage.vue'
import { StepIndexes } from '../../NoGrade.constants'
import { getGradeImage } from '../../NoGrade.utils'
import ImageComparison from '../ImageComparison/ImageComparison.vue'
import ComparisonArea from '../StepPremium/components/ComparisonArea/ComparisonArea.vue'
import translationsBody from '../StepScreenBody/StepBody.translations'
import translationsScreen from '../StepScreenBody/StepScreen.translations'

defineProps<{
  pickers: GetPickersResponse
  activeGrade: string
  activeStep: number
  isStepScreenAvailable: boolean
  isStepBodyAvailable: boolean
  isStepBatteryAvailable: boolean
  isStepPremiumAvailable: boolean
  isPremiumStepAlone: boolean
  unavailableScreenImg?: string
  unavailableBodyImg?: string
  brand: string
  offerTypeWithoutPremium: 'default' | 'new_battery'
  offerGradeWithoutPremium: string
}>()

const i18n = useI18n()
</script>

<style module>
.premiumStep {
  background:
    radial-gradient(80.35% 80.41% at 89.14% 19.59%, #f6f1fd 0%, #fff 100%), #fff;
}
</style>
