<template>
  <div aria-hidden class="flex size-[24px] items-center justify-center">
    <div class="rounded-full border" :class="classes" :style="styles" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const MIN_SIZE = 8
const MAX_SIZE = 20
const SIZE_MULTIPLICATOR = 4

const props = withDefaults(
  defineProps<{
    index: number
    selected: boolean
    disabled: boolean
  }>(),
  {
    index: 0,
    selected: false,
    disabled: false,
  },
)

const styles = computed(() => {
  const size = Math.min(MIN_SIZE + SIZE_MULTIPLICATOR * props.index, MAX_SIZE)

  return {
    width: `${size}px`,
    height: `${size}px`,
  }
})

const classes = computed(() => {
  return [
    // eslint-disable-next-line no-nested-ternary
    props.disabled
      ? 'border-action-default-hi-disabled'
      : props.selected
        ? 'bg-action-default-hi border-action-default-hi-pressed'
        : 'bg-transparent border-action-default-hi',
  ]
})
</script>
