export default {
  chipLabel: {
    id: 'rakuten_chip_label',
    defaultMessage: 'Rakuten Mobile points with your phone',
  },
  chipLink: {
    id: 'rakuten_chip_link',
    defaultMessage: 'https://www.backmarket.co.jp/ja-jp/service/rakuten-mobile',
  },
}
