export default {
  cardHeader_unknown: {
    id: 'no_grade_step_screen_card_header',
    defaultMessage: 'Screen',
  },
  cardHeader_accepted: {
    id: 'product_page_no_grade_labels_screen_flawless',
    defaultMessage: 'Flawless screen',
  },
  cardHeader_declined: {
    id: 'product_page_no_grade_labels_screen_gently_used',
    defaultMessage: 'Gently used screen',
  },
  title: {
    id: 'no_grade_step_screen_title',
    defaultMessage: 'Want a flawless screen?',
  },
  accept: {
    id: 'no_grade_step_screen_accept',
    defaultMessage: 'Flawless',
  },
  decline: {
    id: 'no_grade_step_screen_decline',
    defaultMessage: 'Gently used',
  },
}
