export default {
  metaTitle: {
    id: 'product_meta_title',
    defaultMessage: '{title}',
  },
  metaDescription: {
    id: 'product_meta_description',
    defaultMessage:
      '{category} {brand} {title} ✌ Up to 70% off compared to new ✅ Free delivery ✅ Cheap {model} ✅ {warrantyDuration} year warranty ✅ {coolingOffDays}-day money-back guarantee',
  },
}
