<template>
  <section class="text-center">
    <RevIllustration
      alt=""
      class="mx-auto pt-32"
      :height="150"
      src="/img/product/new-battery/IllustrationPhoneBattery.svg"
      :width="150"
    />
    <h2 class="heading-1 pt-24">
      {{ i18n(translations.section1Title) }}
    </h2>
    <p class="text-static-default-low body-1 pt-8">
      {{ i18n(translations.section1Description) }}
    </p>

    <ComparisonArea class="pt-32" variant="standard_vs_new" />

    <p class="body-2 my-32 text-center">
      {{ i18n(translations.section1Note) }}
    </p>
    <p v-if="link" class="body-2-bold my-32 text-center">
      <RevLink :to="link">
        {{ i18n(translations.section1Link) }}
      </RevLink>
    </p>
  </section>
  <RevDivider />
  <section class="mt-20">
    <RevIllustration
      alt=""
      class="mx-auto"
      :height="100"
      src="/img/product/new-battery/IllustrationQuestionMark.svg"
      :width="100"
    />
    <h3 class="heading-3 py-24">
      {{ i18n(translations.section2Title) }}
    </h3>
    <RevAccordionList class="-mx-24">
      <FaqItem :answers="faqItem1" :question="translations.section2Question1" />
      <FaqItem :answers="faqItem2" :question="translations.section2Question2" />
      <FaqItem :answers="faqItem3" :question="translations.section2Question3" />
    </RevAccordionList>
  </section>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale } from '#imports'
import { computed } from 'vue'

import type { LinkInternal } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevAccordionList } from '@ds/components/AccordionList'
import { RevDivider } from '@ds/components/Divider'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import { LINKS } from '../../NewBatteryModal.constants'
import translations from '../../NewBatteryModal.translations'
import ComparisonArea from '../ComparisonArea/ComparisonArea.vue'
import FaqItem from '../FaqItem/FaqItem.vue'

const i18n = useI18n()

const {
  market: { countryCode },
} = useMarketplace()

const faqItem1 = computed(() => {
  return [
    { paragraph: translations.section2Answer1Paragraph1 },
    { paragraph: translations.section2Answer1Paragraph2 },
    {
      paragraph: translations.section2Answer1Paragraph3,
      link: translations.section2Answer1Paragraph3Link,
      linkText: translations.section2Answer1Paragraph3LinkText,
    },
  ]
})

const faqItem2 = computed(() => {
  return [
    { paragraph: translations.section2Answer2Paragraph1 },
    { paragraph: translations.section2Answer2Paragraph2 },
    { paragraph: translations.section2Answer2Paragraph3 },
  ]
})

const faqItem3 = computed(() => {
  return [
    { paragraph: translations.section2Answer3Paragraph1 },
    {
      paragraph: translations.section2Answer3Paragraph2,
      link: translations.section2Answer3Paragraph2Link,
      linkText: translations.section2Answer3Paragraph2LinkText,
    },
    {
      paragraph: translations.section2Answer3Paragraph3,
    },
  ]
})

const resolveLocalizedRoute = useRouteLocationWithLocale()

const link = computed(() => {
  return LINKS[countryCode]
    ? resolveLocalizedRoute(LINKS[countryCode] as LinkInternal)
    : null
})
</script>
