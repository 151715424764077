<template>
  <RevDrawer
    :close-button-label="i18n(translations.close)"
    :name="MODAL_NAMES.VERIFIED_REFURBISHED"
    :title="i18n(translations.modalTitle)"
  >
    <template #body>
      <RevIllustration
        alt=""
        class="mb-24 w-full"
        :height="180"
        src="/img/product/verified-refurbished/illustration.png"
        :width="327"
      />

      <h1 class="heading-1 text-center">
        {{ i18n(translations.title) }}
      </h1>

      <RevDivider class="my-24" />

      <ContentRow
        :labels="[i18n(translations.item1text)]"
        :title="i18n(translations.item1title)"
      >
        <IconCheckInCircle aria-hidden />
      </ContentRow>
      <ContentRow
        :labels="[i18n(translations.item2text)]"
        :title="i18n(translations.item2title)"
      >
        <IconSparkles aria-hidden />
      </ContentRow>
      <ContentRow
        :labels="[i18n(translations.item3text)]"
        :title="i18n(translations.item3title)"
      >
        <IconFlag aria-hidden />
      </ContentRow>

      <RevButton
        v-if="shouldDisplayButton"
        full-width="always"
        target="_blank"
        :to="link"
        variant="primary"
        @click="
          tracking.trackClick({
            zone: 'verified_refurbished',
          })
        "
      >
        {{ i18n(translations.readMore) }}
      </RevButton>

      <WarrantyExclusion />
    </template>
  </RevDrawer>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale, useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevDivider } from '@ds/components/Divider'
import { RevDrawer } from '@ds/components/Drawer'
import { RevIllustration } from '@ds/components/Illustration'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'
import { IconFlag } from '@ds/icons/IconFlag'
import { IconSparkles } from '@ds/icons/IconSparkles'

import { MODAL_NAMES } from '~/scopes/product/constants'
import { PRODUCT } from '~/scopes/product/route-names'

import ContentRow from '../../../ContentRow/ContentRow.vue'
import WarrantyExclusion from '../../../WarrantyExclusion/WarrantyExclusion.vue'

import translations from './VerifiedRefurbishedModal.translations'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()
const { countryCode } = useMarketplace().market
const config = useRuntimeConfig()
const tracking = useTracking()

const shouldDisplayButton = computed(() => {
  return config.public.FF_ENABLE_VERIFIED_REFURBISHED_LINK_PP?.includes(
    countryCode,
  )
})

const link = resolveLocalizedRoute({
  name: PRODUCT.EXTERNAL_PAGE_VERIFIED_REFURBISHED,
})
</script>
