import { MarketCountryCode } from '@backmarket/http-api'

const imgPath = '/img/product/social-proof'

export const SOCIAL_PROOF_IMAGES: Partial<
  Record<MarketCountryCode, Array<string>>
> = {
  [MarketCountryCode.FR]: [
    `${imgPath}/fr/trustpilot-fr.svg`,
    `${imgPath}/fr/adyen-fr.svg`,
    `${imgPath}/fr/next40-fr.svg`,
    `${imgPath}/fr/fevad-fr.svg`,
  ],
  [MarketCountryCode.US]: [
    `${imgPath}/us/google-us.svg`,
    `${imgPath}/us/trustpilot-us.svg`,
    `${imgPath}/us/bbb-us.svg`,
  ],
  [MarketCountryCode.ES]: [
    `${imgPath}/es/trustedshop-es.svg`,
    `${imgPath}/es/confianza-online-es.svg`,
    `${imgPath}/es/trust-ecommerce-europe-es.svg`,
    `${imgPath}/es/adyen-es.svg`,
  ],
  [MarketCountryCode.DE]: [
    `${imgPath}/de/trustedshop-de.svg`,
    `${imgPath}/de/trustpilot-de.svg`,
    `${imgPath}/de/rtl-de.svg`,
    `${imgPath}/de/computer-bild-de.svg`,
  ],
}
