<template>
  <div
    ref="root"
    class="bg-static-default-low relative aspect-[2/3] w-full overflow-hidden md:w-auto lg:aspect-[5/6]"
    :class="$style.storiesContainer"
  >
    <div class="absolute left-0 right-0 top-0 z-[1]">
      <slot name="breadcrumb" />
    </div>

    <div class="relative flex h-full select-none items-center justify-center">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="flex h-full justify-center"
        :class="{
          'absolute opacity-0': !isCurrentIndex(index),
          'items-center': item.type !== 'image',
          'md:items-center': item.type === 'image',
        }"
      >
        <Media
          ref="media"
          :alt="item.alt"
          class="object-contain"
          :class="
            item.type === 'image' && item.source !== 'PARTNER'
              ? [
                  imagesSizes[index],
                  imagesPositions[index],
                  isIndexActive(index) && [
                    $style[`animation${index}`],
                    { [$style[`animation-paused`]]: paused },
                  ],
                ]
              : ''
          "
          :loading="index === 0 ? 'eager' : 'lazy'"
          :type="item.type"
          :url="item.url"
          @contextmenu.prevent
          @error="onMediaError(index)"
          @loaded="onMediaLoaded(index)"
        />

        <div
          v-if="isError[index]"
          class="text-static-default-low absolute bottom-0 left-0 right-0 top-[30%] flex flex-col gap-8 px-44 text-center md:top-0 md:justify-center"
        >
          <IconImage class="relative left-1/2 -translate-x-1/2" size="48" />
          <span class="body-1-bold">
            {{ i18n(translations.loadingErrorTitle) }}
          </span>
          <p class="body-2 mt-8">
            {{ i18n(translations.loadingErrorDescription) }}
          </p>
        </div>

        <div
          v-if="!isMediaLoaded[index]"
          class="absolute inset-0 flex items-center justify-center"
        >
          <RevSpinner size="large" />
        </div>
      </div>

      <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions vuejs-accessibility/click-events-have-key-events -->
      <div
        ref="overlay"
        class="absolute inset-0"
        role="presentation"
        @click="onClick"
        @touchcancel="touchEnd"
        @touchend="touchEnd"
        @touchmove.passive="touchMove"
        @touchstart="touchStart"
      ></div>
    </div>

    <div class="absolute bottom-[-4px] left-0 right-0 z-[1]">
      <div class="mx-24 mb-12 flex items-end justify-between">
        <div class="flex w-full items-end justify-between">
          <div
            class="bg-static-default-low border-action-default-low rounded-full hidden self-start border-1 md:block"
          >
            <RevButtonIcon
              :icon="paused ? IconMediaPlay : IconMediaPause"
              variant="secondary"
              @click.stop="pause"
            />
          </div>

          <p
            v-if="currentItem.type === 'video'"
            class="text-static-default-hi mood-inverse caption-bold max-w-[55%] md:max-w-none"
            :class="$style.disclaimerShadow"
          >
            {{ i18n(translations.videoDisclaimer) }}
          </p>
        </div>

        <slot name="above-progress" />
      </div>

      <div class="mx-24 md:mb-12">
        <ProgressBar v-model="currentIndex" :items :paused="!timeout" />
      </div>

      <div :class="$style.gradientTitle">
        <slot name="title" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue'

import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonIcon } from '@ds/components/ButtonIcon'
import { RevSpinner } from '@ds/components/Spinner'
import { IconImage } from '@ds/icons/IconImage'
import { IconMediaPause } from '@ds/icons/IconMediaPause'
import { IconMediaPlay } from '@ds/icons/IconMediaPlay'
import { useIntersectionObserver } from '@vueuse/core'

import {
  TOUCH_MOVE_THRESHOLD,
  TOUCH_PRESS_DELAY,
} from '~/scopes/product/components/ImmersiveStories/ImmersiveStories.constants'

import translations from './ImmersiveStories.translations'
import { getStoriesItems } from './ImmersiveStories.utils'
import Media from './components/Media/Media.vue'
import ProgressBar from './components/ProgressBar/ProgressBar.vue'

type TimeoutValue = ReturnType<typeof setTimeout>

const props = defineProps<{
  images: GetProductResponse['images']
  trackingCategory: string
  trackingModel?: string
}>()

const root = ref<HTMLElement | null>()
const { trackProductBlockImpression } = useTracking()
const i18n = useI18n()
const currentIndex = ref(0)
const isMediaLoaded = ref<Record<number, boolean>>({})
const isError = ref<Record<number, boolean>>({})
const timeout = ref<TimeoutValue | null>(null)
const timeoutStart = ref(0)
const mediaProgress = ref(0)
const overlay = ref<HTMLElement | null>(null)
const media = ref<(typeof Media)[]>([])
const isVisible = ref(false)
const paused = ref(false)
const touchTick = ref<TimeoutValue | null>(null)
const touchPressed = ref(false)
const touchStartY = ref(0)
const touchMoved = ref(false)
const isMounted = ref(false)

const items = computed(() => getStoriesItems(props.images))

const imagesSizes = [
  '!h-[56%] w-fit max-w-none',
  '!h-[91%] w-fit max-w-none',
  '!h-[84%] w-fit max-w-none',
]
const imagesPositions = [
  'relative top-[13%] md:top-0 md:items-center',
  'relative top-[1.5%] md:top-0 items-end md:items-center',
  'items-end md:items-center',
]

const currentItem = computed(() => {
  return items.value[currentIndex.value]
})

function trackImpression(autoImpression: boolean) {
  // Don't track automatic slide when the user isn't focused on the stories. It'll mess with the
  // data analysis and cost a log of money $$$
  if (autoImpression && !isVisible.value) {
    return
  }

  trackProductBlockImpression({
    block_name: 'content_carousel',
    position: currentIndex.value,
    product_model: props.trackingModel ?? '',
    product_category: props.trackingCategory,
    content_type: currentItem.value.type,
    auto_impression: autoImpression,
  })
}

function startTimer() {
  timeout.value = setTimeout(
    () => {
      timeout.value = null
      mediaProgress.value = 0
      timeoutStart.value = 0
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      next()
      trackImpression(true)
    },
    currentItem.value.duration * 1000 - mediaProgress.value,
  )
  timeoutStart.value = performance.now()
  media.value[currentIndex.value]?.rewind()
  media.value[currentIndex.value]?.play()
}

function resetTimer() {
  if (timeout.value) {
    clearTimeout(timeout.value)
  }

  timeoutStart.value = 0
  timeout.value = null
  mediaProgress.value = 0
}

function pauseTimer() {
  if (timeout.value) {
    mediaProgress.value += performance.now() - timeoutStart.value
    clearTimeout(timeout.value)
    timeoutStart.value = 0
    timeout.value = null
  }
}

function next() {
  if (currentIndex.value === items.value.length - 1) {
    currentIndex.value = 0
  } else {
    currentIndex.value += 1
  }
}

function previous() {
  if (currentIndex.value === 0) {
    currentIndex.value = items.value.length - 1
  } else {
    currentIndex.value -= 1
  }
}

function pause() {
  if (!paused.value) {
    paused.value = true
    media.value[currentIndex.value]?.pause()
    pauseTimer()
  } else {
    paused.value = false
    media.value[currentIndex.value]?.play()
    startTimer()
  }
}

function isClickedOnSection(
  coords: { clientX: number },
  ratioLeft = 0,
  ratioRight = 1,
) {
  if (!overlay.value) {
    return false
  }
  const box = overlay.value.getBoundingClientRect()
  const clientX = coords.clientX - box.left

  const left = box.width * ratioLeft
  const right = box.width * ratioRight

  return clientX >= left && clientX < right
}

function onClick(event: MouseEvent | { clientX: number }) {
  if (overlay.value) {
    if (isClickedOnSection(event, 0, 0.4)) {
      previous()
    } else if (isClickedOnSection(event, 0.4, 1)) {
      next()
    }
  }
  trackImpression(false)
}

function touchStart(event: TouchEvent) {
  touchStartY.value = event.touches[0].clientY

  touchTick.value = setTimeout(() => {
    touchTick.value = null
    touchPressed.value = true
    pause()
  }, TOUCH_PRESS_DELAY)
}

function touchEnd(event: TouchEvent) {
  if (touchTick.value) {
    clearTimeout(touchTick.value)
    touchTick.value = null
  }
  if (touchPressed.value) {
    event.preventDefault()
    pause()
    touchPressed.value = false
  } else if (!touchMoved.value) {
    event.preventDefault()
    onClick(event.changedTouches[0])
  }

  touchMoved.value = false
}

function touchMove(event: TouchEvent) {
  if (
    !touchMoved.value &&
    Math.abs(event.touches[0].clientY - touchStartY.value) >
      TOUCH_MOVE_THRESHOLD
  ) {
    touchMoved.value = true
    if (touchTick.value) {
      clearTimeout(touchTick.value)
      touchTick.value = null
    }
  }
}

function onMediaError(index: number) {
  isError.value[index] = true
}

function onMediaLoaded(index: number) {
  isMediaLoaded.value[index] = true
}

function isCurrentIndex(index: number) {
  return currentIndex.value === index
}

function isIndexActive(index: number) {
  return isMounted.value && isCurrentIndex(index)
}

watch(currentIndex, () => {
  resetTimer()
  paused.value = false
})

watch(
  [isMounted, currentIndex, () => isMediaLoaded.value[currentIndex.value]],
  () => {
    if (
      isMounted.value &&
      isMediaLoaded.value[currentIndex.value] &&
      !paused.value
    ) {
      startTimer()
    }
  },
)

const { stop } = useIntersectionObserver(root, ([{ isIntersecting }]) => {
  isVisible.value = isIntersecting
})

onMounted(() => {
  isMounted.value = true
})

onUnmounted(() => {
  stop()
})

onBeforeUnmount(() => {
  resetTimer()
})
</script>

<style module>
/* Using custom styles here to be more precise about breakpoints */
.storiesContainer {
  width: 100%;
  height: 100%;

  /* 114px is the height of the app bar */
  /* 100px is the height of the add to cart */
  max-height: calc(100svh - 114px - 100px);

  @media (min-width: 768px) {
    max-height: fit-content;
    height: calc(100svh - 114px - 100px);
  }
}

.animation0 {
  animation: animation0 4.5s ease-out forwards;
}
@keyframes animation0 {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1.04);
  }
}

.animation1 {
  animation: animation1 4.5s ease-out forwards;
}
@keyframes animation1 {
  0% {
    transform: rotate(0) scale(1);
  }
  100% {
    transform: rotate(5deg) scale(1.05);
  }
}

.animation2 {
  animation: animation2 4.5s ease-out forwards;
}
@keyframes animation2 {
  0% {
    transform: rotate(0) scale(1);
  }
  100% {
    transform: rotate(-4deg) scale(1.06);
  }
}

.gradientTitle {
  @media (max-width: 767px) {
    background: linear-gradient(to top, white 30%, rgba(255, 255, 255, 0) 100%);
  }
}

.disclaimerShadow {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.animation-paused {
  animation-play-state: paused;
}
</style>
