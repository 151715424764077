<template>
  <CustomizationFunnel
    :breadcrumb
    :pickers="pickersResponse"
    :pickers-services="pickersServices?.pickerGroups ?? []"
    :price
    :price-without-subsidies
    :price-without-trade-in
    :product
    :product-tracking
    :selected-mobile-plan
    :selected-offer
    :technical-specifications
  >
    <template #sticky-bar="{ title, currentStep, totalSteps }">
      <ClientOnly>
        <StickyBarTop
          :is-customization-funnel-enabled="true"
          :price
          :product
          :selected-mobile-plan
          :selected-offer
          :title
          :tracking="productTracking"
          @hide="handleHideTopBar"
          @show="handleShowTopBar"
        >
          <template #progress-bar>
            <ProgressBar :current-step :total-steps />
          </template>
        </StickyBarTop>

        <StickyBarTopSmall
          :is-customization-funnel-enabled="true"
          :select="selectedOffer.shipping"
          :shipping="selectedOffer.shipping"
          :title
          @hide="handleHideTopBar"
          @show="handleShowTopBar"
        >
          <template #progress-bar>
            <ProgressBar :current-step :total-steps />
          </template>
        </StickyBarTopSmall>
      </ClientOnly>
    </template>
    <template #details>
      <Details
        :has-swap="false"
        :product
        :product-category
        :selected-offer
        :should-display-vat="false"
        :show-seller-block="false"
        :technical-specifications
      />
    </template>
  </CustomizationFunnel>
</template>

<script lang="ts" setup>
import { type Price } from '@backmarket/http-api'
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { type GetBreadcrumbResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/breadcrumb'
import {
  type GetPickersResponse,
  type GetServicesPickersResponse,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { type GetTechnicalSpecificationsResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/technical-specifications'

import { useProductTracking } from '../../composables/useProductTracking'
import CustomizationFunnel from '../CustomizationFunnel/CustomizationFunnel.vue'
import ProgressBar from '../CustomizationFunnel/components/ProgressBar/ProgressBar.vue'
import StickyBarTopSmall from '../CustomizationFunnel/components/StickyBarTopSmall/StickyBarTopSmall.vue'
import Details from '../Details/Details.vue'
import StickyBarTop from '../StickyBarTop/StickyBarTop.vue'

defineProps<{
  breadcrumb?: GetBreadcrumbResponse | null | undefined
  selectedOffer: NonNullable<GetPickersResponse['selectedOffer']>
  pickersResponse: GetPickersResponse
  pickersServices: GetServicesPickersResponse | null
  price: Price
  priceWithoutSubsidies?: Price | null
  priceWithoutTradeIn?: Price | null
  product: GetProductResponse
  productCategory: string
  selectedMobilePlan?: MobilePlanOffer
  productTracking: ReturnType<typeof useProductTracking>
  technicalSpecifications: GetTechnicalSpecificationsResponse | null
}>()

const emit = defineEmits(['hide', 'show'])

function handleShowTopBar() {
  emit('show')
}
function handleHideTopBar() {
  emit('hide')
}
</script>
