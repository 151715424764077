import { defineAsyncComponent } from 'vue'

export const ICONS_COMPONENTS = {
  IconBattery: defineAsyncComponent(() =>
    import('@ds/icons/IconBattery').then((m) => m.IconBattery),
  ),
  IconCamera: defineAsyncComponent(() =>
    import('@ds/icons/IconCamera').then((m) => m.IconCamera),
  ),
  IconCpu: defineAsyncComponent(() =>
    import('@ds/icons/IconCpu').then((m) => m.IconCpu),
  ),
  IconDiamond: defineAsyncComponent(() =>
    import('@ds/icons/IconDiamond').then((m) => m.IconDiamond),
  ),
  IconOfficialFilled: defineAsyncComponent(() =>
    import('@ds/icons/IconOfficialFilled').then((m) => m.IconOfficialFilled),
  ),
  IconScratches: defineAsyncComponent(() =>
    import('@ds/icons/IconScratches').then((m) => m.IconScratches),
  ),
  IconSim: defineAsyncComponent(() =>
    import('@ds/icons/IconSim').then((m) => m.IconSim),
  ),
  IconSparkles: defineAsyncComponent(() =>
    import('@ds/icons/IconSparkles').then((m) => m.IconSparkles),
  ),
  IconSwap: defineAsyncComponent(() =>
    import('@ds/icons/IconSwap').then((m) => m.IconSwap),
  ),
  IconCheckInCircle: defineAsyncComponent(() =>
    import('@ds/icons/IconCheckInCircle').then((m) => m.IconCheckInCircle),
  ),
  IconBackMarket: defineAsyncComponent(() =>
    import('@ds/icons/IconBackMarket').then((m) => m.IconBackMarket),
  ),
}
