<template>
  <div
    v-if="
      withTradeInChip ||
      withBouyguesChip ||
      withVisibleByVerizonChip ||
      withRakutenChip
    "
    class="mt-12 md:relative"
  >
    <div
      ref="wrapper"
      class="flex flex-col gap-12 md:flex-row md:flex-nowrap md:items-center md:overflow-hidden"
    >
      <TradeInChip v-if="withTradeInChip" />

      <PromoCodeChips
        :partner-promo-codes="product.includedServiceOffers.partnerPromoCodes"
        tracking-zone="info_title_section"
      />

      <BouyguesChip v-if="withBouyguesChip" :offers="bouyguesOffers" />
    </div>

    <div
      v-if="shouldShowPrevButton"
      class="absolute left-0 top-[-2px] z-[1] hidden w-[104px] items-center justify-start md:flex"
      :class="$style.gradientPrevButton"
    >
      <RevButtonIcon
        :alternative-text="i18n(translations.less)"
        :icon="IconChevronLeft"
        variant="secondary"
        @click="onPrevClick"
      />
    </div>

    <div
      v-if="shouldShowNextButton"
      class="absolute right-0 top-[-2px] z-[1] hidden w-[104px] items-center justify-end md:flex"
      :class="$style.gradientNextButton"
    >
      <RevButtonIcon
        :alternative-text="i18n(translations.more)"
        :icon="IconChevronRight"
        variant="secondary"
        @click="onNextClick"
      />
    </div>

    <RevDivider class="mb-12 mt-14 md:mb-16" />
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevButtonIcon } from '@ds/components/ButtonIcon'
import { RevDivider } from '@ds/components/Divider'
import { IconChevronLeft } from '@ds/icons/IconChevronLeft'
import { IconChevronRight } from '@ds/icons/IconChevronRight'

import { hasPartnerPromoCode } from '../../utils/hasPartnerPromoCode'
import PromoCodeChips from '../PromoCodeChips/PromoCodeChips.vue'

import translations from './AdvertisementChips.translations'
import BouyguesChip from './components/BouyguesChip/BouyguesChip.vue'
import TradeInChip from './components/TradeInChip/TradeInChip.vue'

const props = defineProps<{
  product: GetProductResponse
}>()

const i18n = useI18n()
const wrapper = ref<HTMLElement | null>()
const displayedButton = ref(0)
const shouldShowPrevButton = ref(false)
const shouldShowNextButton = ref(false)
const { FF_PRODUCT_PAGE_TRADE_IN_CHIP_JP = '' } = useRuntimeConfig().public
const { countryCode } = useMarketplace().market

const withTradeInChip = computed(() => {
  return (
    props.product.isTradeInEligible ||
    FF_PRODUCT_PAGE_TRADE_IN_CHIP_JP.includes(countryCode)
  )
})

const withVisibleByVerizonChip = computed(() =>
  hasPartnerPromoCode(
    props.product.includedServiceOffers.partnerPromoCodes,
    'VISIBLE',
  ),
)

const withRakutenChip = computed(() =>
  hasPartnerPromoCode(
    props.product.includedServiceOffers.partnerPromoCodes,
    'RAKUTEN',
  ),
)

const bouyguesOffers = computed(() => {
  return props.product.includedServiceOffers.mobilePlans.filter(
    ({ partner }) => partner.name === 'BOUYGUES_TELECOM',
  )
})

const withBouyguesChip = computed(() => bouyguesOffers.value.length > 0)

function updateShouldDisplayPrevButton() {
  if (wrapper.value) {
    shouldShowPrevButton.value =
      wrapper.value.scrollWidth > wrapper.value.offsetWidth &&
      displayedButton.value > 0
  }
}

function updateShouldDisplayNextButton() {
  const buttonsLength = wrapper.value?.querySelectorAll('button').length || 0
  const linkLength = wrapper.value?.querySelectorAll('a').length || 0

  const interactiveElementLength = buttonsLength + linkLength

  if (wrapper.value && interactiveElementLength) {
    shouldShowNextButton.value =
      wrapper.value.scrollWidth > wrapper.value.offsetWidth &&
      displayedButton.value < interactiveElementLength - 1
  }
}

function scrollTo(index: number) {
  if (wrapper.value) {
    const arrayOfButtons = Array.from(wrapper.value.querySelectorAll('button'))
    const arrayOfLinks = Array.from(wrapper.value.querySelectorAll('a'))

    const items = [...arrayOfButtons, ...arrayOfLinks]
    items[index].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    })
    displayedButton.value = index
  }

  updateShouldDisplayPrevButton()
  updateShouldDisplayNextButton()
}

function onPrevClick() {
  scrollTo(displayedButton.value - 1)
}

function onNextClick() {
  scrollTo(displayedButton.value + 1)
}

onMounted(() => {
  updateShouldDisplayNextButton()
  window.addEventListener('resize', updateShouldDisplayPrevButton)
  window.addEventListener('resize', updateShouldDisplayNextButton)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateShouldDisplayPrevButton)
  window.removeEventListener('resize', updateShouldDisplayNextButton)
})
</script>

<style module>
.gradientPrevButton {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 9.09%,
    rgba(255, 255, 255, 0.29) 27.86%,
    #fff 58.7%
  );
}

.gradientNextButton {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 9.09%,
    rgba(255, 255, 255, 0.29) 27.86%,
    #fff 58.7%
  );
}
</style>
