<template>
  <component
    :is="props.clickable ? RevButtonCard : RevCard"
    class="!bg-static-info-mid mb-24 flex min-h-[72px] w-full flex-row items-center gap-12 px-16 py-12"
    @click="clickable ? openDaModal() : undefined"
  >
    <DynamicIcon
      v-if="typeof icon === 'string'"
      aria-hidden="true"
      class="m-4 ml-0"
      :icon
      :size="32"
    />
    <component
      :is="icon"
      v-else-if="icon"
      alt=""
      aria-hidden="true"
      class="m-4"
      :size="32"
    />
    <RevIllustration
      v-else-if="image"
      alt=""
      aria-hidden="true"
      class="m-4"
      :height="32"
      :src="image"
      :width="32"
    />

    <span class="body-2 line-clamp-3 flex-1 text-ellipsis text-left">
      {{ label }}
    </span>
    <IconChevronRight v-if="clickable" />
  </component>
</template>

<script lang="ts" setup>
import { type Component } from 'vue'

import { RevButtonCard } from '@ds/components/ButtonCard'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'
import { openModal } from '@ds/components/ModalBase'
import { IconChevronRight } from '@ds/icons/IconChevronRight'

import DynamicIcon from '../DynamicIcon/DynamicIcon.vue'

const props = defineProps<{
  modalName: string
  icon?: string | Component
  image?: string
  label: string
  clickable?: boolean
}>()

function openDaModal() {
  openModal(props.modalName)
}
</script>
