<template>
  <RevButton
    v-if="shouldDisplayButton"
    full-width="always"
    target="_blank"
    :to="link"
    variant="secondary"
  >
    {{ i18n(translations.readMore) }}
  </RevButton>
</template>

<script lang="ts" setup>
import { useRouteLocationWithLocale, useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevButton } from '@ds/components/Button'

import { PRODUCT } from '~/scopes/product/route-names'

import translations from './LinkToVerifiedRefurbished.translations'

const resolveLocalizedRoute = useRouteLocationWithLocale()
const { countryCode } = useMarketplace().market
const i18n = useI18n()
const config = useRuntimeConfig()

const shouldDisplayButton = computed(() => {
  return config.public.FF_ENABLE_VERIFIED_REFURBISHED_LINK_PP?.includes(
    countryCode,
  )
})

const link = resolveLocalizedRoute({
  name: PRODUCT.EXTERNAL_PAGE_VERIFIED_REFURBISHED,
})
</script>
