export default {
  price: {
    id: 'product_page_customization_step_mobile_plan_add_on_price',
    defaultMessage: '{price}/mois',
  },
  firstMonths: {
    id: 'product_page_customization_step_mobile_plan_add_on_first_months',
    defaultMessage: '{price}/mois pendant {duration}',
  },
  rest: {
    id: 'product_page_customization_step_mobile_plan_add_on_rest',
    defaultMessage: 'puis {price}/mois — {enrollmentPeriod} d’engagement',
  },
  enrollmentPeriod: {
    id: 'product_page_customization_step_mobile_plan_add_on_enrollment_period',
    defaultMessage: '{duration} d’engagement',
  },
}
