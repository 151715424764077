<template>
  <div>
    <div class="my-16 flex flex-row items-center">
      <div class="grow">
        <Price
          v-if="product && selectedOffer"
          :mobile-plan="selectedMobilePlan"
          :price
          :product
          :selectedOffer
        />
      </div>

      <RevButton
        v-if="selectedOffer"
        data-id="product-page-buy-button-mobile"
        variant="primary"
        @click="onAddToCart"
      >
        {{ i18n(translations.addToCart) }}
      </RevButton>
    </div>

    <RevDivider class="my-16" />
  </div>
</template>

<script lang="ts" setup>
import { type Price as PriceType } from '@backmarket/http-api'
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import type { GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDivider } from '@ds/components/Divider'

import { useAddToCart } from '../../composables/useAddToCart'
import { useProductTracking } from '../../composables/useProductTracking'
import Price from '../Price/Price.vue'

import translations from './TitleDesktop.translations'

const props = withDefaults(
  defineProps<{
    selectedOffer?: GetPickersResponse['selectedOffer'] | null
    product: GetProductResponse
    price: PriceType
    showEcoBlock?: boolean
    rating?: Rating | null
    tracking: ReturnType<typeof useProductTracking>
    selectedMobilePlan?: MobilePlanOffer
  }>(),
  {
    selectedOffer: null,
    showEcoBlock: false,
    rating: null,
    selectedMobilePlan: undefined,
  },
)

const { addToCart } = useAddToCart()
const i18n = useI18n()

function onAddToCart() {
  if (props.selectedOffer) {
    addToCart({
      listingId: props.selectedOffer.offerLegacyId,
      listingPublicId: props.selectedOffer.offerId,
      tracking: {
        ...props.tracking.product.value,
        list: 'product_page',
      },
      partnerPromoCodes: props.product.includedServiceOffers.partnerPromoCodes,
      isTradeInEligible: props.product.isTradeInEligible,
    })
  }
}
</script>
