export default {
  content: {
    id: 'product_page_customization_step_sim_visible_by_verizon_advertisement_content',
    defaultMessage:
      'Need a phone plan? Get access to the Verizon network at just $30/month with Visible.',
  },
  logo: {
    id: 'visible_by_verizon_square_logo',
    defaultMessage: 'Visible by verizon square logo',
  },
}
