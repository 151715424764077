<template>
  <RevDrawer
    :close-button-label="i18n(translations.close)"
    :has-padding="false"
    :name="MODAL_NAMES.MACBOOK_PROCESSOR"
    :title="i18n(translations.title)"
  >
    <template #body>
      <ProcessorModalBody />
    </template>
  </RevDrawer>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevDrawer } from '@ds/components/Drawer'

import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './ProcessorModal.translations'
import ProcessorModalBody from './ProcessorModalBody/ProcessorModalBody.vue'

const i18n = useI18n()
</script>
