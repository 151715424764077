<template>
  <ArticlesCarousel v-if="shouldShowArticles" />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import { useMasterPPFeatureFlags } from '../../composables/useMasterPPFeatureFlags'

const ArticlesCarousel = defineAsyncComponent(
  () => import('./components/ArticlesCarousel/ArticlesCarousel.vue'),
)

const { shouldShowArticles } = useMasterPPFeatureFlags()
</script>
