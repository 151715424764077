<template>
  <ReassuranceItem>
    <template #icon>
      <IconQuality />
    </template>
    <template #content>
      <button
        class="body-2 flex w-full items-center justify-between text-left"
        @click="openReassuranceModal"
      >
        <div>
          {{ i18n(translations.freeReturn, { day: coolingOffDays }) }}
          <span v-if="warrantyDelay">
            <br />
            {{ warrantyDelay }}
          </span>
        </div>

        <IconChevronRight />
      </button>
      <ReassuranceModal />
    </template>
  </ReassuranceItem>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { openModal } from '@ds/components/ModalBase'
import { IconChevronRight } from '@ds/icons/IconChevronRight'
import { IconQuality } from '@ds/icons/IconQuality'

import { type Features } from '~/features'
import { MODAL_NAMES } from '~/scopes/product/constants'

import ReassuranceItem from '../ReassuranceItem/ReassuranceItem.vue'
import ReassuranceModal from '../ReassuranceModal/ReassuranceModal.vue'

import translations from './ItemReassurance.translations'

withDefaults(
  defineProps<{
    warrantyDelay?: string | null
  }>(),
  { warrantyDelay: '' },
)

const i18n = useI18n()
const { features } = useMarketplace<Features>()
const { coolingOffDays } = features

function openReassuranceModal() {
  openModal(MODAL_NAMES.REASSURANCE)
}
</script>
