export default {
  videoDisclaimer: {
    id: 'product_immersive_stories_video_disclaimer',
    defaultMessage: 'This video is for demonstration purposes only.',
  },
  loadingErrorTitle: {
    id: 'product_immersive_stories_loading_error_title',
    defaultMessage: 'We can’t load this media right now.',
  },
  loadingErrorDescription: {
    id: 'product_immersive_stories_loading_error_description',
    defaultMessage:
      'In the meantime, just picture a really sustainable refurbished device.',
  },
}
