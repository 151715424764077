export default {
  section1Item1Title: {
    id: 'product_page_condition_comparison_modal_section1_item1_title',
    defaultMessage: 'Without Premium',
  },
  section1Item1Paragraph1: {
    id: 'product_page_condition_comparison_modal_section1_item1_paragraph1',
    defaultMessage: 'Verified parts',
  },
  section1Item1Paragraph2: {
    id: 'product_page_condition_comparison_modal_section1_item1_paragraph2',
    defaultMessage: 'Might have wear',
  },
  section1Item1Paragraph2Description: {
    id: 'product_page_condition_comparison_modal_section1_item1_paragraph2_description',
    defaultMessage: 'Will range from gently used to flawless',
  },
  section1Item1Paragraph3: {
    id: 'product_page_condition_comparison_modal_section1_item1_paragraph3',
    defaultMessage: 'Standard battery',
  },
  section1Item1Paragraph3Description: {
    id: 'product_page_condition_comparison_modal_section1_item1_paragraph3_description',
    defaultMessage: '(min 80% capacity)',
  },
  section1Item2Title: {
    id: 'product_page_condition_comparison_modal_section1_item2_title',
    defaultMessage: 'With Premium',
  },
  section1Item2Paragraph1: {
    id: 'product_page_condition_comparison_modal_section1_item2_paragraph1',
    defaultMessage: '{brand} parts',
  },
  section1Item2Paragraph2: {
    id: 'product_page_condition_comparison_modal_section1_item2_paragraph2',
    defaultMessage: 'Looks flawless',
  },
  section1Item2Paragraph3: {
    id: 'product_page_condition_comparison_modal_section1_item2_paragraph3',
    defaultMessage: '{brand} battery',
  },
  section1Item2Paragraph3Description: {
    id: 'product_page_condition_comparison_modal_section1_item2_paragraph3_description',
    defaultMessage: '(min 90% capacity)',
  },
}
