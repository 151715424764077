export default {
  techSpec: {
    id: 'product_additional_info_tech_specs',
    defaultMessage: 'Technical Specifications',
  },
  warranty: {
    id: 'product_additional_info_warranty',
    defaultMessage: 'Warranty and services',
  },
  frequentlyAskedQuestions: {
    id: 'product_additional_info_frequently_asked_questions',
    defaultMessage: 'Frequently asked questions',
  },
}
