import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

export default {
  title: {
    id: 'product_page_customization_title',
    defaultMessage: 'Let’s find you one in our marketplace.',
  },
  helpCardBattery: {
    id: 'product_page_customization_step_battery_help',
    defaultMessage: 'See if your phone usage actually requires a new battery.',
  },
  helpCardSim: {
    id: 'product_sim_modal_opener_customization_funnel',
    defaultMessage:
      'A SIM allows you to activate a cellular plan from your carrier and holds information like your phone number. ',
  },
  helpCardProcessor: {
    id: 'product_processor_modal_opener_customization_funnel',
    defaultMessage: 'See what processor is right for you',
  },
  tradeInGuidance: {
    id: 'product_page_customization_step_trade_in_guidance',
    defaultMessage: 'See how Trade-in works',
  },
  mobilePlanSubsisdy: {
    id: 'product_page_customization_mobile_plan_subsidy_tag',
    defaultMessage: '{subsidy} de remise',
  },
  conditionModalLinkSmallScreen: {
    id: 'product_page_customization_condition_guidance_link',
    defaultMessage: 'Compare',
  },
  conditionDescription: {
    id: 'product_page_customization_condition_description_mobile',
    defaultMessage: 'All guaranteed 100% functional',
  },
} as Record<string, I18nDefinition>
