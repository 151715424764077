import { type HttpResponsePayload, createHttpEndpoint } from '../../utils'

export type GetAccessoryExclusionsResponse = HttpResponsePayload<{
  title: string
  image: string
  paragraphs: Array<{
    title: string
    icon: string
    text: string
  }>
}>

/**
 * Given a product id, returns all the data needed to render the accessory exclusions modal
 *
 * TODO Add link to the Spotlight page once available
 * @see {@link https://github.com/BackMarket/api-specs/pull/772}
 */
export const getProductAccessoryExclusions =
  createHttpEndpoint<GetAccessoryExclusionsResponse>({
    method: 'GET',
    operationId: 'get_product_accessory_exclusions',
    path: '/product-page/products/:productId/accessory-exclusions',
  })
