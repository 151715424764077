import { useRoute, useRouter } from '#imports'
import { onMounted } from 'vue'

import type { InternalLink } from '@backmarket/http-api'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { parse } from '@backmarket/utils/query-string/parse'
import { stringify } from '@backmarket/utils/query-string/stringify'

import { hashToQueryLink } from '../utils/hashToQueryLink'

export const useRedirectionToQueryParamsURL = () => {
  const router = useRouter()
  const route = useRoute()
  const logger = useLogger()

  onMounted(() => {
    const hash = parse(route.hash)

    if (isEmpty(hash) || !hash.l) {
      return
    }

    const link = {
      ...route,
      query: route.query,
      type: 'internal',
      hash: {
        ...parse(route.hash),
      },
    } as InternalLink

    const newRoute = hashToQueryLink(link)

    logger.info(
      '[PRODUCT] access product with hash and redirect to query params',
      { owners: ['bot-squad-product-page-front'] },
    )

    void router.replace({
      ...newRoute,
      hash: `#${stringify({
        ...newRoute.hash,
      })}`,
    })
  })
}
