<template>
  <div v-if="advertisement.isAdvertised" class="min-h-24">
    <PaypalAdvertisement
      v-if="selectedAdvertisement === 'paypal'"
      :base-price
      :payment-methods
      :spot="advertisement.spot"
    />
    <KlarnaModal
      v-if="selectedAdvertisement === 'klarna'"
      :base-price
      :payment-methods
      :spot="advertisement.spot"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type Price } from '@backmarket/http-api'
import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

import { usePaymentMethodsWithFallback } from '../../../methods'
import { usePaymentAdvertisement } from '../../composables/usePaymentAdvertisement'
import { PaymentAdvertisingSpot } from '../../types/PaymentAdvertisingSpot'
import { KLARNA_PAYMENT_METHODS } from '../KlarnaModal/KlarnaModal.config'
import KlarnaModal from '../KlarnaModal/KlarnaModal.vue'

import PaypalAdvertisement from './PaypalAdvertisement.vue'

const props = withDefaults(
  defineProps<{
    basePrice: Price
    iconClasses?: string
    spot: PaymentAdvertisingSpot
    paymentMethods?: PaymentMethod[] | null
    tag?: string
    textSize?: '1' | '2'
  }>(),
  {
    iconClasses: '',
    paymentMethods: null,
    tag: 'div',
    textSize: '2',
  },
)

const paymentMethods = usePaymentMethodsWithFallback(() => props.paymentMethods)
const includeDisabledMethods = computed(
  () => props.spot === PaymentAdvertisingSpot.CART,
)
const advertisement = usePaymentAdvertisement(
  () => props.spot,
  paymentMethods,
  { includeDisabledMethods },
)

const selectedAdvertisement = computed(() => {
  if (!advertisement.value.isAdvertised) return null

  if (advertisement.value.method.bmCode === 'paypal') return 'paypal'
  if (
    paymentMethods.value.find(({ bmCode }) =>
      KLARNA_PAYMENT_METHODS.includes(bmCode),
    )
  )
    return 'klarna'

  return null
})
</script>
