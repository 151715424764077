<template>
  <div>
    <PaymentAdvertisement
      v-if="
        !isAdvertisementDirectlyIntegrated &&
        selectedOffer &&
        selectedOffer.price
      "
      :base-price="selectedOffer.price"
      icon-classes="w-[40px] flex-shrink-0 mr-8 self-center"
      :payment-methods="selectedOffer.paymentMethods"
      spot="product"
      @open="trackOpen"
    />
    <IntegratedPaymentAdvertisement
      v-if="
        isAdvertisementDirectlyIntegrated &&
        selectedOffer &&
        selectedOffer.price
      "
      :base-price="selectedOffer.price"
      :payment-methods="selectedOffer.paymentMethods"
      spot="product"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { PaymentAdvertisement } from '@backmarket/nuxt-layer-payment/advertising'
import IntegratedPaymentAdvertisement from '@backmarket/nuxt-layer-payment/advertising/components/IntegratedPaymentAdvertisement/IntegratedPaymentAdvertisement.vue'
import { usePaymentAdvertisement } from '@backmarket/nuxt-layer-payment/advertising/composables/usePaymentAdvertisement'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import { EU_COUNTRIES_WITH_SPLIT_PRICE } from '~/scopes/product/components/AdvertisedPayment/AdvertisedPayment.constants'

withDefaults(
  defineProps<{
    selectedOffer?: GetPickersResponse['selectedOffer'] | null
  }>(),
  {
    selectedOffer: null,
  },
)

const { market } = useMarketplace()

const isAdvertisementDirectlyIntegrated = computed(() => {
  return EU_COUNTRIES_WITH_SPLIT_PRICE.includes(market.countryCode)
})

const { trackClick } = useTracking()

function trackOpen(advertisement: ReturnType<typeof usePaymentAdvertisement>) {
  trackClick({
    zone: 'installment plans',
    name: advertisement.value?.isAdvertised
      ? advertisement.value?.method.networks.join(',')
      : '',
  })
}
</script>
