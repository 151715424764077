import { useRoute } from '#imports'

import type { PickersResponseItem } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'

import type { Step } from '../components/CustomizationFunnel/utils/types'
import { PRODUCT } from '../route-names'

// Creating this as Pickers VS MixAndMatch are customizing the pickers items, adding props & co, but keeping the ones we need
type PickerCompatibleItem = {
  [key: string]: unknown
  productId?: string
  slug?: string
  available: boolean
  parameters?: Partial<PickersResponseItem['parameters']>
}

export const useCreateLinkFromPicker = () => {
  const route = useRoute()
  const i18n = useI18n()

  const createLink = (picker: PickerCompatibleItem, step?: Step) => {
    if (!picker.available || !picker.productId || !picker.slug) {
      return null
    }

    /**
     * We have two cases to manage:
     * - when the user is changing a mobile plan
     * - when the user is changing any other selection (grade, new battery...)
     *
     * In the first case when we select the mobile plan, we don't have in the picker the selection of the other pickers.
     * Ex: { parameters: { mobilePlanOfferId: "b2e4f4e4-8e25-4078-a230-f51138de6833" } }
     * In order to keep the selection of all the other pickers, we need to keep the query params we have in the route.
     *
     * In the second casewhen we select a grade (or something else that is not mobile plan), we don't have in the picker the selection
     * of the mobile plan moreover we receive mobilePlanId as null by default.
     * Ex: { parameters: { grade: { name: "État correct", value: 12 }, mobilePlanOfferId: null, specialOfferType: { name: "Batterie standard", value: 0 }}
     * In order to keep the selection of the mobile plan, we need to keep the query param from the route.
     */
    const isMobilePlan =
      !picker.parameters?.grade && !picker.parameters?.specialOfferType
    const offerType = isMobilePlan
      ? route.query.offerType
      : picker.parameters?.specialOfferType?.value

    // Adding a scroll=false param to avoid transitioning when using pickers. See product.vue
    let hash = '#scroll=false'

    // For an upcoming test on pickers, we need to know if a variant picker has been clicked or not
    // It's used onlu in `useProductRequests`
    if (
      (step && step.id !== 'grades') ||
      route.hash?.includes('variantClicked')
    ) {
      hash += '&variantClicked'
    }

    return toNuxtLink({
      name: PRODUCT.HOME,
      params: {
        locale: toBcp47Locale(i18n.currentLocale),
        slugV2: picker.slug,
        uuid: picker.productId,
      },
      query: removeEmptyValuesInObject({
        l: isMobilePlan
          ? route.query.l
          : String(picker.parameters?.grade?.value ?? ''),
        offerType: offerType !== 0 ? String(offerType ?? '') : null,
        mobilePlanOfferId: isMobilePlan
          ? picker.parameters?.mobilePlanOfferId
          : route.query.mobilePlanOfferId,
        withNoGrade: route.query.withNoGrade,
      }),
      hash,
    })
  }

  return {
    createLink,
  }
}
