import { useHead, useRequestURL, useRouter, useRuntimeConfig } from '#imports'
import { type Ref, computed } from 'vue'

import { type GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import type { Rating } from '@backmarket/http-api/src/api-specs-reviews/types/rating'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { getAllMarkets } from '@backmarket/nuxt-module-marketplace/getAllMarkets'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'

import { type Features } from '~/features'
import { useMasterPP } from '~/scopes/master-pp/composables/useMasterPP'
import type { MetaTypeKeys } from '~/utils/seo/constants'

import { getMetas } from '../../../utils/seo/meta'
import {
  buildProductSchema,
  prepareJsonLdScript,
} from '../../../utils/seo/schema'
import { PRODUCT } from '../route-names'

import translations from './useProductHead.translations'

export function useProductHead(
  product: Ref<GetProductResponse | null>,
  selectedOffer: Ref<GetPickersResponse['selectedOffer']>,
  rating: Ref<Rating | null>,
  isOutOfStock: Ref<boolean>,
) {
  const i18n = useI18n()
  const { features } = useMarketplace<Features>()
  const runtimeConfig = useRuntimeConfig()
  const router = useRouter()
  const { protocol, hostname } = useRequestURL()
  const { isPlpUrl } = useMasterPP()

  const title = computed(() => {
    if (!product.value) {
      return ''
    }

    return `${i18n(translations.metaTitle, { title: product.value.titles.raw })} | ${runtimeConfig.public.COMPANY_NAME}`
  })

  const description = computed(() => {
    if (!product.value) {
      return ''
    }

    return i18n(translations.metaDescription, {
      title: product.value.titles.default,
      warrantyDuration: features.warranty.duration,
      coolingOffDays: features.coolingOffDays,
      category: product.value.tracking.categoryName,
      brand: product.value.brand,
      model: product.value.model,
    })
  })

  /**
   * Alternates links are retrieved from the product endpoint, helping google to crawl the current product in alternative locales.
   * Canonical is here to help preventing duplicate content with URLs containing query params for instance.
   */
  const link = computed(() => {
    if (!product.value) {
      return []
    }

    if (isPlpUrl.value) {
      return []
    }

    const markets = getAllMarkets()

    return [
      ...Object.values(product.value.seo.marketsLinks).map(
        ({ countryCode, slug }) => {
          const locale = toBcp47Locale(
            markets[countryCode]?.defaultLocale ?? i18n.currentLocale,
          )

          return {
            rel: 'alternate',
            hreflang: locale,
            href: `${protocol}//www${markets[countryCode]?.domain}${router.resolve(toNuxtLink({ name: PRODUCT.HOME, params: { locale, slugV2: slug, uuid: product.value?.productId } })).href}`,
          }
        },
      ),
      {
        href: `${protocol}//${hostname}${router.resolve(toNuxtLink({ name: PRODUCT.HOME, params: { locale: toBcp47Locale(i18n.currentLocale), slugV2: product.value.seo.slug, uuid: product.value.productId } })).href}`,
        rel: 'canonical',
      },
    ]
  })

  const meta = computed(() => {
    if (!product.value) {
      return []
    }

    // checking listing in the URL should be enough

    const metas: Record<MetaTypeKeys | string, string> = {
      title: title.value,
      description: description.value,
      image: product.value.images?.[0]?.url ?? null,
      'og:type': 'product',
    }

    return getMetas(metas)
  })

  const script = computed(() => {
    if (!product.value || !rating.value || !selectedOffer.value) {
      return []
    }

    return [
      prepareJsonLdScript(
        buildProductSchema({
          title: product.value.titles.raw,
          description: description.value,
          image: product.value.images?.[0]?.url ?? null,
          brand: product.value.brand,
          rate: rating.value ?? undefined,
          price: selectedOffer.value.price,
          isOutOfStock: isOutOfStock.value,
          companyName: runtimeConfig.public.COMPANY_NAME,
        }),
      ),
    ]
  })

  useHead({
    title,
    link,
    meta,
    script,
  })
}
