import {
  type GetPickersResponse,
  type GetServicesPickersResponse,
  type PickersResponseItem,
  type PickersResponseServiceItem,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'

import { STEPS_WITHOUT_ICONS } from '../CustomizationFunnel.constants'
import { type Prefixes } from '../components/LargePicker/LargePicker.types'

function getPremiumIcon(
  option: PickersResponseItem | PickersResponseServiceItem,
): Prefixes {
  if (!option.available) {
    return 'IconDiamond'
  }

  if (option.selected) {
    return 'AnimatedDiamondFilled'
  }

  return 'AnimatedDiamondOutlined'
}

export const getOptionIcon = (
  step:
    | GetPickersResponse['pickerGroups'][number]
    | GetServicesPickersResponse['pickerGroups'][number],
  option: PickersResponseItem | PickersResponseServiceItem,
): Prefixes => {
  if (step.id === 'color') {
    return 'color'
  }

  if (step.id === 'grades') {
    // We should use option.grade.value but it's not defined when there is not stock for this grade.
    return option.trackingValue === '9' ? getPremiumIcon(option) : 'circle'
  }

  if (STEPS_WITHOUT_ICONS.includes(step.id)) {
    return ''
  }

  return 'circle'
}
