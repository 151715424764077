export const MAX_PICKER_ITEMS = 6

export const COLS_CLASS_DEFAULT = 'grid-cols-2'
export const COLS_CLASS_OVERRIDES: Record<string, string> = {
  storage: 'grid-cols-3',
}

/* This is only temporary because having this exception on the front side makes the generic pickers
 * not so generic anymore (action will be needed if another picker needs a sold out label as well).
 * We will have to add a new property on the back side to determine
 * when we should display sold out label.
 */
export const DISPLAY_SOLD_OUT = ['grades']
