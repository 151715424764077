<template>
  <StepLayout v-if="shouldShowStep" :tracking="trackingStep">
    <template #left>
      <RevIllustration
        alt=""
        class="block max-w-[498px] rounded-[32px] md:min-w-[337px]"
        :height="498"
        src="/img/product/funnel/desktop/macbook/step-keyboard.jpg"
        :width="498"
      />
    </template>

    <template #right>
      <div class="mb-16 flex items-baseline justify-between">
        <h3 class="heading-1">
          {{ i18n(translations.title) }}
        </h3>
      </div>

      <RevCard
        class="!bg-static-warning-mid mb-32 flex min-h-[72px] w-full flex-row items-center gap-12 p-12 pr-16"
      >
        <IconWarning aria-hidden="true" class="m-4" size="32" />
        <span class="body-2 line-clamp-3 flex-1 text-ellipsis text-left">
          {{ i18n(translations.warning) }}
        </span>
      </RevCard>

      <ul class="list-none">
        <li class="mb-12">
          <LargePicker
            class="m-auto h-full"
            :index="0"
            :label="keyboard"
            prefix="circle"
            :price="i18n.price(price)"
            selected
            @click="
              trackClick({
                zone: 'keyboard_picker',
                name: 'keep_keyboard',
              })
            "
          />
        </li>
        <li class="mb-12">
          <RevButtonBase
            v-if="landing"
            class="bg-static-default-low border-action-default-low focus-visible-outline-default-hi rounded-sm relative flex size-full flex-col border px-16 py-12 no-underline hover:bg-static-default-low-hover"
            :to="landing"
            @click="
              trackClick({
                zone: 'keyboard_picker',
                name: 'change_keyboard',
              })
            "
          >
            <div class="flex gap-16">
              <span class="grow">{{ i18n(translations.selectAnother) }}</span>
              <IconArrowRight aria-hidden="true" />
            </div>
          </RevButtonBase>
        </li>
      </ul>
    </template>
  </StepLayout>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type Price } from '@backmarket/http-api'
import { type GetBreadcrumbResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/breadcrumb'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { type GetTechnicalSpecificationsResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/technical-specifications'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'
import { IconArrowRight } from '@ds/icons/IconArrowRight'
import { IconWarning } from '@ds/icons/IconWarning'

import LargePicker from '../LargePicker/LargePicker.vue'
import StepLayout from '../StepLayout/StepLayout.vue'

import {
  COMPATIBLE_CATEGORIES,
  LOCALE_DEFAULT_KEYBOARD,
} from './StepIncompatibleKeyboard.constants'
import translations from './StepIncompatibleKeyboard.translations'

const props = withDefaults(
  defineProps<{
    breadcrumb?: GetBreadcrumbResponse | null | undefined
    price: Price
    product: GetProductResponse
    technicalSpecifications?:
      | GetTechnicalSpecificationsResponse
      | null
      | undefined
  }>(),
  {
    breadcrumb: undefined,
    technicalSpecifications: undefined,
  },
)

const i18n = useI18n()
const { market } = useMarketplace()
const { trackClick } = useTracking()

const trackingStep = computed(() => {
  return {
    trackingTags: {
      tagDisplayed: 'no_tag',
      tagSelected: false,
    },
    trackingId: 'keyboard_picker',
    trackingModel: props.product.model,
    trackingCategory: props.product.tracking.categoryName,
  }
})

const keyboard = computed(() => {
  if (
    !props.technicalSpecifications ||
    isEmpty(props.technicalSpecifications.specifications)
  ) {
    return ''
  }

  const spec = props.technicalSpecifications.specifications.find(
    ({ field }) => field === 'keyboard_type_language',
  )

  if (!spec) {
    return ''
  }

  return spec.values?.[0].label ?? ''
})

const landing = computed(() => {
  if (!props.breadcrumb || isEmpty(props.breadcrumb.breadcrumb)) {
    return null
  }

  const url = props.breadcrumb.breadcrumb.slice(-1).pop()?.url
  const facet = `#keyboard_type_language=${encodeURIComponent(LOCALE_DEFAULT_KEYBOARD[market.defaultLocale] ?? '')}`

  return url ? url + facet : null
})

const shouldShowStep = computed(() => {
  // Technical specifications & breadcrumb are optional endpoint. We don't want to break
  // anything if they don't answer in time.
  if (!keyboard.value || !landing.value) {
    return false
  }

  // This step is only for macbooks
  if (!COMPATIBLE_CATEGORIES.includes(props.product.tracking.categoryId)) {
    return false
  }

  // Unhandled locales (i.e. KR for now)
  if (!(market.defaultLocale in LOCALE_DEFAULT_KEYBOARD)) {
    return false
  }

  // Don't show the step if the locale is the default one for the country
  if (LOCALE_DEFAULT_KEYBOARD[market.defaultLocale] === keyboard.value) {
    return false
  }

  return true
})
</script>
