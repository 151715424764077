export default {
  visibleByVerizonBasicOffer: {
    id: 'visible_by_verizon_chip_exclusive_plan',
    defaultMessage: 'Exclusive: Visible by Verizon plan',
  },
  logo: {
    id: 'visible_by_verizon_tiny_logo',
    defaultMessage: 'Visible by verizon tiny logo',
  },
  visibleByVerizonPremiumOffer: {
    id: 'visible_by_verizon_chip_premium_offer',
    defaultMessage: 'Save big: $30/month unlimited data',
  },
}
