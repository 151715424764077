<template>
  <Card
    class="relative"
    :is-loading
    :is-opened
    :status
    :tracking
    @click="emit('click')"
  >
    <template #header>
      <CardHeader :label="i18n(translations[`cardHeader_${status}`])" :status />
    </template>

    <h2 class="heading-1 mb-4 px-24 md:text-center">
      {{ i18n(translations.title) }}
    </h2>

    <div aria-hidden="true" class="md:hidden">
      <ExampleImage class="text-static-default-low pl-24" :with-icon="false" />

      <ImageComparison
        class="bg-surface-default-hi mt-8"
        :image1="{ src: images[1], label: i18n(translations.decline) }"
        :image2="{ src: images[0], label: i18n(translations.accept) }"
        :tracking="{ zone: `no_grade_${type}` }"
      />
    </div>

    <div
      class="absolute bottom-0 left-0 right-0 rounded-b-[0.6875rem] px-24 md:static"
      :class="$style.gradient"
    >
      <PriceGroup
        :accept="{ price: prices[0], label: i18n(translations.accept) }"
        class="mb-24 mt-48 md:mt-24"
        :decline="{ price: prices[1], label: i18n(translations.decline) }"
        theme="white"
        :tracking="{ zone: `no_grade_${type}` }"
        @accept="emit('accept')"
        @decline="emit('decline')"
      />
    </div>
  </Card>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { Price } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import ExampleImage from '../../../ExampleImage/ExampleImage.vue'
import Card from '../Card/Card.vue'
import CardHeader from '../CardHeader/CardHeader.vue'
import ImageComparison from '../ImageComparison/ImageComparison.vue'
import PriceGroup from '../PriceGroup/PriceGroup.vue'

import translationsBody from './StepBody.translations'
import translationsScreen from './StepScreen.translations'

const props = withDefaults(
  defineProps<{
    prices: Array<Price>
    images: Array<string>
    isOpened?: boolean
    isLoading?: boolean
    type: 'screen' | 'body'
    status?: 'accepted' | 'declined' | 'unknown'
    tracking: Record<string, string>
  }>(),
  {
    isOpened: false,
    isLoading: false,
    status: 'unknown',
  },
)

const emit = defineEmits<{
  (e: 'click'): void
  (e: 'accept'): void
  (e: 'decline'): void
}>()

const i18n = useI18n()

const translations = computed(() => {
  return props.type === 'screen' ? translationsScreen : translationsBody
})

const tracking = computed(() => {
  const acceptPrice = parseFloat(props.prices[0].amount)
  const declinePrice = parseFloat(props.prices[1].amount)

  return {
    ...props.tracking,
    price_accept: props.prices[0].amount,
    price_decline: props.prices[1].amount,
    good_deal: acceptPrice < declinePrice,
  }
})
</script>

<style module>
.gradient {
  @media (max-width: 767px) {
    background: linear-gradient(180deg, rgba(15, 17, 23, 0) 0%, #0f1117 100%);
  }
}
</style>
