<template>
  <div class="caption">
    <span>{{ i18n(translations.example) }}</span>
    <IconCamera v-if="withIcon" data-test="icon" size="16" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconCamera } from '@ds/icons/IconCamera'

import translations from './ExampleImage.translations'

withDefaults(
  defineProps<{
    withIcon?: boolean
  }>(),
  {
    withIcon: true,
  },
)

const i18n = useI18n()
</script>
