<template>
  <BouyguesFlashOffer>
    <template #previous>
      {{ i18n(translations.flashOffer180EuroPreviousBenefit) }}
    </template>
    <template #new>
      {{ i18n(translations.flashOffer180EuroNewBenefit) }}
    </template>
  </BouyguesFlashOffer>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import BouyguesFlashOffer from '../BouyguesFlashOffer/BouyguesFlashOffer.vue'

import translations from './SubsidyBoostFlashOffer.translations'

const i18n = useI18n()
</script>
