export default {
  showLess: {
    id: 'pickers_list_show_less',
    defaultMessage: 'Show less',
  },
  showMore: {
    id: 'pickers_list_show_more',
    defaultMessage: '+{remaining, number}',
  },
}
