<template>
  <VisibleByVerizonChip
    v-if="hasPartnerPromoCode(partnerPromoCodes, 'VISIBLE')"
    :hasVisiblePremiumOffer
    :tracking-zone="`visible_${trackingZone}`"
  />

  <RakutenChip
    v-if="hasPartnerPromoCode(partnerPromoCodes, 'RAKUTEN')"
    :tracking-zone="`rakuten_${trackingZone}`"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'

import { hasPartnerPromoCode } from '~/scopes/product/utils/hasPartnerPromoCode'
import { hasVisiblePremium } from '~/scopes/product/utils/hasVisiblePremium'

import RakutenChip from './components/RakutenChip/RakutenChip.vue'
import VisibleByVerizonChip from './components/VisibleByVerizonChip/VisibleByVerizonChip.vue'

const props = defineProps<{
  partnerPromoCodes: GetProductResponse['includedServiceOffers']['partnerPromoCodes']
  trackingZone: string
}>()

const hasVisiblePremiumOffer = computed<boolean>(() => {
  const visibleOffer = props.partnerPromoCodes.find(
    (partnerPromoCodes) => partnerPromoCodes.partnerName === 'VISIBLE',
  )

  if (!visibleOffer) {
    return false
  }

  return hasVisiblePremium(visibleOffer)
})
</script>
