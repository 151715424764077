export default {
  alternativeController: {
    id: 'alternative-controller',
    defaultMessage: 'Controller',
  },
  alternativeNext: {
    id: 'alternative-next',
    defaultMessage: 'Next',
  },
  alternativePrevious: {
    id: 'alternative-previous',
    defaultMessage: 'Previous',
  },
  carouselTitle1: {
    id: 'rakuten_carousel_title_1',
    defaultMessage: 'Loyalty points to save on a plan',
  },
  carouselTitle2: {
    id: 'rakuten_carousel_title_2',
    defaultMessage: 'How it works',
  },
  chip1: {
    id: 'rakuten_carousel_chip_1',
    defaultMessage: "Rakuten Mobile's 5G service available",
  },
  chip2: {
    id: 'rakuten_carousel_chip_2',
    defaultMessage: '3GB or Unlimited data',
  },
  chip3: {
    id: 'rakuten_carousel_chip_3',
    defaultMessage: 'Up to 11,000 points to choose your plan',
  },
  chip4: {
    id: 'rakuten_carousel_chip_4',
    defaultMessage: 'Buy a phone on Back Market',
  },
  chip5: {
    id: 'rakuten_carousel_chip_5',
    defaultMessage: 'Get your Back Market Order ID in your account',
  },
  chip6: {
    id: 'rakuten_carousel_chip_6',
    defaultMessage: 'Subscribe to a Rakuten mobile plan and save',
  },
}
