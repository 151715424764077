<template>
  <DataBoostFlashOffer v-if="hasDataBoost" />
  <SubsidyBoostFlashOffer v-if="hasSubsidyBoost" />
  <BouyguesOfferSelected v-if="selected" :offer />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'

import BouyguesOfferSelected from './components/BouyguesOfferSelected/BouyguesOfferSelected.vue'
import DataBoostFlashOffer from './components/DataBoostFlashOffer/DataBoostFlashOffer.vue'
import SubsidyBoostFlashOffer from './components/SubsidyBoostFlashOffer/SubsidyBoostFlashOffer.vue'

const props = defineProps<{
  offer: MobilePlanOffer
  selected: boolean
}>()

const hasDataBoost = computed(() =>
  props.offer.benefits.includes('FLASH_OFFER_130_GO_INSTEAD_OF_90_GO'),
)

const hasSubsidyBoost = computed(() =>
  props.offer.benefits.includes(
    'FLASH_OFFER_180_EURO_SUBSIDY_INSTEAD_OF_150_EURO',
  ),
)
</script>
