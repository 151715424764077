import { MarketCountryCode } from '@backmarket/http-api'

import { CMS } from '~/scopes/cms/routes-names'

type Link = {
  name: string
  params: Record<string, string>
}
type Links = Partial<Record<MarketCountryCode, Link>>

export const LINKS: Links = {
  [MarketCountryCode.US]: {
    name: CMS.ARTICLE,
    params: {
      pageName: 'refurbished-phone-battery',
      category: 'smartphone-guides',
    },
  },
  [MarketCountryCode.GB]: {
    name: CMS.ARTICLE,
    params: {
      pageName: 'refurbished-phone-battery',
      category: 'smartphone-guides',
    },
  },
  [MarketCountryCode.FR]: {
    name: CMS.ARTICLE,
    params: {
      pageName: 'refurbished-phones-batteries',
      category: 'news',
    },
  },
  [MarketCountryCode.DE]: {
    name: CMS.ARTICLE,
    params: {
      pageName: 'refurbished-phones-batteries',
      category: 'news',
    },
  },
  [MarketCountryCode.ES]: {
    name: CMS.ARTICLE,
    params: {
      pageName: 'refurbished-phones-batteries',
      category: 'news',
    },
  },
}
