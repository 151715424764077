export default {
  modalTitle: {
    id: 'product_page_verified_refurbished_new_modal_main_title',
    defaultMessage: 'What is Verified Refurbished?',
  },
  close: {
    id: 'product_page_verified_refurbished_new_modal_close',
    defaultMessage: 'Close',
  },
  title: {
    id: 'product_page_verified_refurbished_new_modal_title',
    defaultMessage: 'This device is\nVerified Refurbished',
  },
  item1title: {
    id: 'product_page_verified_refurbished_new_modal_item_1_title',
    defaultMessage: '100% functionality, guaranteed',
  },
  item1text: {
    id: 'product_page_verified_refurbished_new_modal_item_1_text',
    defaultMessage:
      'Tested and checked thoroughly, backed by a 1-year warranty and free 30-day returns.',
  },
  item2title: {
    id: 'product_page_verified_refurbished_new_modal_item_2_title',
    defaultMessage: 'Clean slate',
  },
  item2text: {
    id: 'product_page_verified_refurbished_new_modal_item_2_text',
    defaultMessage:
      'Squeaky clean inside and out, complete with a factory data reset.',
  },
  item3title: {
    id: 'product_page_verified_refurbished_new_modal_item_3_title',
    defaultMessage: 'No red flags',
  },
  item3text: {
    id: 'product_page_verified_refurbished_new_modal_item_3_text',
    defaultMessage:
      'No malware in sight. Device not locked, stolen, or fraudulently sourced.',
  },
  readMore: {
    id: 'product_page_verified_refurbished_new_modal_link',
    defaultMessage: 'Discover Verified Refurbished',
  },
}
