export default {
  buttonLabel: {
    id: 'visible_by_verizon_offer_button_label',
    defaultMessage: 'See plan details',
  },
  termsApply: {
    id: 'visible_by_verizon_offer_terms_apply',
    defaultMessage: 'Terms apply.',
  },
  basicOfferTitle: {
    id: 'visible_by_verizon_basic_offer_title',
    defaultMessage: 'Go unlimited for 20% less.',
  },
  premiumOfferTitle: {
    id: 'visible_by_verizon_premium_offer_title_on_mobile',
    defaultMessage: 'Go unlimited for 33% less.',
  },
  titleOnDesktop: {
    id: 'visible_by_verizon_offer_title_on_desktop',
    defaultMessage: 'Get your exclusive savings with {offerName, html}',
  },
  offerName: {
    id: 'visible_by_verizon_offer_title_offer_name',
    defaultMessage: 'Visible+',
  },
  basicOfferLabel: {
    id: 'visible_by_verizon_offer_basic_offer_label',
    defaultMessage:
      "This device comes with an offer for unlimited 5G & 4G LTE data on Verizon's network for $20/month for 3 years. That's a total savings of $180 with no strings attached.",
  },
  premiumOfferLabel: {
    id: 'visible_by_verizon_offer_premium_offer_label',
    defaultMessage:
      "This device comes with an offer for unlimited premium 5G data on Verizon's network for $30/month for 3 years. That's a total savings of $540 with no strings attached.",
  },
  logo: {
    id: 'visible_by_verizon_offer_partnered_with_back_market',
    defaultMessage: 'Visible partnered with Back Market',
  },
}
