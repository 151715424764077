import { useRoute, useRouter } from '#imports'
import { type Ref, watch } from 'vue'

import { type GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { useUrlParams } from './useUrlParams'

/**
 * When the grade in the URL points towards a disabled one, we need to fallback to the one
 * selected by default (provided by the endpoint).
 *
 * This can happen in a few occasions:
 * - direct access on the pp with an out of stock grade (user favorites or history for instance)
 * - wrong grade provided by the pickers endpoint (issue raised on their side that should be fixed, but better safe than sorry)
 * - ...?
 */
export const useGradeFallback = (pickers: Ref<GetPickersResponse | null>) => {
  const { grade: gradeInURL } = useUrlParams()
  const router = useRouter()
  const route = useRoute()
  const logger = useLogger()

  watch(
    [pickers],
    () => {
      if (!pickers.value || !gradeInURL.value) {
        return
      }

      const grades = pickers.value.pickerGroups.find(
        (picker) => picker.id === 'grades',
      )?.items

      const selectedGrade = grades?.find(
        (grade) => grade && grade.trackingValue && grade.selected,
      )

      if (!selectedGrade) {
        return
      }

      if (
        !selectedGrade.available ||
        selectedGrade.parameters?.grade.value !== gradeInURL.value
      ) {
        const fallbackGrade = grades?.find((grade) => grade.available === true)

        if (fallbackGrade) {
          logger.info('[PRODUCT] Disabled grade in the URL', {
            owners: ['bot-squad-product-page-front'],
          })

          const link = {
            ...route,
            type: 'internal',
            query: {
              ...route.query,
              l: fallbackGrade.parameters?.grade.value.toString(),
            },
          }

          void router.replace(link)
        }
      }
    },
    { immediate: true },
  )
}
