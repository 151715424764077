export default {
  headerTitle: {
    id: 'rakuten_carousel_header_title',
    defaultMessage: 'Rakuten mobile points comes with this phone',
  },
  learnMoreLink: {
    id: 'rakuten_carousel_header_link',
    defaultMessage: 'https://www.backmarket.co.jp/ja-jp/service/rakuten-mobile',
  },
}
