export default {
  flashOffer180EuroPreviousBenefit: {
    id: 'bouygues_flash_offer_180_euro_previous_benefit',
    defaultMessage: '150€',
  },
  flashOffer180EuroNewBenefit: {
    id: 'bouygues_flash_offer_180_go_new_benefit',
    defaultMessage: '180€ de réduction',
  },
}
