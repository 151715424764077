<template>
  <Chip
    isInteractive
    :label="
      hasVisiblePremiumOffer
        ? i18n(translations.visibleByVerizonPremiumOffer)
        : i18n(translations.visibleByVerizonBasicOffer)
    "
    @click="openVisibleByVerizonModal"
  >
    <template #prefix>
      <RevIllustration
        :alt="i18n(translations.logo)"
        :height="20"
        src="/img/visible-by-verizon/visible-by-verizon-logo-tiny.svg"
        :width="20"
      />
    </template>
  </Chip>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevIllustration } from '@ds/components/Illustration'
import { openModal } from '@ds/components/ModalBase'

import Chip from '~/scopes/product/components/Chip/Chip.vue'
import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './VisibleByVerizonChip.translations'

const props = defineProps<{
  trackingZone: string
  hasVisiblePremiumOffer: boolean
}>()

const i18n = useI18n()
const { trackClick } = useTracking()

function openVisibleByVerizonModal() {
  trackClick({
    name: 'visible_chip',
    zone: props.trackingZone,
  })
  openModal(MODAL_NAMES.VISIBLE_BY_VERIZON_BENEFITS)
}
</script>
