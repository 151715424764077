import { MODAL_NAMES } from '../../constants'

import { type Step } from './utils/types'

export const STEP_DESKTOP_ILLUSTRATION_SMARTPHONE: Record<string, string> = {
  battery: '/img/product/funnel/desktop/smartphone/step-battery.jpg',
  dual_sim: '/img/product/funnel/desktop/smartphone/step-sim.jpg',
  storage: '/img/product/funnel/desktop/smartphone/step-storage.jpg',
}

export const STEP_DESKTOP_ILLUSTRATION_MACBOOK: Record<string, string> = {
  battery: '/img/product/funnel/desktop/macbook/step-battery.jpg',
  processor_type_and_speed:
    '/img/product/funnel/desktop/macbook/step-processor.jpg',
  processor_type_and_graphic_card:
    '/img/product/funnel/desktop/macbook/step-processor.jpg',
  memory: '/img/product/funnel/desktop/macbook/step-memory.jpg',
  storage: '/img/product/funnel/desktop/macbook/step-storage.jpg',
  display: '/img/product/funnel/step-display.jpg',
}

export const STEP_DESKTOP_ILLUSTRATION_AIRPODS: Record<string, string> = {
  magsafe_compatible: '/img/product/funnel/desktop/airpods/step-magsafe.jpg',
}

export const STEP_DESKTOP_ILLUSTRATION_GENERIC: Record<string, string> = {
  storage: '/img/product/funnel/step-storage.jpg',
  network: '/img/product/funnel/step-network.jpg',
  display: '/img/product/funnel/step-display.jpg',
}

export const STEP_DESKTOP_ILLUSTRATION_SERVICES: Record<string, string> = {
  trade_in: '/img/product/funnel/step-trade-in-2.jpg',
}

export const STEP_GUIDANCE: Record<
  string,
  NonNullable<Step['guidance']>['modalName']
> = {
  grades: MODAL_NAMES.CONDITION_COMPARISON,
  battery: MODAL_NAMES.NEW_BATTERY,
  dual_sim: MODAL_NAMES.SIM,
  processor_type_and_speed: MODAL_NAMES.MACBOOK_PROCESSOR,
  processor_type_and_graphic_card: MODAL_NAMES.MACBOOK_PROCESSOR,
  trade_in: MODAL_NAMES.TRADE_IN,
}

export const STEP_COLUMNS: Record<string, number> = {
  grades: 2,
  color: 2,
}

export const STEPS_WITHOUT_ICONS = [
  'display',
  'magsafe_compatible',
  'case_size',
  'case_material',
  'band_type',
  'band_color',
]
