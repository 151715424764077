import { type HttpResponsePayload, createHttpEndpoint } from '../../utils'

/**
 * Get the data for the client to build the eco block, based on a product
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/product-page-api/definition#tag/getRenewedImpact/operation/getEcoBlock}
 */
export const getProductEcoBlock = createHttpEndpoint<GetEcoBlockResponse>({
  method: 'GET',
  operationId: 'get_eco_block',
  path: '/product-page/products/:productId/eco-block',
})

export type GetEcoBlockResponse = HttpResponsePayload<{
  display: boolean
  title?: string
  description?: string
  figures?: Array<{
    title: string
    description: string
    type: 'e-waste' | 'co2' | 'water' | 'raw-materials'
  }>
}>

export const getEcoBlockDefaultValue: GetEcoBlockResponse = {
  display: false,
  title: '',
  description: '',
  figures: [],
}
