export default {
  freeShippingWithoutDates: {
    id: 'product_page_shipping_without_date',
    defaultMessage: 'Free delivery',
  },
  freeShipping: {
    id: 'product_page_shipping_with_date',
    defaultMessage: 'Get it between {dates, html} free standard shipping',
  },
  freeShippingDates: {
    id: 'product_page_shipping_dates',
    defaultMessage: '{earliest} and {latest}',
  },
  expressShipping: {
    id: 'product_page_express_shipping',
    defaultMessage: 'Get it from {amount} between {earliest} and {latest}',
  },
  freeShippingBeforeChristmas: {
    id: 'product_page_shipping_free_before_christmas',
    defaultMessage: 'Free delivery before Christmas, {earliest} - {latest}',
  },
  expressShippingBeforeChristmas: {
    id: 'product_page_shipping_express_before_christmas',
    defaultMessage: 'Express delivery before Christmas, {earliest} - {latest}',
  },
}
