export default {
  title: {
    id: 'product_page_seller_block_title',
    defaultMessage: 'Refurbished by',
  },
  subtitle: {
    id: 'product_page_seller_block_subtitle',
    defaultMessage: 'Reseller since {since}, location: {location}',
  },
  paymentMethodAccepted: {
    id: 'product_page_seller_ships_accepted_payment',
    defaultMessage: 'Accepts',
  },
}
