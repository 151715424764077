/**
 * Naive case insensitive search mechanism
 *
 * Ex:
 * const objects = [
 *  { label: 'foo', ... },
 *  { label: 'bar', ... },
 * ]
 * searchObjects(objects, 'label', 'fo')
 *
 * @param {array} objects
 * @param {string} key
 * @param {string} search
 * @returns array
 */
export function searchObjects<T extends object>(
  objects: ReadonlyArray<T> = [],
  key = '',
  search = '',
) {
  return objects.filter((obj: T) =>
    (obj[key as keyof T] as string)
      .toLowerCase()
      .includes(search.toLowerCase().trim()),
  )
}
