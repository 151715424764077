export default {
  drawerTitle: {
    id: 'product_page_visible_by_verizon_drawer_title',
    defaultMessage: 'Exclusive savings with Visible',
  },
  closeButtonLabel: {
    id: 'product_page_visible_by_verizon_drawer_close_button_label',
    defaultMessage: 'Close "Visible by Verizon x Back Market" drawer',
  },
}
