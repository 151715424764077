<template>
  <RevContainer>
    <RevBreadcrumb
      v-if="links.length"
      :ariaLabel="i18n(translations.breadcrumb)"
      :breads="links"
    />
  </RevContainer>
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'
import { computed } from 'vue'

import { type GetBreadcrumbResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/breadcrumb'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'
import { RevContainer } from '@ds/components/Container'

import translations from './Breadcrumb.translations'

const props = defineProps<{
  breads: GetBreadcrumbResponse['breadcrumb']
  title: string
}>()

const i18n = useI18n()
const route = useRoute()

const links = computed(() => {
  if (isEmpty(props.breads)) {
    return []
  }

  return [
    ...props.breads.map(({ label, url }) => ({
      title: label,
      link: url,
    })),
    // Adding the current URL as last item, as the new breadcrumb service returns only the parent landing pages.
    {
      title: props.title,
      link: route.path,
    },
  ]
})
</script>
