<template>
  <BouyguesIncompatibleSIM
    v-if="shouldDisplayIncompatibleSIMBlock"
    :step
    :tracking-model
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { Step } from '../../utils/types'

import BouyguesIncompatibleSIM from './components/BouyguesIncompatibleSIM/BouyguesIncompatibleSIM.vue'

const props = defineProps<{
  step: Step
  trackingModel?: string
}>()

const shouldDisplayIncompatibleSIMBlock = computed(() => {
  return props.step.options.slice(1).every((o) => !o.available)
})
</script>
