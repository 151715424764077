<template>
  <RecommendationCarousel
    :options="{
      withCrossedPrice: true,
      withStartingFrom: false,
      withGrade: true,
      withAddToCart: true,
    }"
    :recommendation-query="{
      category: 'crossSellProtection',
      scope: 'addToCart',
      scopeId: `${listingId}`,
      limit: 12,
    }"
    :title="i18n(translations.recommendationTitle)"
    :trackingData="{
      list: 'cross sell modal 2',
    }"
  />
</template>

<script lang="ts" setup>
import RecommendationCarousel from '@backmarket/nuxt-layer-recommendation/RecommendationCarousel.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './RecommendationCarousel.translations'

const i18n = useI18n()

defineProps<{
  listingId: number
}>()
</script>
