<template>
  <RevDrawer
    :close-button-label="i18n(translations.closeButtonLabel)"
    :name="MODAL_NAMES.CONDITION_COMPARISON"
    :title="i18n(translations.drawerTitle)"
  >
    <template #body>
      <section class="text-center">
        <h2 class="heading-1">
          {{ i18n(translations.section1Title) }}
        </h2>
        <p class="text-static-default-hi body-1 pt-24">
          {{ i18n(translations.section1Description) }}
        </p>

        <ComparisonArea :brand class="mt-24" :options />
        <Checkpoints :category-id class="mt-24" />
        <LinkToVerifiedRefurbished class="mt-16" />
      </section>
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevDrawer } from '@ds/components/Drawer'

import type { Option } from '~/scopes/product/components/CustomizationFunnel/utils/types'
import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './ConditionDescriptionModal.translations'
import Checkpoints from './components/Checkpoints/Checkpoints.vue'
import ComparisonArea from './components/ComparisonArea/ComparisonArea.vue'
import LinkToVerifiedRefurbished from './components/LinkToVerifiedRefurbished/LinkToVerifiedRefurbished.vue'

const i18n = useI18n()

defineProps<{
  brand: string
  categoryId: string
  options?: Option[]
}>()
</script>
