<template>
  <div v-if="shouldDisplayPickers">
    <PickerGroup
      v-for="group in pickersWithLoading"
      :key="group.id"
      class="mb-32"
      :picker="group"
      @change="handleChange"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

import { type GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'

import { getPriceWithTradeIn } from '~/scopes/product/utils/getPriceWithTradeIn'

import PickerGroup from './components/PickerGroup/PickerGroup.vue'

const props = defineProps<{
  pickers: GetPickersResponse['pickerGroups']
}>()

const emit = defineEmits(['change'])
const { buybackOffer } = useBuybackOffer()
const loadingGroup = ref('')
const loadingIndex = ref<number | undefined>(undefined)

watch(props, () => {
  loadingGroup.value = ''
  loadingIndex.value = undefined
})

const shouldDisplayPickers = computed(() => {
  return props.pickers.length > 0
})

const pickersWithoutBattery = computed(() => {
  return props.pickers.filter(({ id }) => id !== 'battery')
})

const pickersWithTradeInPrice = computed(() => {
  if (!buybackOffer.value) {
    return pickersWithoutBattery.value
  }

  return pickersWithoutBattery.value.map((picker) => ({
    ...picker,
    items: picker.items.map((item) => ({
      ...item,
      price:
        item.price && buybackOffer.value?.listing.price.amount
          ? {
              amount: getPriceWithTradeIn(
                item.price,
                parseFloat(buybackOffer.value?.listing.price.amount),
              ),
              currency: item.price.currency,
            }
          : undefined,
    })),
  }))
})

const pickersWithLoading = computed(() => {
  return pickersWithTradeInPrice.value.map((picker) => ({
    ...picker,
    items: picker.items.map((item, index) => ({
      ...item,
      loading: loadingGroup.value === picker.id && loadingIndex.value === index,
    })),
  }))
})

function handleChange(pickerId: string, index: number) {
  loadingGroup.value = pickerId
  loadingIndex.value = index

  emit('change', pickerId, index)
}
</script>
