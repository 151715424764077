<template>
  <RevDrawer
    :close-button-label="i18n(translations.close)"
    :name="MODAL_NAMES.SIM"
    :title="i18n(translations.title)"
  >
    <template #body>
      <section class="text-center">
        <h2 class="text-static-default-hi heading-1">
          {{ i18n(translations.introTitle) }}
        </h2>
        <p class="text-static-default-low body-1 pt-16">
          {{ i18n(translations.introText) }}
        </p>
      </section>

      <section class="my-56 text-center">
        <div class="body-1-bold flex gap-16 py-12">
          <h3 class="w-1/2">
            {{ i18n(translations.compareSim) }}
          </h3>
          <h3 class="w-1/2">
            {{ i18n(translations.compareEsim) }}
          </h3>
        </div>
        <div class="text-static-default-low body-2 flex gap-16 pb-12">
          <p class="w-1/2">
            {{ i18n(translations.compareSimSubtitle) }}
          </p>
          <p class="w-1/2">
            {{ i18n(translations.compareEsimSubtitle) }}
          </p>
        </div>
        <div>
          <div class="text-static-default-hi group body-1 mt-12 flex gap-16">
            <p
              class="bg-static-info-low rounded-t-md relative flex w-1/2 items-center justify-center px-16 pb-16 pt-24"
            >
              <IconSim class="absolute -top-10 mx-auto" />
              {{ i18n(translations.compareSimValue1) }}
            </p>
            <p
              class="bg-static-info-low rounded-t-md relative flex w-1/2 items-center justify-center px-16 pb-16 pt-24"
            >
              <RevTag
                class="absolute -top-10 mx-auto"
                :label="i18n(translations.popular)"
                :variant="VariantsEnum.Primary"
              />
              {{ i18n(translations.compareESimValue1) }}
            </p>
          </div>

          <div
            v-for="i in [2, 3] as const"
            :key="i"
            class="text-static-default-hi group body-1 flex gap-16"
          >
            <p
              class="bg-static-info-low flex w-1/2 items-center justify-center p-16 group-last:rounded-b-md group-last:pb-24"
            >
              {{ i18n(translations[`compareSimValue${i}`]) }}
            </p>
            <p
              class="bg-static-info-low flex w-1/2 items-center justify-center px-16 py-12 group-first:rounded-t-md group-last:rounded-b-md"
            >
              {{ i18n(translations[`compareESimValue${i}`]) }}
            </p>
          </div>
        </div>
      </section>

      <section class="my-56">
        <RevInfoBlock
          :icon="IconContact"
          :title="i18n(translations.infoBlockTitle)"
          variant="default"
        >
          <p class="text-static-default-mid body-2 mb-16">
            {{ i18n(translations.infoBlockText1) }}
          </p>
          <p class="text-static-default-mid body-2">
            {{ i18n(translations.infoBlockText2) }}
          </p>
        </RevInfoBlock>
      </section>

      <section class="my-56">
        <RevIllustration
          alt=""
          class="mx-auto"
          :height="79"
          src="/img/product/icon-faq.svg"
          :width="97"
        />

        <h3 class="text-static-default-hi body-1-bold mt-24">
          {{ i18n(translations.faqTitle) }}
        </h3>

        <RevAccordionList :has-external-borders="false">
          <RevAccordionItem
            v-for="i in [1, 2, 3] as const"
            :key="i"
            class="pl-0"
          >
            <template #title>
              {{ i18n(translations[`faqQuestion${i}Title`]) }}
            </template>
            <template #body>
              <p>
                {{ i18n(translations[`faqQuestion${i}Answer`]) }}
              </p>
            </template>
          </RevAccordionItem>
        </RevAccordionList>
      </section>
    </template>
  </RevDrawer>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevAccordionList } from '@ds/components/AccordionList'
import { RevDrawer } from '@ds/components/Drawer'
import { RevIllustration } from '@ds/components/Illustration'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevTag } from '@ds/components/Tag'
import { VariantsEnum } from '@ds/components/Tag/Tag.constant'
import { IconContact } from '@ds/icons/IconContact'
import { IconSim } from '@ds/icons/IconSim'

import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './SimModalExperiment.translations'

const i18n = useI18n()
</script>
