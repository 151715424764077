import { type HttpResponsePayload, createHttpEndpoint } from '../../utils'

/**
 * Given a product id, returns the frequently asked questions about this product
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/product-page-api/definition#tag/faq/operation/getFaq}
 */
export const getFaq = createHttpEndpoint<GetFaqResponse>({
  method: 'GET',
  operationId: 'getFaq',
  path: '/bm/product/:productId/listing/:listingId/faq',
})

export type GetFaqResponse = HttpResponsePayload<{
  questions: Array<{
    title: string
    text: string
    hyperlink?: {
      text: string
      link: {
        type: string
        href: string
      }
    }
  }>
}>
