import type { Price } from '@backmarket/http-api'
import type { Operation } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'

export const applyPriceOperation = (
  price: Price | null | undefined,
  operation: Operation | null,
): Price | null => {
  if (!price) {
    return null
  }
  if (!operation || !operation.price) {
    return price
  }

  let computedPrice = parseFloat(price.amount)

  if (operation.type === 'Add') {
    computedPrice += parseFloat(operation.price.amount)
  } else if (operation.type === 'Subtract') {
    computedPrice -= parseFloat(operation.price.amount)
  }

  return {
    amount: `${computedPrice < 0 ? 0 : computedPrice.toFixed(2)}`,
    currency: price.currency,
  }
}
