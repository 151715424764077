<template>
  <div
    class="flex items-center gap-8"
    :class="{
      'justify-start': variant === 'accept',
      'justify-end': variant === 'decline',
    }"
  >
    <RevButtonIcon
      v-if="variant === 'accept'"
      :alternative-text="i18n(translations.accept)"
      class="border-action-default-low mood-inverse !border"
      :icon="IconCheckWithSparkles"
      variant="primary"
      @click.stop="emit('accept')"
    />

    <!--  eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
    <div
      class="flex cursor-pointer flex-col"
      :class="{
        'text-left': variant === 'accept',
        'items-end text-right': variant === 'decline',
        'mood-inverse md:mood-main': theme === 'white',
      }"
      @click.stop="variant === 'accept' ? emit('accept') : emit('decline')"
      @keydown.enter.stop="
        variant === 'accept' ? emit('accept') : emit('decline')
      "
    >
      <RevTag v-if="tag" v-bind="tag" />

      <span v-if="label" class="text-static-default-hi body-2-bold">
        {{ label }}
      </span>

      <div
        class="flex items-center"
        :class="{
          'text-static-success-hi': isGoodDeal,
          'text-static-default-hi': !isGoodDeal,
        }"
      >
        <IconArrowDownRight
          v-if="isGoodDeal"
          :alternative-text="i18n(translations.goodDeal)"
        />
        <FormattedPrice :price />
      </div>
    </div>

    <RevButtonIcon
      v-if="variant === 'decline'"
      :alternative-text="i18n(translations.decline)"
      class="border-action-default-low mood-inverse !border"
      :icon="IconCheckLarge"
      variant="primary"
      @click.stop="emit('decline')"
    />
  </div>
</template>

<script setup lang="ts">
import type { Price } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonIcon } from '@ds/components/ButtonIcon'
import { RevTag } from '@ds/components/Tag'
import { IconArrowDownRight } from '@ds/icons/IconArrowDownRight'
import { IconCheckLarge } from '@ds/icons/IconCheckLarge'
import { IconCheckWithSparkles } from '@ds/icons/IconCheckWithSparkles'

import FormattedPrice from '../FormattedPrice/FormattedPrice.vue'

import translations from './Price.translations'

const i18n = useI18n()

export type PriceProps = {
  price: Price
  variant: 'accept' | 'decline'
  theme?: 'black' | 'white'
  label?: string
  tag?: {
    label: string
    variant:
      | 'primary'
      | 'secondary'
      | 'info'
      | 'success'
      | 'warning'
      | 'danger'
      | 'alternate'
  }
  isGoodDeal?: boolean
}

withDefaults(defineProps<PriceProps>(), {
  theme: 'black',
  isGoodDeal: false,
  label: undefined,
  tag: undefined,
})

const emit = defineEmits<{
  (e: 'accept'): void
  (e: 'decline'): void
}>()
</script>
