import { type Grade, type Price } from '../../api-models'
import { type MobilePlanOffer } from '../../api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { type PaymentMethod } from '../../api-specs-payment/payment/payment.types'
import { createHttpEndpoint } from '../../utils'

/**
 * @see {@link https://devportal.backmarket.io/catalog/default/api/product-page-service-api/definition#/product-page/get_pickers}
 */
export const getPickers = createHttpEndpoint<GetPickersResponse>({
  method: 'GET',
  operationId: 'getPickers',
  path: '/product-page/products/:productId/pickers',
})

/**
 * @see {@link https://devportal.backmarket.io/catalog/default/api/product-page-service-api/definition#/product-page/get_service_pickers}
 */
export const getServicesPickers =
  createHttpEndpoint<GetServicesPickersResponse>({
    method: 'GET',
    operationId: 'getServicesPickers',
    path: '/product-page/products/:productId/services/pickers',
  })

export type PickersResponseItem = {
  trackingValue?: string
  label: string
  sublabel?: string | null
  selected: boolean
  available: boolean
  acquirable: boolean
  price?: Price
  color: string | null
  goodDeal?: boolean
  productId: string
  sortingKey?: number | string | null
  slug?: string
  parameters?: {
    grade: Grade
    specialOfferType: {
      name: string
      value: number
    }
    mobilePlanOfferId: string | null
  }
  tag?: {
    label: string
    type:
      | 'primary'
      | 'secondary'
      | 'info'
      | 'success'
      | 'warning'
      | 'danger'
      | 'alternate'
  } | null
}

export type Operation = {
  type: 'NoOperation' | 'Subtract' | 'Add'
  price: Price | null
}

export type PickersResponseServiceItem = Omit<
  PickersResponseItem,
  'parameters' | 'color'
> & {
  parameters: Partial<PickersResponseItem['parameters']>
  operation: Operation
  // TODO improve typechecking here, as the first is only for mobile plan steps & the second for trade-in
  mobilePlanOffer?: MobilePlanOffer | null
  tradeInDiscount?: Price | null
}

export type Picker = {
  id: string
  trackingId?: string
  label: string
  sectionTitle?: string
  items: Array<PickersResponseItem>
  guidanceCard?: {
    clickable: boolean
    text: string
    icon: {
      name: string | null
      url: string | null
    }
  }
  sideContent?: {
    type: string
    content: {
      grade: number
      items: {
        sectionId: string
        imageUrl: string
        title: string
        tags: {
          id: string
          text: string
          icon: string
        }[]
      }[]
    }[]
  }
}

export type PickerService = Omit<Picker, 'items'> & {
  items: Array<PickersResponseServiceItem>
}

export type PickerWithExtraData = PickersResponseServiceItem & {
  mobilePlanOffer: MobilePlanOffer
}

//  Extra data guard
export function hasItemExtraData(
  item: PickersResponseServiceItem,
): item is PickerWithExtraData {
  return Boolean(item.mobilePlanOffer)
}

type Shipping = {
  price: Price | null
  delayInHours: number
  earliestArrivalDate: string
  latestArrivalDate: string
  provider: string
}

type GradeOffer = {
  offerType: number
  price: Price | null
  productId: string
  slug: string
}

export type GetPickersResponse = {
  gradeOffers: {
    [key: string]: GradeOffer[]
  }
  isOutOfStock: boolean
  pickerGroups: Array<Picker>
  selectedOffer: null | {
    offerId: string
    offerLegacyId: number | null
    paymentMethods: Array<PaymentMethod> | null
    price: Price | null
    defaultWarrantyDelay: string | null
    discount: null | {
      price: Price
      rate: string
    }
    shipping: null | {
      free?: Shipping
      express?: Shipping
    }
    sellerComment: string | null
    grade: Grade
    newBattery?: {
      status: 'not-available' | 'available' | 'disabled'
      offerType?: number
    }
    merchant: {
      merchantId: string
      company: string
      country: {
        name: string
        code: string
      }
      sellerSinceYear: string
      merchantUrl: string
    } | null
  }
}

export type GetServicesPickersResponse = {
  pickerGroups: Array<PickerService>
}
