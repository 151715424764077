<template>
  <Card
    :class="{
      [$style.gradiant]: isOpened || isPremiumStepAlone,
    }"
    :is-loading
    :is-opened="isPremiumStepAlone ? false : isOpened"
    :status
    :tracking
    @click="emit('click')"
  >
    <template #header>
      <CardHeader
        :label="i18n(translations.cardHeader)"
        :price="
          status !== 'unknown' || isPremiumStepAlone ? prices[0] : undefined
        "
        :status="
          status === 'accepted' || isPremiumStepAlone ? 'accepted' : 'unknown'
        "
        :without-chevron="isPremiumStepAlone"
      />
    </template>

    <FormattedMessage
      class="heading-1 block justify-center px-16 md:inline-block md:w-full md:text-center"
      :definition="translations.title"
      tag="h2"
    >
      <template #highlight>
        <span class="flex md:inline-block lg:pl-4">
          <span
            class="text-static-default-hi punchline-italic md:heading-1-italic"
          >
            {{ i18n(translations.titleHighlight) }}
          </span>

          <RevIllustration
            alt=""
            class="inline-block"
            :height="44"
            src="/img/product/no-grade/diamond.svg"
            :width="44"
          />
        </span>
      </template>
    </FormattedMessage>

    <div class="md:hidden">
      <ComparisonArea
        :brand
        class="px-16 pt-24"
        :offer-grade-without-premium
        :offer-type-without-premium
      />
    </div>

    <div class="p-24">
      <PriceGroup
        :accept="{ price: prices[0], label: i18n(translations.accept) }"
        :decline="{ price: prices[1], label: i18n(translations.decline) }"
        :tracking="{ zone: 'no_grade_premium' }"
        @accept="emit('accept')"
        @decline="emit('decline')"
      />
    </div>
  </Card>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { Price } from '@backmarket/http-api'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'

import Card from '../Card/Card.vue'
import CardHeader from '../CardHeader/CardHeader.vue'
import PriceGroup from '../PriceGroup/PriceGroup.vue'

import translations from './StepPremium.translations'
import ComparisonArea from './components/ComparisonArea/ComparisonArea.vue'

const props = withDefaults(
  defineProps<{
    prices: Array<Price>
    isOpened?: boolean
    isLoading?: boolean
    status?: 'accepted' | 'declined' | 'unknown'
    brand: string
    offerTypeWithoutPremium: 'default' | 'new_battery'
    offerGradeWithoutPremium: string
    tracking: Record<string, string>
    isPremiumStepAlone: boolean
  }>(),
  {
    isOpened: false,
    status: 'unknown',
    isLoading: false,
  },
)

const emit = defineEmits<{
  (e: 'click'): void
  (e: 'accept'): void
  (e: 'decline'): void
}>()

const i18n = useI18n()

const tracking = computed(() => {
  const acceptPrice = parseFloat(props.prices[0].amount)
  const declinePrice = parseFloat(props.prices[1].amount)

  return {
    ...props.tracking,
    price_accept: props.prices[0].amount,
    price_decline: props.prices[1].amount,
    good_deal: acceptPrice < declinePrice,
  }
})
</script>

<style module>
.gradiant {
  background:
    radial-gradient(80.35% 80.41% at 89.14% 19.59%, #f6f1fd 0%, #fff 100%), #fff;
}
</style>
