<template>
  <RevDrawer
    :close-button-label="i18n(translations.close)"
    :name="modalName"
    :title="i18n(translations.title)"
  >
    <template #body>
      <StandardVsNew v-if="isStandardVsNewVariant" />
      <StandardVsPremium v-else-if="isStandardVsPremium" />
      <NewVsPremium v-else-if="isNewVsPremium" />

      <p
        v-if="isStandardVsPremium || isNewVsPremium"
        class="text-static-default-low mt-32"
      >
        {{ i18n(translations.section1Note) }}
      </p>
    </template>
  </RevDrawer>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevDrawer } from '@ds/components/Drawer'

import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './NewBatteryModal.translations'
import type { BatteryModalVariants } from './NewBatteryModal.types'
import NewVsPremium from './components/BatteryModalContent/NewVsPremium.vue'
import StandardVsNew from './components/BatteryModalContent/StandardVsNew.vue'
import StandardVsPremium from './components/BatteryModalContent/StandardVsPremium.vue'

const i18n = useI18n()

const props = withDefaults(
  defineProps<{
    deviceName?: string
    modalName?:
      | typeof MODAL_NAMES.NEW_BATTERY
      | typeof MODAL_NAMES.NEW_BATTERY_NO_GRADE
    variant?: BatteryModalVariants
  }>(),
  {
    brand: '',
    deviceName: '',
    modalName: MODAL_NAMES.NEW_BATTERY,
    variant: 'standard_vs_new',
  },
)

const isStandardVsNewVariant = computed(
  () => props.variant === 'standard_vs_new',
)
const isStandardVsPremium = computed(
  () => props.variant === 'standard_vs_premium',
)
const isNewVsPremium = computed(() => props.variant === 'new_vs_premium')
</script>
