<template>
  <div>
    <AsyncModal
      :fetch="fetchFrequentlyAskedQuestions"
      :name="MODAL_NAMES.FAQ"
      :title="i18n(translations.title)"
    >
      <template #content>
        <div class="-mt-24 overflow-x-hidden">
          <RevAccordionList :has-external-borders="false">
            <RevAccordionItem
              v-for="question in questions"
              :key="question.title"
            >
              <template #title>
                <span class="body-1">
                  {{ question.title }}
                </span>
              </template>
              <template #body>
                <span class="body-2">
                  {{ question.text }}
                </span>
              </template>
            </RevAccordionItem>
          </RevAccordionList>
        </div>
        <WarrantyExclusion />
      </template>
    </AsyncModal>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import { getFaq } from '@backmarket/http-api/src/api-specs-navigation-experience/product/faq'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevAccordionList } from '@ds/components/AccordionList'

import { useUrlParams } from '~/scopes/product/composables/useUrlParams'
import { MODAL_NAMES } from '~/scopes/product/constants'

import AsyncModal from '../../../AsyncModal/AsyncModal.vue'
import WarrantyExclusion from '../../../WarrantyExclusion/WarrantyExclusion.vue'

import translations from './FaqModal.translations'

const props = defineProps<{
  listingId: number
}>()

const questions = ref()
const i18n = useI18n()
const { productId } = useUrlParams()

async function fetchFrequentlyAskedQuestions() {
  const { data } = await useHttpFetch(getFaq, {
    pathParams: {
      productId,
      listingId: String(props.listingId),
    },
  })

  questions.value = data.value?.questions ?? []
}
</script>
