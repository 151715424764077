export default {
  flashOffer130GoPreviousBenefit: {
    id: 'bouygues_flash_offer_130_go_previous_benefit',
    defaultMessage: '90Go',
  },
  flashOffer130GoNewBenefit: {
    id: 'bouygues_flash_offer_130_go_new_benefit',
    defaultMessage: '130Go',
  },
}
