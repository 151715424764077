<template>
  <ProductCardCarouselSkeleton v-if="pending" :with-title="!!title" />
  <section
    v-else-if="products.length > 0"
    data-qa="recommendation-carousel"
    data-test="recommendation-carousel"
  >
    <ProductCardCarousel
      :products
      :subtitle
      :tags="[i18n(translations.recommendationTag)]"
      :title
      :tracking="{ ...trackingData, provider: 'algolia' }"
      :use-client-side-navigation
      v-on="withAddToCart ? { addToCart: addToCartHandler } : {}"
    />
  </section>
</template>

<script lang="ts" setup>
import { onMounted, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'

import type { ExtraTrackingData } from '../composables/useProductTracking'
import { useAlgoliaRelatedProducts } from '../composables/useRecommendation'

import ProductCardCarousel from './ProductCardCarousel/ProductCardCarousel.vue'
import ProductCardCarouselSkeleton from './ProductCardCarousel/ProductCardCarouselSkeleton.vue'
import translations from './Recommendation.translations'

const {
  title = undefined,
  subtitle = undefined,
  productObjectId = undefined,
  withAddToCart = false,
  trackingData = {},
  useClientSideNavigation = true,
  categoryId = '',
} = defineProps<{
  title?: string
  subtitle?: string
  productObjectId?: string
  categoryId?: string
  withAddToCart?: boolean
  trackingData?: ExtraTrackingData
  useClientSideNavigation?: boolean
}>()

const i18n = useI18n()
const { openSuccessToast, openErrorToast } = useTheToast()
const emit = defineEmits(['error', 'loaded', 'refresh'])

const { products, pending, initRelatedProducts, error } =
  useAlgoliaRelatedProducts()

async function fetchRelatedProducts(id: string) {
  await initRelatedProducts(id, categoryId)
  if (products.value.length === 0) emit('loaded', products.value)
}

function addToCartHandler({
  status,
  product,
}: {
  status: 'success' | 'error'
  product: { listingId: string }
}) {
  if (status === 'success') {
    openSuccessToast({
      title: i18n(translations.toastSuccessTitle),
      content: i18n(translations.toastSuccessMessage),
    })
    emit('refresh', product.listingId)
  }
  if (status === 'error') {
    openErrorToast({
      title: i18n(translations.toastErrorTitle),
      content: i18n(translations.toastErrorMessage),
    })
  }
}

onMounted(() => {
  if (productObjectId) fetchRelatedProducts(productObjectId)
  else {
    watch(
      () => productObjectId,
      (id) => {
        if (id) fetchRelatedProducts(id)
      },
    )
  }
})

watch(error, () => {
  if (error.value) emit('error', error)
})
</script>
